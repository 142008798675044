import React from 'react';
import { observer } from 'mobx-react-lite';

import { useBasicStore } from 'store/hooks';
import { IAsset, IMm3Asset } from 'types';
import { InfoBoxV2 } from 'components/info-box-v2';
import { Row } from 'components/label-value-pair';
import { AssetFilters, withAssetVersion } from 'utils/asset';

import { FormInput, FormMultiSelect, FormSelect } from 'helpers/form';

import type { IAssetMetadataEditTabProps } from 'components/asset/edit-sections/types';
import { getEntityType, formatResolution, parseDataSize } from 'utils/general';
import { formatType } from 'utils/format-type';

export const AssetOldMetadataSpecificationEditSection: React.FC<IAssetMetadataEditTabProps<IAsset>> = observer(
  ({ asset, form }) => {
    const { formData, handlers } = form;
    const { languagesDataOptions } = useBasicStore();

    const {
      ['@type']: type,
      file_size,
      file_name,
      format,
      height,
      md5,
      width,
      classification,
      ingest_notes,
    } = asset || {};

    const assetItemType = getEntityType(classification);
    const isVideo: boolean = assetItemType === AssetFilters.VIDEO;
    const isImage: boolean = assetItemType === AssetFilters.IMAGE;
    return (
      <InfoBoxV2 gridTemplateColumns="120px 1fr" title="Specification">
        <Row label="File Name" value={file_name} size="m" />
        <Row label="File Type" value={type} size="m" />
        <Row label="MD5 Ingest" value={md5} size="m" />
        <Row
          label="MD5 User Provided"
          value={
            <FormInput
              placeholder="Type MD5 User Provided"
              name="user_md5"
              type="textarea"
              {...formData.user_md5}
              {...handlers}
            />
          }
          size="m"
        />

        <Row label="Width" value={`${width}px`} size="m" hidden={!(isImage || isVideo)} />
        <Row label="Height" value={`${height}px`} size="m" hidden={!(isImage || isVideo)} />
        <Row label="Resolution" value={formatResolution({ width, height })} size="m" hidden={!(isImage || isVideo)} />

        <Row
          label="Languages"
          value={
            <FormMultiSelect
              name="language_ids"
              placeholder="Select Languages"
              {...formData.language_ids}
              {...handlers}
              options={languagesDataOptions}
              large
            />
          }
          size="m"
        />
        <Row label="File Size" value={parseDataSize(file_size)} size="m" />
        <Row label="Format" value={format} size="m" hidden={!format} />
        <Row label="Ingest Notes" value={ingest_notes} size="m" hidden={!ingest_notes} />
      </InfoBoxV2>
    );
  },
);

export const AssetMm3MetadataSpecificationEditSection: React.FC<IAssetMetadataEditTabProps<IMm3Asset>> = observer(
  ({ asset, form }) => {
    const { formData, handlers } = form;
    const { languagesDataOptions } = useBasicStore();

    const { classification, file_size, ingest_notes, source_meta, type } = asset || {};
    const { file_name, format, height, md5, width } = source_meta || {};

    const assetItemType = getEntityType(classification);
    const isVideo: boolean = assetItemType === AssetFilters.VIDEO;
    const isImage: boolean = assetItemType === AssetFilters.IMAGE;
    return (
      <InfoBoxV2 gridTemplateColumns="120px 1fr" title="Specification">
        <Row label="File Name" value={file_name} size="m" />
        <Row label="File Type" value={formatType(type)} size="m" />
        <Row label="MD5 Ingest" value={md5} size="m" />
        <Row
          label="MD5 User Provided"
          value={
            <FormInput
              placeholder="Type MD5 User Provided"
              name="meta.user_md5"
              type="textarea"
              {...formData.meta?.user_md5}
              {...handlers}
            />
          }
          size="m"
        />

        <Row label="Width" value={`${width}px`} size="m" hidden={!(isImage || isVideo)} />
        <Row label="Height" value={`${height}px`} size="m" hidden={!(isImage || isVideo)} />
        <Row
          label="Resolution"
          value={`${formatResolution({ width, height })}`}
          size="m"
          hidden={!(isImage || isVideo)}
        />

        <Row
          label="Language"
          value={
            <FormSelect
              name="meta.language_id"
              placeholder="Select Language"
              {...formData.meta?.language_id}
              {...handlers}
              options={languagesDataOptions}
              large
            />
          }
          size="m"
        />
        <Row label="File Size" value={parseDataSize(file_size)} size="m" />
        <Row label="Format" value={format} size="m" hidden={!format} />
        <Row label="Ingest Notes" value={ingest_notes} size="m" hidden={!ingest_notes} />
      </InfoBoxV2>
    );
  },
);

export const AssetMetadataSpecificationEditSection = withAssetVersion(
  AssetOldMetadataSpecificationEditSection,
  AssetMm3MetadataSpecificationEditSection,
);
