import React from 'react';

import { FormInput, FormNumericInput, FormSelect } from 'helpers/form';
import { IGuildFormComponent } from './types';
import FormSectionWrapperV2 from 'components/form-section-wrapper-v2/form-section-wrapper-v2';

export const keysGeneral = ['name', 'access_level'];

export const GuildFormGeneral: React.FC<IGuildFormComponent> = ({ form }) => {
  return (
    <FormSectionWrapperV2 padded={false} gridTemplateColumns={'2fr 2fr'}>
      <FormInput name="name" label="Name" {...form.formData.name} {...form.handlers} />
      <FormSelect name="access_level" label="Access Level" large {...form.formData.access_level} {...form.handlers} />
      <FormNumericInput
        name="requested_codes_count"
        label="Requested Codes Count"
        {...form.formData.requested_codes_count}
        {...form.handlers}
      />
    </FormSectionWrapperV2>
  );
};
