import React from 'react';
import { observer } from 'mobx-react-lite';

import { Routes } from 'utils/routes';
import { LoaderWithText, ThumbnailWithRefresh } from 'components/thumbnail';
import { LabelValuePair as Row } from 'components/label-value-pair';
import { PreviewTab, PreviewInfo } from 'components/preview-tab';

import { formatDate } from 'utils/date';
import { customLabel } from 'components/cast-crew/preview-tab/utils';

import { useDataSectionStore } from 'store';
import { IconAlias } from 'icons';
import { IExtendedProductCastCrew } from 'types/product';
import { CustomDescriptionPreview } from 'components/cast-crew/preview-tab/utils';
import { useEntityOwnership } from 'utils/hooks/use-divisions';

export const CastCrewPreview: React.FC<{}> = observer(() => {
  const { activeItem } = useDataSectionStore<IExtendedProductCastCrew>();

  if (!activeItem?.id) {
    return <span className="entity-preview-tab__no-preview">No preview</span>;
  }

  const {
    id,
    preview_image,
    created_at,
    meta,
    name,
    updated_at,
    cast_role,
    crew_position,
    cast_character,
    kind,
    preview_image_id,
  } = activeItem;
  const { ownershipInfo, loadingOwnership } = useEntityOwnership(activeItem, undefined, false);

  return (
    <PreviewTab
      title={name}
      subtitle={cast_character}
      detailsLink={`${Routes.CAST_CREW}/${id}`}
      customThumbnail={
        <ThumbnailWithRefresh
          fallbackIconName={IconAlias.CAST_CREW}
          entity={{ preview_image_id, preview_image }}
          placeholder={<LoaderWithText text="processing" />}
          showType={Boolean(kind)}
          customTag={kind}
        />
      }
    >
      <PreviewInfo gridTemplateColumns="90px 1fr">
        <Row label="Name" value={name} />
        <Row label="Role" hideIfNoValue value={cast_role} />
        <Row label="Position" hideIfNoValue value={crew_position} />
        <Row label="Character" hideIfNoValue value={cast_character} />
        <CustomDescriptionPreview activeItem={activeItem} />
        <Row label={customLabel.description} rawLabel hideIfNoValue value={meta?.description} />
        <Row label="Owner" value={ownershipInfo} loading={loadingOwnership} size="m" />
        <Row label="Created at" value={formatDate(created_at)} rawLabel />
        <Row label="Updated at" value={formatDate(updated_at)} rawLabel />
      </PreviewInfo>
    </PreviewTab>
  );
});
