import React from 'react';
import { Tabs } from '@mantine/core';

import { EmptySectionMessage } from 'components/section-message/section-message';
import { GroupDetailProductsTab } from 'components/marketing-entity-detail';
import { DataSectionControls } from 'components/data-section-controls';

import { SmartGroupAssetsTab } from './smart-group-assets-tab';

import { TabButtonsWrapper } from 'components/tabs';
import { MobileSelectionDetailTab, MobileSelectionTabPanel } from './utils';

import { ITitleDividerActionName, Pages } from 'utils/actions';
import { DetailsPageTabs, ISmartGroup } from 'types';
import { SmartGroupContactsTab } from './smart-group-contacts-tab';
// import { SmartGroupOrganizationsTab } from './smart-group-organizations-tab';

const options = {
  page: Pages.DETAILS,
  groupName: ITitleDividerActionName.SMART_GROUP,
  groupParamName: 'smartGroupId',
};

interface ISmartGroupDetailsTabsView {
  smartGroup: ISmartGroup;
  currentTab: DetailsPageTabs;
  onTabChangeHandle: (DetailsPageTabs) => void;
  onSuccess?: () => void;
}

export const SmartGroupDetailsTabsView: React.FC<ISmartGroupDetailsTabsView> = ({
  smartGroup,
  currentTab,
  onTabChangeHandle,
  onSuccess,
}) => {
  const { id } = smartGroup || {};

  if (!smartGroup?.id) {
    return <EmptySectionMessage />;
  }

  return (
    <Tabs value={currentTab} onChange={onTabChangeHandle} className="marketing-entity-details__tabs h-100">
      <Tabs.List className="d-flex flex-nowrap">
        <MobileSelectionDetailTab value={DetailsPageTabs.PRODUCTS} activeTab={currentTab} />
        <MobileSelectionDetailTab value={DetailsPageTabs.ASSETS} activeTab={currentTab} />
        <MobileSelectionDetailTab value={DetailsPageTabs.CONTACTS} activeTab={currentTab} />
        {/* <MobileSelectionDetailTab value={DetailsPageTabs.ORGANIZATIONS} activeTab={currentTab} /> */}
        <TabButtonsWrapper>
          <DataSectionControls />
        </TabButtonsWrapper>
      </Tabs.List>
      <MobileSelectionTabPanel
        value={DetailsPageTabs.PRODUCTS}
        content={
          <GroupDetailProductsTab
            groupId={id}
            options={{ ...options, page: Pages.MOBILE_APP_SYNC_PRODUCTS_TAB, onSuccess }}
            page={Pages.MOBILE_APP_SYNC_PRODUCTS_TAB}
          />
        }
      />

      <MobileSelectionTabPanel
        value={DetailsPageTabs.ASSETS}
        content={<SmartGroupAssetsTab smartGroup={smartGroup} onSuccess={onSuccess} />}
      />

      <MobileSelectionTabPanel
        value={DetailsPageTabs.CONTACTS}
        content={<SmartGroupContactsTab smartGroup={smartGroup} onSuccess={onSuccess} />}
      />
      {/* 
      <MobileSelectionTabPanel
        value={DetailsPageTabs.ORGANIZATIONS}
        content={<SmartGroupOrganizationsTab smartGroup={smartGroup} onSuccess={onSuccess} />}
      /> */}
    </Tabs>
  );
};
