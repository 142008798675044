import React, { useCallback } from 'react';
import { TextInput, TextInputProps, Textarea } from '@mantine/core';
import cx from 'classnames';

import { IFieldData, IFieldHandlers } from 'helpers/form/types';
import { getFieldPlaceholder } from 'helpers/form/fields/helpers';
import './style.scss';

export interface IFormInputProps
  extends Omit<TextInputProps, 'value' | 'onChange' | 'onBlur'>,
    IFieldData<string | null>,
    IFieldHandlers<string | null> {
  name: string;
  label?: string;
  showPositiveValidation?: boolean;
  formId?: string;
  inline?: boolean;
  textarea?: boolean;
  rows?: number;
  contentClassName?: string;
  autocomplete?: string;
  leftIcon?: React.ReactNode;
  rightElement?: React.ReactNode;
}

const FormInput: React.FC<IFormInputProps> = (props) => {
  const {
    formId = 'id',
    showPositiveValidation,
    label,
    name,
    type = 'text',
    leftIcon,
    disabled,
    rightElement,
    placeholder,
    onChange,
    onBlur,
    validation,
    touched,
    value = '',
    required,
    inline = false,
    style,
    className,
    textarea = false,
    rows = 4,
    contentClassName,
    autocomplete = '',
    size = 'md',
    ...rest
  } = props;

  const showError = Boolean(touched && !validation?.valid && validation?.errorMessage);
  const showValid = Boolean(showPositiveValidation && touched && validation?.valid);
  const formattedPlaceholder = getFieldPlaceholder({ placeholder, disabled, defaultPlaceholder: `Type ${label}` });

  const handleChange = useCallback(
    (e) => {
      onChange?.({ [name]: e.target.value || null });
    },
    [name, onChange],
  );

  const handleBlur = useCallback(() => {
    onBlur?.(name);
  }, [name, onBlur]);

  const safeValue = value || '';

  return (
    <>
      {textarea ? (
        <Textarea
          id={`${formId}-${name}`}
          label={label}
          name={name}
          placeholder={formattedPlaceholder}
          disabled={disabled}
          onChange={handleChange}
          value={safeValue}
          rows={rows}
          className={cx({ 'form-input-inline__container': inline }, className)}
          error={showValid || showError ? validation?.errorMessage : undefined}
        />
      ) : (
        <TextInput
          id={`${formId}-${name}`}
          name={name}
          label={label}
          withAsterisk={required}
          leftSection={leftIcon}
          placeholder={formattedPlaceholder}
          type={type}
          disabled={disabled}
          onChange={handleChange}
          onBlur={handleBlur}
          value={safeValue}
          className={cx({ 'form-input-inline__container': inline }, className)}
          autoComplete={autocomplete}
          error={showValid || showError ? validation?.errorMessage : undefined}
          rightSection={rightElement}
          size={size}
          {...rest}
        />
      )}
    </>
  );
};

export default FormInput;
