import React, { useCallback } from 'react';
import { map } from 'lodash';
import { observer } from 'mobx-react-lite';

import { useDataSectionStore } from 'store';

import { Row } from 'components/label-value-pair';
import { PreviewTab, PreviewInfo } from 'components/preview-tab';
import { MemoizedMultiThumbnail } from 'components/multi-component/multi-component';

import { Routes } from 'utils/routes';
import { IAccessPrivilege, IGroupItem, DetailsPageTabs } from 'types';

import { changeAccessLevelSlider } from 'utils/change-access';
import { Model } from 'helpers/filters/types';
import { formatDate } from 'utils/date';
import { getStatus } from 'utils/general';
import { useOrganizationCountInGroup, useRemote } from 'utils/hooks';
import { accessPrivilegeListSchema } from 'utils/schemas';
import { DetailCountLink } from 'utils/ui';
import { loadGroupItems, changeGroupAccessImmediate } from 'utils/apis/groups';
import { queryProducts } from 'utils/apis/product';
import { useEntityOwnership } from 'utils/hooks/use-divisions';

const AccessPrivilegePreview: React.FC = observer(() => {
  const { activeItem: accessPrivilege } = useDataSectionStore<IAccessPrivilege>();

  const { name, access_level, id, pm_statistics_data, users_count, created_at, updated_at, deleted_at, expires_at } =
    accessPrivilege || {};

  const [organization_count] = useOrganizationCountInGroup(id);

  const fetchThumbnailImages = useCallback(async () => {
    if (!id) {
      return [];
    }
    const productGroupItems = await loadGroupItems<IGroupItem>({
      group_ids: id,
      target: Model.PRODUCT_GROUP_ITEMS,
      schema: 'id, product_id, permissions',
      per: 4,
    });
    if (!productGroupItems || !productGroupItems.length) return [{}];

    const productIds = map(productGroupItems, 'product_id');
    const productThumbnails = await queryProducts({ ids: productIds });

    return productThumbnails.map((product) => ({
      image: product.inherited_preview_image?.url,
      assetType: product.type,
    }));
  }, [id]);

  const [thumbnailImages] = useRemote(fetchThumbnailImages, [{}]);

  const handleAccessLevelChange = useCallback((newAccessLevel: string) => {
    return changeAccessLevelSlider({
      apiCall: (data) => changeGroupAccessImmediate(data, accessPrivilegeListSchema),
      newAccessLevel,
    });
  }, []);

  if (!accessPrivilege) {
    return <span className="entity-preview-tab__no-preview">No preview</span>;
  }

  const { ownershipInfo, loadingOwnership } = useEntityOwnership(accessPrivilege);

  const detailsPageLink = `${Routes.ACCESS_PRIVILEGES}/${id}`;

  return (
    <PreviewTab
      title={name}
      accessLevel={access_level}
      detailsLink={deleted_at ? '' : detailsPageLink}
      handleAccessLevelChange={handleAccessLevelChange}
      customThumbnail={
        <MemoizedMultiThumbnail
          height={172}
          width={308}
          className="entity-preview__thumb"
          thumbnailImages={thumbnailImages || [{}]}
        />
      }
    >
      <PreviewInfo>
        <DetailCountLink
          entityCount={pm_statistics_data?.count}
          link={`${detailsPageLink}/${DetailsPageTabs.PRODUCTS}`}
          label={DetailsPageTabs.PRODUCTS}
        />
        <DetailCountLink
          entityCount={users_count}
          link={`${detailsPageLink}/${DetailsPageTabs.CONTACTS}`}
          label={DetailsPageTabs.CONTACTS}
        />
        <DetailCountLink
          entityCount={organization_count}
          link={`${detailsPageLink}/${DetailsPageTabs.ORGANIZATIONS}`}
          label={DetailsPageTabs.ORGANIZATIONS}
        />

        <Row label="Ownership" value={ownershipInfo} loading={loadingOwnership} />
        <Row label="Status" value={getStatus(deleted_at)} />

        <Row label="Created at" value={formatDate(created_at)} rawLabel />
        <Row label="Updated at" value={formatDate(updated_at)} rawLabel />
        <Row label="Expires at" value={formatDate(expires_at)} rawLabel />
        <Row label="Deleted at" value={formatDate(deleted_at)} rawLabel hideIfNoValue />
      </PreviewInfo>
    </PreviewTab>
  );
});

export default AccessPrivilegePreview;
