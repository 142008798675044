import { McGenericRecommendationAsset, McGenericRecommendationProduct } from '@mediafellows/mm3-types';

import { logger } from 'utils/logger';
import { parseToSearchParams } from 'utils/general';
import { queryProducts } from 'utils/apis/product';
import { queryAssets } from 'utils/apis/asset';
import { chipmunk, IResult } from 'utils/chipmunk';
import { RecommendationSchema } from 'utils/schemas';
import { Model } from 'helpers/filters/types';
import {
  IQueryParams,
  IRecommendationSentRecipient,
  ISearchFilter,
  ISearchParams,
  ItemId,
  IThumbnailImage,
} from 'types';

export const loadAssetRecommendationThumbnails = async (
  recommendation?: McGenericRecommendationAsset,
): Promise<IThumbnailImage[]> => {
  if (!recommendation?.id || recommendation.type !== 'Recommendation::Asset' || !recommendation.asset_ids?.length) {
    return [];
  }
  if (!recommendation.asset_ids?.length) {
    return [];
  }
  const ids = recommendation.asset_ids.slice(0, 3);
  const assets = await queryAssets({ ids }, [['parent_id', 'not_exist']], 'id, preview_image, preview_image_id, @type');

  return (assets || []).reduce((acc, cur) => {
    const url = cur?.preview_image?.url;
    return url ? [{ image: url, assetType: cur['@type'] }, ...acc] : acc;
  }, []);
};

export const loadProductRecommendationThumbnails = async (
  recommendation?: McGenericRecommendationProduct,
): Promise<IThumbnailImage[]> => {
  if (recommendation?.type !== 'Recommendation::Product' || !recommendation.product_ids?.length) {
    return [];
  }

  const products = await queryProducts({ ids: recommendation.product_ids.slice(0, 3) });

  return products.reduce((acc, product) => {
    const url = product?.inherited_preview_image?.url;
    return url ? [{ image: url, assetType: product.type }, ...acc] : acc;
  }, []);
};

export const loadRecommendationThumbnails = async (
  recommendation?: McGenericRecommendationProduct | McGenericRecommendationAsset,
): Promise<IThumbnailImage[]> => {
  if (!recommendation?.id) {
    return [];
  }

  if (recommendation.type === 'Recommendation::Asset') {
    return loadAssetRecommendationThumbnails(recommendation as McGenericRecommendationAsset);
  }

  return loadProductRecommendationThumbnails(recommendation as McGenericRecommendationProduct);
};

export const updateRecommendation = async <
  T extends McGenericRecommendationProduct | McGenericRecommendationAsset = McGenericRecommendationProduct,
>(
  recommendation: Partial<T>,
): Promise<T> => {
  return chipmunk.run(async (chipmunk) => {
    const { object } = await chipmunk.action(Model.RECOMMENDATIONS, 'update', {
      params: { recommendation_ids: [recommendation?.id] },
      body: [recommendation],
      multi: false,
      schema: RecommendationSchema,
    });

    return object;
  });
};

export const deleteRecommendation = async (ids?: (number | null | undefined)[]): Promise<IResult | void> => {
  if (!ids) {
    return;
  }
  return chipmunk.run(({ action }) => {
    return action(Model.RECOMMENDATIONS, 'delete', {
      params: { recommendation_ids: ids },
    });
  });
};

export const revokeRecommendation = async (ids?: (number | null | undefined)[]): Promise<IResult | void> => {
  if (!ids) {
    return;
  }
  return chipmunk.run(({ action }) => {
    return action(Model.RECOMMENDATIONS, 'revoke', {
      params: { recommendation_ids: ids },
    });
  });
};

export const createRecommendationDraft = <
  T extends McGenericRecommendationProduct | McGenericRecommendationAsset = McGenericRecommendationProduct,
>({
  asset_ids,
  recipient_list = [],
  requires_login = false,
  subject,
  type = 'Recommendation::Product',
  ...rest
}: Partial<T>): Promise<T> => {
  return chipmunk.run(async ({ action }) => {
    const { object } = await action(Model.RECOMMENDATIONS, 'create', {
      body: {
        asset_ids,
        recipient_list,
        requires_login,
        subject: subject || 'Auto subject',
        type,
        ...rest,
      },
    });

    return object;
  });
};

export const sendRecommendation = <
  T extends McGenericRecommendationProduct | McGenericRecommendationAsset = McGenericRecommendationProduct,
>(
  recommendation: T,
): Promise<T> => {
  return chipmunk.run(async ({ action }) => {
    if (!recommendation) {
      return [];
    }
    const { object } = await action(Model.RECOMMENDATIONS, 'deliver', {
      params: { recommendation_ids: [recommendation.id] },
      schema: RecommendationSchema,
    });

    return object;
  });
};

export const querySentRecommendationRecipients = ({
  ids,
}: {
  ids?: ItemId[];
}): Promise<IRecommendationSentRecipient[]> => {
  return chipmunk.run(async (chipmunk) => {
    const { objects } = await chipmunk.action(`${Model.RECOMMENDATIONS}/recipient`, 'index', {
      params: { recommendation_ids: ids },
    });

    return objects;
  });
};

export const loadRecommendation = async (recommendationId?: ItemId): Promise<McGenericRecommendationProduct> => {
  return chipmunk.run(async ({ action }) => {
    try {
      if (!recommendationId) {
        return;
      }
      const { object } = await action(Model.RECOMMENDATIONS, 'get', {
        params: {
          recommendation_ids: recommendationId,
        },
        schema: RecommendationSchema,
      });
      return object;
    } catch (e) {
      logger.error(e);
      return null;
    }
  });
};

export const queryRecommendations = (
  params: IQueryParams | { search: { filters: [string, string, string | string[]][] } },
  additionalFilters: ISearchFilter[] = [],
  parseToSearch = parseToSearchParams,
  schema = RecommendationSchema,
): Promise<McGenericRecommendationProduct[]> =>
  searchRecommendations(parseToSearch(params as IQueryParams, additionalFilters), schema);

export const searchRecommendations = (
  body: ISearchParams,
  schema = RecommendationSchema,
): Promise<McGenericRecommendationProduct[]> => {
  return chipmunk.run(async ({ action }) => {
    const { objects } = await action(Model.RECOMMENDATIONS, 'search', {
      body,
      schema,
    });

    return objects;
  });
};

export const loadRecommendationByGroupId = async (groupId?: ItemId): Promise<string> => {
  return chipmunk.run(async ({ action }) => {
    try {
      if (!groupId) {
        return;
      }
      const { object } = await action(Model.RECOMMENDATIONS, 'search', {
        body: {
          search: {
            filters: [['recommendation_group_id', 'eq', groupId]],
          },
        },
        schema: 'id',
      });
      return object.id;
    } catch (e) {
      logger.error(e);
      return null;
    }
  });
};
