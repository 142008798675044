import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Checkbox } from '@mantine/core';
import cx from 'classnames';

import { SmallLoader, Thumbnail } from 'components/thumbnail';
import DateElement from 'components/date-list-element/date-list-element';
import { ListItemCountLink } from 'utils/ui';
import { DetailsPageTabs, ISmartGroup } from 'types';
import useSelectedOption from 'utils/hooks/selected-option';
import { Routes } from 'utils/routes';
import { IconAlias } from 'icons';
import { Classes } from 'utils/ui';

import { isEntityIngesting } from 'utils/hooks/refresh-preview-ingest';

import './style.scss';
import { useMainAssetCountInGroup } from 'utils/hooks/asset';

interface ISmartGroupDataSectionListItemProps {
  contextMenu?: React.ReactElement;
  group: ISmartGroup;
  isChecked: boolean;
  isCheckedDisabled: boolean;
  handleClick?: (e) => void;
}

export const DataSectionListItem: React.FC<ISmartGroupDataSectionListItemProps> = ({
  group,
  contextMenu,
  handleClick,
  isChecked,
  isCheckedDisabled,
}) => {
  const { id, created_at, name, preview_image, pm_statistics_data, updated_at, access_level, users_count } =
    group || {};

  const selectedOption = useSelectedOption({ value: access_level });
  const detailsPageLink = `${Routes.SMART_GROUP}/${id}`;
  const [itemChecked, setItemChecked] = useState(isChecked);
  const [assetCount] = useMainAssetCountInGroup(id);

  useEffect(() => {
    setItemChecked(isChecked);
  }, [isChecked]);

  return (
    <div className="entity-list-item-row">
      <div
        className={cx('entity-list-item-row__checkbox', {
          [`access-level-${selectedOption?.color}`]: Boolean(selectedOption),
        })}
      >
        <Checkbox onChange={handleClick} checked={itemChecked} disabled={isCheckedDisabled} />
      </div>
      <div className="entity-list-item-row__thumb">
        <Thumbnail
          fallbackIconName={IconAlias.SMART}
          image={preview_image?.url}
          placeholder={isEntityIngesting(group) && <SmallLoader size={30} />}
        />
      </div>
      <div className="entity-list-item-row__content">
        <div className="entity-list-item-row__title" title={name}>
          <Link to={detailsPageLink}>
            <h4 className="text-nowrap text-truncate">{name}</h4>
          </Link>
          {Boolean(contextMenu) && contextMenu}
        </div>
        <div className={`smart-group-list-item__attributes ${Classes.TEXT_MUTED}`}>
          <div className="smart-group-list-item__products">
            <ListItemCountLink
              entityCount={pm_statistics_data?.count}
              link={`${detailsPageLink}/${DetailsPageTabs.PRODUCTS}`}
              linkText="Product"
            />
          </div>
          <div className="smart-group-list-item__videos">
            <ListItemCountLink
              entityCount={assetCount}
              link={`${detailsPageLink}/${DetailsPageTabs.ASSETS}`}
              linkText="Asset"
            />
          </div>
          <div className="smart-group-list-item__products">
            <ListItemCountLink
              entityCount={users_count}
              link={`${detailsPageLink}/${DetailsPageTabs.CONTACTS}`}
              linkText="Contact"
            />
          </div>
          <div className="text-truncate smart-group-list-item__created">
            <DateElement date={created_at} mfxIcon="created-at" />
          </div>
          <div className="text-truncate smart-group-list-item__updated">
            <DateElement date={updated_at} mfxIcon="updated-at" />
          </div>
        </div>
      </div>
    </div>
  );
};
