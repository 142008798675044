import React, { useState, useMemo, useCallback } from 'react';
import { Button, Tabs } from '@mantine/core';

import { GroupAssetsTreeTab } from 'components/marketing-entity-detail/group-asset-tree';
import { AssetsDataSection } from 'components/asset/assets-data-section';
import { ManageAssetsButtons } from 'components/manage-assets-button';
import TabButtonsWrapper from 'components/tabs/tab-buttons-wrapper';
import { useAddToGroupDialog } from 'components/add-to-group-dialog';
import { DataSectionControls } from 'components/data-section-controls';
import { Loading } from 'components/loading';

import { MobileSyncAssetTabSubTabs } from './utils';
import { MantineIcon } from 'utils/ui';
import { ITitleDividerActionName, Pages } from 'utils/actions';
import { getProductsWithAssets } from 'components/marketing-entity-detail/group-asset-tree/utils';
import { useRemote } from 'utils/hooks';

import { IProductWithAssets } from 'components/marketing-entity-detail/group-asset-tree/group-asset-tree';
import { IGroupAssetItem, ISearchFilter, ItemId, DetailsPageTabs, GroupTypes, IMobileAppSync } from 'types';

interface IMobileAppSyncAssetsTab {
  propGroupId?: ItemId;
  disabled?: boolean;
  groupAssets?: IGroupAssetItem[];
  onAssetUpdate: (
    assetsToUpdate?: {
      assetsToAdd?: string[] | undefined;
      assetsToDelete?: string[] | undefined;
    },
    assetIdsToGroupItemIds?: Record<string, string>,
  ) => Promise<void>;
  isSaveClicked: boolean;
  isCancelClicked: boolean;
  setIsCancelClicked: React.Dispatch<React.SetStateAction<boolean>>;
  currentTab: DetailsPageTabs;
  isAssetEditEnabled: boolean;
  setIsAssetEditEnabled: React.Dispatch<React.SetStateAction<boolean>>;
  setIsSaveClicked: React.Dispatch<React.SetStateAction<boolean>>;
  isLoading: boolean;
  mobileAppSync?: IMobileAppSync;
  onSuccess: (() => void) | undefined;
}

export const MobileAppSyncAssetsTab: React.FC<IMobileAppSyncAssetsTab> = ({
  onAssetUpdate,
  disabled,
  propGroupId,
  isSaveClicked,
  groupAssets,
  isCancelClicked,
  setIsCancelClicked,
  currentTab,
  isAssetEditEnabled,
  setIsAssetEditEnabled,
  setIsSaveClicked,
  isLoading,
  mobileAppSync,
  onSuccess,
}) => {
  const [assetSubTab, setCurrentSubTab] = useState(MobileSyncAssetTabSubTabs.PRODUCT_ASSETS);

  const fetchProductsWithAssets = useCallback(
    async (): Promise<IProductWithAssets[]> => getProductsWithAssets(propGroupId),
    [propGroupId],
  );

  const [productWithAssets, loading] = useRemote(fetchProductsWithAssets);
  const productAssetsIds = productWithAssets?.flatMap((prod) => prod.assets.map((ass) => ass.id));
  const otherAssetIds = useMemo(
    () =>
      loading
        ? []
        : groupAssets?.reduce((acc, groupAsset) => {
            const isProductAsset = productAssetsIds?.includes(groupAsset.asset_id);
            if (!isProductAsset) return [...acc, groupAsset.asset_id];
            return acc;
          }, []),
    [groupAssets, productAssetsIds, loading],
  );
  const assetsFilter = useMemo(
    () =>
      [
        ['id', 'in', otherAssetIds],
        ['parent_id', 'not_exist'],
      ] as ISearchFilter[],
    [otherAssetIds],
  );
  const handleSubTabChange = useCallback((tab) => {
    setCurrentSubTab(tab);
  }, []);

  const [openDialog] = useAddToGroupDialog({
    entityType: 'assets',
    groupType: GroupTypes.MOBILE_APP_SYNC,
    group: mobileAppSync,
    groupLabel: 'Mobile Selection',
    options: { onSuccess, context: 'multi' },
  });

  return (
    <Tabs className="access__tabs h-100 w-100" value={assetSubTab} onChange={handleSubTabChange}>
      <Tabs.List className="d-flex flex-nowrap">
        <Tabs.Tab value={MobileSyncAssetTabSubTabs.PRODUCT_ASSETS} className="access__tab h-100">
          {MobileSyncAssetTabSubTabs.PRODUCT_ASSETS}
        </Tabs.Tab>
        <Tabs.Tab value={MobileSyncAssetTabSubTabs.OTHER_ASSETS} className="access__tab h-100">
          {MobileSyncAssetTabSubTabs.OTHER_ASSETS}
        </Tabs.Tab>
        <TabButtonsWrapper>
          <ManageAssetsButtons
            showButton={
              currentTab === DetailsPageTabs.ASSETS && assetSubTab === MobileSyncAssetTabSubTabs.PRODUCT_ASSETS
            }
            isAssetEditEnabled={isAssetEditEnabled}
            setIsAssetEditEnabled={setIsAssetEditEnabled}
            setIsSaveClicked={setIsSaveClicked}
            isLoading={isLoading}
            setIsCancelClicked={setIsCancelClicked}
          />
          <Button
            size="xs"
            leftSection={<MantineIcon icon="add" />}
            onClick={openDialog}
            variant="primary"
            hidden={assetSubTab !== MobileSyncAssetTabSubTabs.OTHER_ASSETS}
          >
            Add Assets
          </Button>
          <DataSectionControls hidden={assetSubTab !== MobileSyncAssetTabSubTabs.OTHER_ASSETS} />
        </TabButtonsWrapper>
      </Tabs.List>
      <Tabs.Panel value={MobileSyncAssetTabSubTabs.PRODUCT_ASSETS} className="h-100">
        <GroupAssetsTreeTab
          onAssetUpdate={onAssetUpdate}
          disabled={disabled}
          propGroupId={propGroupId}
          isSaveClicked={isSaveClicked}
          groupAssets={groupAssets}
          isCancelClicked={isCancelClicked}
          setIsCancelClicked={setIsCancelClicked}
          productWithAssetsProp={productWithAssets}
        />
      </Tabs.Panel>
      <Tabs.Panel value={MobileSyncAssetTabSubTabs.OTHER_ASSETS} className="h-100">
        {loading ? (
          <Loading text="Loading Data" />
        ) : (
          <AssetsDataSection
            persistentFilters={assetsFilter}
            page={Pages.MOBILE_APP_SYNC_ASSETS_TAB}
            actionsOptions={{
              groupName: ITitleDividerActionName.MOBILE_APP_SYNC,
              groupParamName: 'mobileSyncId',
              page: Pages.MOBILE_APP_SYNC_ASSETS_TAB,
              onSuccess,
            }}
          />
        )}
      </Tabs.Panel>
    </Tabs>
  );
};
