import React from 'react';
import { observer } from 'mobx-react-lite';

import { useFormsStore } from 'store/hooks';

import { FormContacts, FormDivisions } from 'helpers/form';
import FormOrganizationSelect from 'helpers/form/fields/form-organization/form-organization';
import { InfoBoxV2 } from 'components/info-box-v2';

import { IUseMm3FormReturn } from 'helpers/form/use-mm3-form';
import { IProduct } from 'types';

export const ProductOverviewTabEdit: React.FC = observer(() => {
  const { forms } = useFormsStore();

  const productForm = forms['productForm'] as IUseMm3FormReturn<IProduct>;

  return (
    <InfoBoxV2 className="product-metadata-tab__container" wrapperClassName="w-100 pt-3" title="Ownership" padded>
      <FormContacts label="Owner" name="owner_id" {...productForm.formData.owner_id} {...productForm.handlers} large />
      <FormDivisions
        name="division_ids"
        label="Divisions"
        large
        placeholder="Search Divisions"
        {...productForm.formData.division_ids}
        {...productForm.handlers}
      />
      <FormOrganizationSelect
        name="owner_organization_id"
        key="owner_organization_id"
        label="Organization"
        {...productForm.formData.owner_organization_id}
        {...productForm.handlers}
      />
    </InfoBoxV2>
  );
});

export default ProductOverviewTabEdit;
