import React, { useCallback, Dispatch, SetStateAction } from 'react';
import { observer } from 'mobx-react-lite';
import { useParams } from 'react-router-dom';
import { omit } from 'lodash';
import { Switch } from '@mantine/core';

import { tuco } from 'utils/chipmunk';
import { AssetsDataSection } from 'components/asset/assets-data-section';
import { Pages } from 'utils/actions';
import { IAsset, IProductAncestryInfo, IProductAsset } from 'types';
import { ICustomSortMenuProp } from 'components/data-section';
import { ParametronOrder, ParametronSort } from 'helpers/filters/types';

import ProductAssetsSortTab from './product-asset-sort-tab';
import { useIncludeDescendantsProducts } from './utils';
import { ProductAssetSortMenu } from './sort-menu';
import { submitFiltersWithSeqNumber } from 'helpers/filters/helpers';

interface IProductAssetsPreviewTabProps {
  productId: string;
  setProductAssets: Dispatch<SetStateAction<IProductAsset[]>>;
  ancestry: IProductAncestryInfo;
}

const defaultParams = {
  sort: ParametronSort.SEQUENCE_NUMBER,
  order: ParametronOrder.ASCENDING,
};

const customSortMenu: ICustomSortMenuProp = (sort, handleSort, searchStore) => (
  <ProductAssetSortMenu sort={sort} handleSort={handleSort} searchStore={searchStore} />
);

const ProductAssetsPreviewTab: React.FC<IProductAssetsPreviewTabProps> = observer(
  ({ productId, setProductAssets, ancestry }) => {
    const { paramComponentValues, handleToggleParam, disableIncludeDescendantsAssetsSwitch } =
      useIncludeDescendantsProducts(ancestry);

    const executor = useCallback(
      async ({ schema: assetSchema, ...opts }) => {
        const options = { ...opts, assetSchema };

        const include_descendants = opts.params.include_descendants;
        options.params = omit(opts.params, ['include_descendants']);
        options.body = omit(opts.body, ['include_descendants']);
        options.body[include_descendants ? 'product_ancestry_id' : 'product_id'] = ~~productId;

        options.body.search.filters = [...(options.body.search.filters || []), ['parent_id', 'not_exist']];
        return tuco<IAsset>('getProductAssets', options);
      },
      [productId],
    );

    return (
      <AssetsDataSection
        executor={executor}
        customSortMenu={customSortMenu}
        defaultParams={defaultParams}
        headerRightSection={
          <Switch
            data-param="include_descendants"
            onChange={handleToggleParam}
            checked={paramComponentValues.include_descendants}
            label="Include Descendants' Assets"
            className="my-0 pe-3"
            disabled={disableIncludeDescendantsAssetsSwitch}
          />
        }
        setProductAssets={setProductAssets}
        customSubmitFilter={submitFiltersWithSeqNumber}
        page={Pages.PRODUCT_ASSETS_TAB}
        customOrderRightSection
      />
    );
  },
);

interface IProductAssetsTabProps {
  isSortModeEnabled: boolean;
  setProductAssets: Dispatch<SetStateAction<IProductAsset[]>>;
  ancestry: IProductAncestryInfo;
}
export const ProductAssetsTab: React.FC<IProductAssetsTabProps> = ({
  isSortModeEnabled,
  setProductAssets,
  ancestry,
}) => {
  const { productId = '' } = useParams<{ productId: string }>();

  if (isSortModeEnabled) {
    return <ProductAssetsSortTab productId={productId} setProductAssets={setProductAssets} />;
  }

  return <ProductAssetsPreviewTab productId={productId} ancestry={ancestry} setProductAssets={setProductAssets} />;
};

export default ProductAssetsTab;
