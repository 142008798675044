import { useCallback } from 'react';
import type { ISmartGroup } from 'types';

import { IUseActionsOptionParam } from 'utils/actions/types';

import { shouldDisplaySmartGroup as shouldDisplay } from './actions-acl';

import { ISmartGroupActionConfig, ISmartGroupActionName } from './types';
import { useSmartGroupDialog } from 'components/smart-group/form-hook';

import { getValidIds } from 'utils/general';
import { useRemote } from 'utils/hooks';
import { queryContacts } from 'utils/apis/contacts';

export const useEditAction = (items: ISmartGroup[], options: IUseActionsOptionParam): ISmartGroupActionConfig => {
  const groupId = getValidIds(items)[0];

  const fetchContacts = useCallback(() => {
    return queryContacts({}, [['group_ids', 'in', [groupId]]], 'id', 'unfurl');
  }, [groupId]);
  const [contacts, loadingContacts] = useRemote(fetchContacts);

  const loading = loadingContacts;

  const [handler] = useSmartGroupDialog({
    group: items[0],
    userIds: getValidIds(contacts),
    onSuccess: options?.onSuccess,
  });

  return {
    name: ISmartGroupActionName.EDIT,
    shouldDisplay,
    icon: 'edit',
    title: loading ? 'Loading...' : 'Edit',
    isDisabled: loading,
    handler,
  };
};
