import React from 'react';
import queryString from 'query-string';
import { useEffect, useState, useCallback, useRef } from 'react';
import { createParametron, IParametronData, Parametron } from '@mediafellows/parametron';
import { useNavigate } from 'react-router';
import { useLocation } from 'react-router-dom';
import { IActionOpts, IObject, IResult } from '@mediafellows/chipmunk/dist/src/action';
import isEmpty from 'lodash/isEmpty';

import { refreshPreviewIngestingDelay } from 'utils/constants';
import { chipmunk } from 'utils/chipmunk';
import { parseQueryParams } from 'utils/general';

import { defaultSearchParams } from 'utils/default-search-param';
import { IEntityWithPreview, ISearchFilter, ISearchFilterValue, ItemId } from 'types';
import {
  EqFilter,
  IFilter,
  IFiltersDefinition,
  InFilter,
  Model,
  ParametronOrder,
  RangeFilter,
} from 'helpers/filters/types';
import { ParametronStore } from 'store/parametron-store';
import { DataSectionSidebarTabs } from 'components/data-section-sidebar';
import { useStore } from 'store';
import { ToastError } from 'components/toast';
import { IDataSectionDefaultParams } from 'components/data-section/data-section';

import { refreshIngestingDataSection } from './refresh-preview-ingest';
import { getItemsPerPage } from 'utils/list-item';
import { createdAtRange } from 'utils/date';
import { noParentFilter } from 'components/product-filters';
import { DataSectionStore } from 'store/data-section-store';

export interface IUseControlDataSection<T = IObject, P = IFiltersDefinition> {
  schema?: string;
  model?: Model;
  queryParams?: Record<string, string>;
  presetSuffix?: string;
  actionName?: string;
  defaultFilters?: P;
  filters?: IFilterOption[];
  initFilters?: Partial<P> | undefined;
  params?: Record<string, unknown> | IDataSectionDefaultParams;
  defaultParams?: Record<string, unknown> | IDataSectionDefaultParams;
  executor?: (opts?: IActionOpts) => Promise<IResult>;
  stats?: string;
  persistFilters?: boolean;
  handleUpdate?: (data: IParametronData, searchStore: ParametronStore | null) => Promise<void>;
  initQueryParams?: (api: Parametron, queryParams: Record<string, string>) => void;
  parseQueryParamsToFilters?: (query?: Record<string, string>) => Record<string, IFilter>;
  customDataSectionStore?: DataSectionStore<T>;
}

const defaultParseQueryParamsToFilters = ({
  status,
  created_at,
  type,
  division_ids,
}: Record<string, string> = {}): Record<string, IFilter> => {
  const parsedIds = division_ids?.length && JSON.parse(division_ids);
  const divIds = parsedIds ? (Array.isArray(parsedIds) ? parsedIds : [parsedIds]) : [];
  return {
    ...(status ? { status: new EqFilter('status', status) } : {}),
    ...(division_ids ? { division_ids: new InFilter('division_ids', divIds) } : {}),
    ...(created_at && createdAtRange[created_at]
      ? { created_at: new RangeFilter('created_at', ...createdAtRange[created_at]) }
      : {}),
    ...(type ? { ...noParentFilter(false), type: new InFilter('type', [type]) } : {}),
  };
};

const initiateQueryParams = (api: Parametron, queryParams: Record<string, string> = {}): void => {
  if (queryParams.status) {
    api.setFilter('status', 'eq', queryParams.status);
  }
  if (queryParams.created_at && createdAtRange[queryParams.created_at]) {
    api.setFilter('created_at', 'range', queryParams.created_at);
  }
  if (queryParams.type) {
    api.setFilter('type', 'in', [queryParams.type]);
    api.setFilter('parent_id', 'not_exist', false);
  }
};

export function usePersistFilters<T extends IFiltersDefinition>(
  dataSectionStore: DataSectionStore,
  persistFilters?: boolean,
): { oldFilters: T | undefined } {
  const { updateOldFilters, getOldFilters } = dataSectionStore;

  useEffect(() => {
    const pathname = location.pathname;

    return () => {
      if (!persistFilters) {
        return;
      }
      updateOldFilters(pathname);
    };
  }, [updateOldFilters, persistFilters]);

  if (!persistFilters) {
    return { oldFilters: undefined };
  }

  const oldFilters = getOldFilters<T>(location.pathname);
  return { oldFilters };
}

function useControlDataSection<T, FiltersTemplate>({
  schema,
  presetSuffix = '',
  model = Model.PRODUCTS,
  filters,
  params,
  actionName = 'search',
  executor,
  stats = 'status,responsibility',
  defaultFilters,
  initFilters,
  handleUpdate,
  defaultParams,
  queryParams,
  persistFilters,
  initQueryParams = initiateQueryParams,
  parseQueryParamsToFilters = defaultParseQueryParamsToFilters,
  customDataSectionStore,
}: IUseControlDataSection<T, FiltersTemplate>): void {
  const { toastStore, dataSectionStore: defaultDataSection } = useStore();
  const dataSectionStore = customDataSectionStore || defaultDataSection;
  const [entitySearch, setEntitySearch] = useState<ParametronStore | null>(null);
  useClearDataInitialFilters(dataSectionStore);
  const intervalTimerRef = useRef<number>();

  const { updateStore, activeTab, active, checked } = dataSectionStore;
  const { oldFilters } = usePersistFilters(dataSectionStore, persistFilters);

  const handleExecutor = useCallback(
    async (options: IActionOpts): Promise<IResult> => {
      try {
        let result;
        if (executor) {
          result = await executor(options);
        } else {
          result = await chipmunk.action(model, actionName, options);
        }
        return result;
      } catch (error) {
        toastStore.error(<ToastError error={error} placeholder="Could not load data!" />);
        return { objects: [], object: {} };
      }
    },
    [model, executor, actionName, toastStore],
  );

  useEffect(() => {
    let entitySearch: ParametronStore | null = null;
    const parametron = createParametron({
      executor: handleExecutor,
      stats,
      schema,
      init: (api) => {
        if (entitySearch) return;

        api.setParams({
          ...defaultSearchParams,
          per: getItemsPerPage(),
          ...(defaultParams || {}),
          ...(params || {}),
        });
        filters?.map(([attribute, method, value1, value2]: ISearchFilter) => {
          api.setPersistentFilter(attribute, method, value1, value2);
        });
        if (isEmpty(oldFilters) && queryParams && !isEmpty(queryParams)) {
          initQueryParams(api, queryParams);
        }
      },
      update: (data: IParametronData) => {
        entitySearch?.update(data);
        entitySearch?.updateParamsAndFiltersCount(['include_deleted', 'include_internal_accounts']);

        handleUpdate?.(data, entitySearch);

        if (data?.objects?.[0]?.hasOwnProperty('preview_image_id')) {
          clearInterval(intervalTimerRef.current);
          intervalTimerRef.current = window.setInterval((): void => {
            refreshIngestingDataSection(entitySearch as ParametronStore<IEntityWithPreview>, intervalTimerRef.current);
          }, refreshPreviewIngestingDelay);
        }
      },
    });
    entitySearch = new ParametronStore(parametron);
    setEntitySearch(entitySearch);

    return () => {
      clearInterval(intervalTimerRef.current);
    };
  }, [
    filters,
    handleExecutor,
    params,
    schema,
    stats,
    queryParams,
    initQueryParams,
    handleUpdate,
    defaultParams,
    oldFilters,
  ]);

  useEffect(() => {
    if (!entitySearch) {
      return;
    }

    const defaultParams = { ...entitySearch.parametron.data.params };

    updateStore({
      searchStore: entitySearch,
      target: `${model}${presetSuffix ? `-${presetSuffix}` : ''}`,
      filters: {
        ...defaultFilters,
        ...initFilters,
        ...(isEmpty(oldFilters) ? parseQueryParamsToFilters(queryParams) : oldFilters),
      },
      defaultFilters: { ...defaultFilters },
      defaultParams,
    });

    if (initFilters) {
      updateStore({ initFilters });
    }
  }, [
    updateStore,
    entitySearch,
    initFilters,
    presetSuffix,
    defaultFilters,
    model,
    queryParams,
    oldFilters,
    parseQueryParamsToFilters,
  ]);

  useEffect(() => {
    if (!entitySearch) {
      return;
    }
    const newActiveTab = active || checked?.length > 0 ? activeTab : DataSectionSidebarTabs.FILTERS;

    updateStore({ activeTab: newActiveTab });
  }, [active, activeTab, checked?.length, entitySearch, updateStore]);
}

export type IFilterOption =
  | [string, 'match' | 'eq' | 'ne' | 'in', string | string[] | number | number[] | ItemId | ItemId[] | boolean]
  | [string, string, ISearchFilterValue, string?]
  | [string, 'not_exist'];

export default useControlDataSection;

export const useClearDataInitialFilters = (dataSectionStore: DataSectionStore): void => {
  const { reset } = dataSectionStore;

  useEffect(() => {
    reset();
  }, [reset]);
};

export const useDataSectionSort = (): ((value: string, order?: ParametronOrder) => void) => {
  const navigate = useNavigate();
  const location = useLocation();

  const handleSort = useCallback(
    (value: string, order?: ParametronOrder): void => {
      const queryParams = parseQueryParams(location.search);

      const query = { ...queryParams, ...(value ? { sort: value, ...(order ? { order } : {}) } : {}) };

      const searchString = queryString.stringify({ ...query });
      navigate({ pathname: location.pathname, search: searchString });
    },
    [navigate, location],
  );

  return handleSort;
};
