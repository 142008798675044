import React, { useEffect, useCallback, useState } from 'react';
import { Button } from '@mantine/core';

import {
  IFormMultiSelectOption,
  FormSelect,
  useFetchFieldOptions,
  FormMultiSelect,
  FormOrganization,
} from 'helpers/form';
import { IUseMm3FormReturn } from 'helpers/form/use-mm3-form';

import { fetchConnectionById, fetchConnections } from 'utils/apis/delivery-connections';

import { IDeliveryFormProps } from 'components/delivery/types';
import { IDeliveryConnection } from 'components/delivery/connections/types';
import { fetchRecipients, validateEmail, parseRecipients } from 'components/delivery/utils';
import { ConnectionEditor } from 'components/delivery/connections/connection-editor/connection-editor';
import { initialValues } from 'components/delivery/connections/initial-values';

export const DeliveryOrganizationRecipientsStep: React.FC<IDeliveryFormProps> = ({
  deliveryForm,
  connectionForm,
  connectionValidating,
}) => {
  const [newConnectionActivated, setNewConnection] = useState(false);
  const { formData, handlers } = deliveryForm;
  const { onChange } = handlers;

  const setRecipients = useCallback(
    (options: IFormMultiSelectOption[]): void => {
      const { recipient_list, recipients } = parseRecipients(options);
      onChange({ recipient_list, recipients });
    },
    [onChange],
  );

  const fetchOrgConnections = useCallback(async () => {
    return await fetchConnections(formData.organization_id.value, 'active');
  }, [formData.organization_id.value]);

  const setCurrentConnection = useCallback(
    async ({ connection_id }: { connection_id: number }) => {
      const connection = await fetchConnectionById(connection_id);

      setNewConnection(false);
      handlers.onChange({ connection_id });
      connectionForm?.handlers.onChange(connection);
    },
    [connectionForm?.handlers, handlers],
  );

  const connectionOptions = useFetchFieldOptions(fetchOrgConnections);

  useEffect(() => {
    if (deliveryForm.values.organization_id && deliveryForm.values.connection_id && !connectionForm?.values.id) {
      setCurrentConnection({ connection_id: deliveryForm.values.connection_id });
    }
  }, [deliveryForm, connectionForm, setCurrentConnection]);

  const addConnection = (): void => {
    const initialConnection = { ...initialValues, organization_id: formData.organization_id.value };
    setNewConnection(true);

    //TODO use a uuid instead of 0
    deliveryForm.handlers.onChange({ connection_id: 0 });
    connectionForm?.handlers.onChange(initialConnection);
  };

  return (
    <div className={`organization-connection__grid-containter ${connectionValidating ? 'is-disabled' : ''}`}>
      <div className="organization__row">
        <FormOrganization
          name="organization_id"
          label="Organization"
          {...formData.organization_id}
          {...handlers}
          large={false}
          required
        />

        <FormMultiSelect
          className="recipients-selector"
          fetchValues={fetchRecipients}
          name="recipients"
          label="Notification Recipients"
          rawLabel
          omni
          allowNewItems
          onSelectedItemsChange={setRecipients}
          validateNewItem={validateEmail}
          {...formData.recipients}
          {...handlers}
        />
      </div>

      <div className={`connection-row ${!deliveryForm.values.organization_id ? 'disabled' : ''}`}>
        <div className="connection-selector">
          <FormSelect
            name="connection_id"
            label="Existing Connections"
            disabled={!formData.organization_id.value}
            options={connectionOptions}
            {...formData.connection_id}
            {...handlers}
            onChange={setCurrentConnection}
            size="xs"
          />

          <Button
            size="xs"
            variant="primary"
            disabled={newConnectionActivated}
            className="connection-action__button"
            onClick={addConnection}
          >
            New Connection
          </Button>
        </div>

        <ConnectionEditor
          form={connectionForm as IUseMm3FormReturn<IDeliveryConnection>}
          newConnection={newConnectionActivated}
        />
      </div>
    </div>
  );
};
