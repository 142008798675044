import React, { useMemo } from 'react';
import cx from 'classnames';

import { DetailsPageTabs, IAssetAggregation, IMm3Asset, IMm3AssetType } from 'types';
import { getMainType, AssetFilters } from 'utils/asset';
import { flags } from 'utils/flags';

import { assetClassifications, AssetIconLink } from './product-assets';
import { AssetType } from 'utils/format-asset-type';

interface IAssetLinkUIBasicProps {
  baseUrl?: string;
  muted?: boolean;
  disabled?: boolean;
  fallback?: string;
  className?: string;
}
const typesWithCustomLinks = new Set([IMm3AssetType.AUDIO, IMm3AssetType.SUBTITLE] as string[]);
const relevantClassifications = assetClassifications.filter((type) => !typesWithCustomLinks.has(type));

export const DescendantsMm3Assets: React.FC<IAssetLinkUIBasicProps & { assets?: IMm3Asset[] }> = ({
  assets,
  baseUrl,
  muted,
  fallback = '0',
  disabled,
  className,
}) => {
  const assetTypesCount = useMemo<Record<string | IMm3AssetType, number>>(() => {
    const countByType = {} as Record<string | IMm3AssetType, number>;

    assets?.map((asset) => {
      const type = getMainType(asset);
      const { classification } = asset || {};
      if (!classification || !type) {
        return;
      }

      if (classification === 'document/subtitle' || (classification as string) === 'document/report') {
        const type = classification.split('/')[1];
        countByType[type] = (countByType[type] || 0) + 1;
        return;
      }

      if (type === AssetFilters.AUDIO) {
        countByType[type] = (countByType[type] || 0) + 1;
        return;
      }

      countByType.ancillary = (countByType.ancillary || 0) + 1;
    });

    return countByType;
  }, [assets]);
  const { subtitle, audio, ancillary } = assetTypesCount;
  const showFallback = Object.values(assetTypesCount).every((e) => !e);
  return (
    <div className={cx(className, { ['d-flex flex-row']: !className?.includes('d-block') })}>
      {subtitle ? (
        <AssetIconLink
          disabled={disabled}
          muted={muted}
          key="subtitle"
          count={subtitle}
          type={IMm3AssetType.SUBTITLE}
          baseUrl={baseUrl + '/' + DetailsPageTabs.SUBTITLES}
          skipTypeParam
        />
      ) : (
        <></>
      )}
      {audio ? (
        <AssetIconLink
          disabled={disabled}
          muted={muted}
          key="audio"
          count={audio}
          type="audio"
          baseUrl={baseUrl + '/' + DetailsPageTabs.AUDIO_TRACKS}
          skipTypeParam
        />
      ) : (
        <></>
      )}

      {ancillary ? (
        <AssetIconLink
          disabled={disabled}
          muted={muted}
          key="ancillary"
          count={ancillary}
          type="ancillary"
          baseUrl={baseUrl + '/' + DetailsPageTabs.SUB_ASSETS}
          skipTypeParam
        />
      ) : (
        <></>
      )}
      {showFallback && fallback}
    </div>
  );
};

interface IAssetTypeAggregationsProps {
  aggregations?: IAssetAggregation;
  baseUrl?: string;
  muted?: boolean;
  disabled?: boolean;
  fallback?: string;
}

export const DescendantsAssetsAggregations: React.FC<IAssetTypeAggregationsProps> = ({
  aggregations,
  baseUrl,
  muted,
  fallback = '0',
  disabled = false,
}) => {
  const buckets = flags.hasCustomClassifications ? aggregations?.count_by_type : aggregations?.count_by_classification;
  const assetTypesCount = useMemo<Record<string | IMm3AssetType, number>>(() => {
    const countByType = {} as Record<string | IMm3AssetType, number>;

    buckets?.map(({ value, count }) => {
      if ((!flags.hasCustomClassifications && value === 'document/subtitle') || value === 'document/report') {
        const type = value.split('/')[1];
        countByType[type] = count;
        return;
      }

      const mainClassification = flags.hasCustomClassifications ? value : value.split('/')[0];
      countByType[mainClassification] = (countByType[mainClassification] || 0) + count;
    });

    return countByType;
  }, [buckets]);
  const { subtitle, audio, report, ...rest } = assetTypesCount;
  const hasAssets = Object.values(rest).some((e) => e > 0);
  return (
    <div className="d-flex flex-row">
      {subtitle || rest[AssetType.SUBTITLE] ? (
        <AssetIconLink
          disabled={disabled}
          muted={muted}
          key="subtitle"
          count={subtitle || rest[AssetType.SUBTITLE]}
          type={IMm3AssetType.SUBTITLE}
          baseUrl={baseUrl + '/' + DetailsPageTabs.SUBTITLES}
          skipTypeParam
        />
      ) : (
        <></>
      )}
      {audio || rest[AssetType.AUDIO] ? (
        <AssetIconLink
          disabled={disabled}
          muted={muted}
          key="audio"
          count={audio || rest[AssetType.AUDIO]}
          type="audio"
          baseUrl={baseUrl + '/' + DetailsPageTabs.AUDIO_TRACKS}
          skipTypeParam
        />
      ) : (
        <></>
      )}
      {report ? (
        <AssetIconLink
          disabled={disabled}
          muted={muted}
          key="report"
          count={report}
          type="report"
          baseUrl={baseUrl + '/' + DetailsPageTabs.REPORTS}
          skipTypeParam
        />
      ) : (
        <></>
      )}
      {hasAssets ? (
        relevantClassifications.reduce((acc, assetType) => {
          return rest[assetType]
            ? [
                ...acc,
                <AssetIconLink
                  disabled={disabled}
                  muted={muted}
                  key={assetType}
                  count={assetTypesCount[assetType]}
                  type={assetType}
                  baseUrl={baseUrl + '/' + DetailsPageTabs.SUB_ASSETS}
                />,
              ]
            : acc;
        }, [])
      ) : (
        <></>
      )}
      {Object.values(assetTypesCount).some((e) => e > 0) ? '' : fallback}
    </div>
  );
};
