import React, { useEffect } from 'react';
import { Grid, Text } from '@mantine/core';
import { map } from 'lodash';

import { FormAssetTreeSelector, FormAssets } from 'helpers/form';
import { getRecommendationAssetsFilters } from 'utils/recommendation';
import { IMobileAppSyncAssetsForm } from './types';
import { querySubAssets } from 'utils/apis/asset';

export const MobileAppSyncFormAssets: React.FC<IMobileAppSyncAssetsForm> = ({
  form,
  isEditingProducts,
  showMetadataHover,
  setLoadingAssets,
}) => {
  useEffect(() => {
    // for independed assets
    const fetchSubAssets = async (): Promise<void> => {
      if (!form.values.independentAssetIds) return;

      const subAssets = await querySubAssets(form.values.independentAssetIds as string[]);
      const independentAssetSubIds = map(subAssets, 'id') || [];
      form.handlers.onChange({ independentAssetSubIds: independentAssetSubIds as string[] });
    };

    fetchSubAssets();
  }, [form.values.independentAssetIds, form.values.independentAssetSubIds, form.handlers]);

  return (
    <Grid className="h-100" styles={{ inner: { height: '100%' } }}>
      <Grid.Col span={6}>
        <>
          <Text>Product Assets</Text>
          <FormAssetTreeSelector
            form={form}
            allSelected
            isEditingProducts={isEditingProducts}
            showMetadataHover={showMetadataHover}
            setLoadingAssets={setLoadingAssets}
          />
        </>
      </Grid.Col>
      <Grid.Col span={6}>
        <FormAssets
          name="independentAssetIds"
          label="Other Assets"
          fitInParent
          large
          omni
          {...form.formData.independentAssetIds}
          {...form.handlers}
          filters={getRecommendationAssetsFilters()}
        />
      </Grid.Col>
    </Grid>
  );
};
