import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { Checkbox } from '@mantine/core';
import cx from 'classnames';

import { Routes } from 'utils/routes';
import { ScheduledChangeIndicator } from 'components/common/scheduled-change-info';
import { Thumbnail } from 'components/thumbnail';
import { ProductListItemAttributes } from 'components/product-data-section-item/product-list-attributes';
import { ProductAncestryBreadcrumbs as AncestryBreadcrumbs } from 'components/breadcrumb-product-ancestry';
import { getProductSubtitle } from 'components/product/product-translated-title/get-product-translated-title';
import useSelectedOption from 'utils/hooks/selected-option';
import { isEntityDeleted } from 'utils/is-entity-deleted';

import { IProductDataSectionWrappedComponent } from './types';
import './style.scss';

export const ProductDataSectionListItem: React.FC<
  IProductDataSectionWrappedComponent & { rightSection?: JSX.Element }
> = ({ contextMenu, handleClick, isAllItemsSelected, isChecked, rightSection, product, id, active }) => {
  const { access_level, inherited_preview_image, display_title } = product;
  const selectedOption = useSelectedOption({ value: access_level });
  const subTitle = getProductSubtitle(product);
  const linkTitle = Boolean(subTitle)
    ? `${display_title}  <span class="product-list__pipe">|</span> ${subTitle}`
    : `${display_title}`;
  const [itemChecked, setItemChecked] = useState(isChecked);

  useEffect(() => {
    setItemChecked(isChecked);
  }, [isChecked]);

  return (
    <div
      className={cx('product-list-item', {
        'entity-list-item--active product-list-item-row--active': active === id,
        'entity-list-item--deleted': isEntityDeleted(product),
      })}
      onClick={handleClick}
    >
      <div className={cx({ 'product-list-item-row--side-section': Boolean(rightSection) }, 'product-list-item-row')}>
        <div
          className={cx('entity-list-item-row__checkbox', {
            [`access-level-${selectedOption?.color}`]: Boolean(selectedOption),
          })}
        >
          <Checkbox onChange={handleClick} checked={itemChecked || isAllItemsSelected} disabled={isAllItemsSelected} />
        </div>

        <div className="entity-list-item-row__thumb">
          <Thumbnail showType assetType={product?.type} image={inherited_preview_image?.url} />
        </div>

        <div className="product-list-item-row__content">
          <div className="product-list-item-row__pre-title">
            <AncestryBreadcrumbs ancestryInfo={product?.ancestry_info} layout="list" productId={id} />
            <div className="d-flex align-items-center gap-1">
              <ScheduledChangeIndicator entity={product} />
              {Boolean(contextMenu) && contextMenu}
            </div>
          </div>
          <div title={display_title} className="entity-list-item-row__title product-list-item-row__title-wrapper">
            <Link to={`${Routes.PRODUCTS}/${product?.id}`} className="text-truncate d-inline-block">
              <span dangerouslySetInnerHTML={{ __html: linkTitle }}></span>
            </Link>
          </div>
          <ProductListItemAttributes product={product} />
        </div>
        {rightSection && <div className="product-list-item-tile__right-section">{rightSection}</div>}
      </div>
    </div>
  );
};
