import React, { useCallback } from 'react';

import { FormSectionWrapperV2 } from 'components/form-section-wrapper-v2';
import { FormMultiAssets } from 'helpers/form/custom-fields';

import { IScreeningRoomStepProps } from './utils';
import { IAsset, IQueryParams } from 'types';
import { queryAssets } from 'utils/apis/asset';

export const ScreeningRoomStepAssets: React.FC<IScreeningRoomStepProps> = ({ form }) => {
  const onAssetChange = useCallback(
    (assets) => {
      // do the above in the final step
      form.handlers.onChange({ assets, assets_item_ids: assets?.map((e) => e?.value) });
    },
    [form.handlers],
  );

  const fetchAssets = useCallback(({ q, ids }: IQueryParams): Promise<IAsset[]> => {
    return queryAssets({ q, ids }, [['parent_id', 'not_exist']]);
  }, []);

  return (
    <FormSectionWrapperV2 gridTemplateColumns="1fr" padded={false}>
      <FormMultiAssets
        label="Assets"
        name="assets_item_ids"
        {...form.formData.assets_item_ids}
        {...form.handlers}
        large
        onSelectedItemsChange={onAssetChange}
        fetchValues={fetchAssets}
      />
    </FormSectionWrapperV2>
  );
};
