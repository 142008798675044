import React from 'react';
import { observer } from 'mobx-react-lite';

import { useFilters } from 'helpers/filters';

import { flags } from 'utils/flags';

import { DataSectionStore } from 'store/data-section-store';
import { IAsset } from 'types';
import { FormSectionWrapperV2 } from 'components/form-section-wrapper-v2';
import { IAssetFilters } from 'components/asset/asset-filters/utils';
import { SharedAssetFilters } from 'components/asset/asset-filters/shared-filters';

interface ISmartGroupFormAssetFilters {
  dataSectionStore: DataSectionStore<IAsset>;
}

export const SmartGroupFormAssetFilters: React.FC<ISmartGroupFormAssetFilters> = observer(({ dataSectionStore }) => {
  const [filterValues, filterHandlers] = useFilters<IAssetFilters>(dataSectionStore, dataSectionStore.triggerSearch);

  return (
    <FormSectionWrapperV2 padded={false} className="smart-group-wizard__filters-tab">
      <SharedAssetFilters
        dataSectionStore={dataSectionStore}
        filterValues={filterValues}
        filterHandlers={filterHandlers}
        withMainAssetsFilter={flags.isMm3Assets}
      />
    </FormSectionWrapperV2>
  );
});
