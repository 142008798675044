import React, { useCallback } from 'react';
import { observer } from 'mobx-react-lite';

import { Routes } from 'utils/routes';
import { LoaderWithText, ThumbnailWithRefresh } from 'components/thumbnail';
import { LabelValuePair as Row } from 'components/label-value-pair';
import { PreviewTab, PreviewInfo } from 'components/preview-tab';

import { DetailsPageTabs, ISmartGroup } from 'types';
import { useDataSectionStore } from 'store';
import { changeAccessLevelSlider } from 'utils/change-access';
import { IconAlias } from 'icons';

import { formatOwnership, getStatus } from 'utils/general';
import { formatDate } from 'utils/date';
import { changeGroupAccessImmediate } from 'utils/apis/groups';
import { SmartGroupListSchema } from 'utils/schemas/smart-group';
import { useMainAssetCountInGroup } from 'utils/hooks/asset';

export const SmartGroupPreview: React.FC<{}> = observer(() => {
  const { activeItem } = useDataSectionStore<ISmartGroup>();

  const {
    created_at,
    deleted_at,
    name,
    id,
    preview_image,
    pm_statistics_data,
    updated_at,
    product_filters,
    asset_filters,
    user_filters,
    access_level,
    users_count,
    owner,
    preview_image_id,
  } = activeItem || {};

  const [assetCount] = useMainAssetCountInGroup(id);

  const handleAccessLevelChange = useCallback(
    (newAccessLevel: string) => {
      return changeAccessLevelSlider({
        apiCall: (data) => changeGroupAccessImmediate(data, SmartGroupListSchema),
        divisionIds: owner?.division_ids,
        newAccessLevel,
      });
    },
    [owner],
  );

  if (!event) {
    return <span className="entity-preview-tab__no-preview">No preview</span>;
  }

  const detailsPageLink = `${Routes.SMART_GROUP}/${id}`;

  return (
    <PreviewTab
      title={name}
      accessLevel={access_level}
      detailsLink={deleted_at ? '' : detailsPageLink}
      handleAccessLevelChange={handleAccessLevelChange}
      customThumbnail={
        <ThumbnailWithRefresh
          fallbackIconName={IconAlias.SMART}
          entity={{ preview_image_id, preview_image }}
          placeholder={<LoaderWithText text="processing" />}
        />
      }
    >
      <PreviewInfo gridTemplateColumns="80px 1fr">
        <Row label="Name" value={name} />
        <Row label="Owner" value={formatOwnership(activeItem)} />
        <Row
          label="Products"
          link={`${detailsPageLink}/${DetailsPageTabs.PRODUCTS}`}
          value={`${pm_statistics_data?.count} (${product_filters?.length ? 'dynamic' : 'static'})`}
        />
        <Row
          label="Assets"
          value={`${assetCount} (${asset_filters?.length ? 'dynamic' : 'static'})`}
          link={`${detailsPageLink}/${DetailsPageTabs.ASSETS}`}
        />
        <Row
          label="Contacts"
          link={`${detailsPageLink}/${DetailsPageTabs.CONTACTS}`}
          value={`${users_count} (${user_filters?.length ? 'dynamic' : 'static'})`}
        />
        <Row label="Status" value={getStatus(deleted_at)} />
        <Row label="Created at" value={formatDate(created_at)} rawLabel />
        <Row label="Updated at" value={formatDate(updated_at)} rawLabel />
        <Row label="Deleted at" value={formatDate(deleted_at)} rawLabel hideIfNoValue />
      </PreviewInfo>
    </PreviewTab>
  );
});
