import queryString from 'query-string';
import { isString, reduce, last, compact, startCase, omit } from 'lodash';
import {
  DetailsPageTabs,
  GroupTypes,
  ICategory,
  IDivision,
  IExtendedSearchFilter,
  ItemId,
  ProductAccessTabSubTabs,
} from 'types';
import {
  IAddress,
  IContact,
  ICountry,
  IGenre,
  ILanguage,
  IGroupEntityIdName,
  IPhone,
  IProductAncestryInfo,
  IQueryParams,
  IResolution,
  ISearchParams,
} from 'types';
import { AssetFilters } from './asset';
import { Routes } from 'utils/routes';
import { IActionOpts } from '@mediafellows/chipmunk';
export { formatType } from 'utils/format-type';

export const parseDataSize = (size?: number | null, defaultEmptyValue?: string): string => {
  if (!size) return defaultEmptyValue || '';
  if (size / 1024 < 1) {
    return `${size} B`;
  }

  if (size / 1024 / 1024 < 1) {
    return `${Math.ceil(size / 1024)} kB`;
  }

  if (size / 1024 / 1024 / 1024 < 1) {
    return `${Math.ceil(size / 1024 / 1024)} MB`;
  }

  return `${Math.ceil(size / 1024 / 1024 / 1024)} GB`;
};

export const parseQueryParams = (search: string): queryString.ParsedQuery<string | number | boolean> => {
  return queryString.parse(search.substr(1), { parseNumbers: true, parseBooleans: true });
};

export const by = <T>(arr: T[], key: string): { [key: string]: T } =>
  reduce(arr, (acc, curr) => ({ [curr[key]]: curr, ...acc }), {});

export const byId = <T>(arr: T[]): { [key: string]: T } => by<T>(arr, 'id');

export const getValidIds = <T extends ItemId = ItemId, P = T>(
  items?: { id?: T | null }[],
  callback?: (id: ItemId) => P,
): P[] =>
  (items || []).reduce((acc: P[], item) => (item?.id ? acc.concat((callback?.(item.id) || item.id) as P) : acc), []);

export const parseToSearchParams = (
  { q, ids, ...params }: IQueryParams,
  additionalFilters: IExtendedSearchFilter[] = [],
): ISearchParams => {
  const filters = [...additionalFilters];

  if (isString(q) && q) {
    filters.push(['+main', 'q', `(${q}) || (${q}*)`]);
  }

  if (Array.isArray(ids)) {
    filters.push(['id', 'in', ids]);
  }

  return { search: { filters }, ...params };
};

export function parseOptionsToSearch(options: IActionOpts, paramsToOmit: string[] = []): IActionOpts {
  const filters = options?.body?.search.filters;
  const index = filters?.findIndex(([attribute]) => attribute === '+main');
  const q = filters?.[index]?.[2];
  if (q) {
    filters[index][2] = `(${q}) || (${q}*)`;
  }

  return omit(
    options,
    paramsToOmit.map((e) => `body.${e}`),
  );
}

export const pluralWordInflect = (word: string, count: number, includeCount = true): string => {
  const pluralized = count === 1 ? `${word}` : `${word}s`;
  return includeCount ? `${count} ${pluralized}` : pluralized;
};

export const pluralEntityInflect = (
  entity: string,
  count: number,
): { entityWithDemonstrative: string; entity: string; entityWithCount: string } => {
  let pluralized;
  if (count === 1) {
    entity.slice(-1) === 's' ? (pluralized = entity.slice(0, entity.length - 1)) : (pluralized = `${entity}`);
  } else {
    pluralized = `${entity}s`;
  }
  return {
    entityWithDemonstrative: count === 1 ? `this ${pluralized}` : `these ${pluralized}`,
    entity: pluralized,
    entityWithCount: `${count} ${pluralized}`,
  };
};

export const formatAddress = (address?: IAddress, countries: ICountry[] = []): string => {
  if (!address) return '';
  const { street, zip_code, city, country_id } = address || {};

  const country: ICountry | null = (country_id && countries.find((country) => country.id == country_id)) || null;

  const formattedAddress = [street, zip_code, city, country?.name].filter(Boolean).join(', ');
  return formattedAddress || '';
};

export const formatFullName = (contact?: IContact | null, withMiddleName = true): string => {
  const { first_name, last_name, middle_name, full_name } = contact || {};
  if (full_name) return full_name;

  if (!withMiddleName && contact) return [first_name, last_name].filter(Boolean).join(' ');
  return contact ? [first_name, middle_name, last_name].filter(Boolean).join(' ') : `—`;
};

export const sortPhones = (a: IPhone, b: IPhone): number => {
  const labelsOrder = ['mobile', 'work', 'home', 'fax'];
  return labelsOrder.indexOf(a.label.toLowerCase()) - labelsOrder.indexOf(b.label.toLowerCase());
};

export const getEntityType = (property?: string | null): string => {
  const [assetType] = property?.split('/') || [];
  return Object.values(AssetFilters).find((type) => assetType === type) || '';
};

export const getLanguageById = (languages: ILanguage[], languageId?: string): string => {
  return languages.find((lang) => lang.id.toLowerCase() === languageId?.toLowerCase())?.name || '';
};

export const joinLanguages = (languages: ILanguage[], language_ids?: (string | null | undefined)[]): string => {
  return (language_ids || [])
    .reduce((acc, languageId) => {
      if (!languageId) return acc;
      const language = getLanguageById(languages, languageId);
      return language ? [...acc, language] : acc;
    }, [])
    .join(', ');
};

export const formatAssetType = (classification?: string | null): string => {
  if (!classification) return '';
  return startCase(last(classification?.split('/'))).toLowerCase() || '';
};

export const formatResolution = (resolution?: IResolution): string => {
  if (!resolution?.width || !resolution?.height) return '';
  return resolution.width + 'x' + resolution.height;
};

export function formatClassifications(ids: number[], categories: IGenre[]): string {
  const categoriesById = byId(categories);

  return ids?.map((categoryId) => categoriesById[categoryId]?.name).join(', ');
}

export const isProduct = (item?: unknown): boolean => {
  if (typeof item !== 'object') {
    return false;
  }

  const type = item?.['type'];

  if (typeof type !== 'string') {
    return false;
  }

  return Boolean(type.includes('Product'));
};

export const isAsset = (item?: unknown): boolean => {
  if (typeof item !== 'object') {
    return false;
  }

  const type = (item as Record<string, unknown>)?.['type'] || (item as Record<string, unknown>)?.['@type'];

  if (typeof type !== 'string') {
    return false;
  }

  return Boolean(type.includes('Asset') || type.includes('asset'));
};

export function formatField(
  field: undefined | null | string | number | (string | number | undefined)[],
  fallback = '—',
  separator = ', ',
): string | number {
  if (!field && field !== 0) return fallback;
  if (Array.isArray(field) && !field.length) {
    return fallback;
  }

  return Array.isArray(field) ? field.filter(Boolean).join(separator) : field;
}

interface IFormatOwnershiptProps {
  owner?: IContact;
  owner_organization?: { name?: string | null };
  divisionsNames?: string | null;
  showOrg?: boolean;
}

export function formatOwnership(props?: IFormatOwnershiptProps): string {
  const { owner, owner_organization, divisionsNames, showOrg = true } = props || {};
  const ownerFields = [owner?.id && `${owner.first_name} ${owner.last_name}`, divisionsNames].concat(
    showOrg && owner_organization ? [owner_organization?.name] : [],
  );

  return compact(ownerFields).join(', ');
}
export const getEntityIdFromParams = (
  params: Record<string, string | number>,
  entityIdName: IGroupEntityIdName,
): string =>
  Object.entries(params)
    ?.find((el) => el[0] === entityIdName)?.[1]
    .toString() || '';

export const formatTimelineItemDescription = (description: string): string => {
  return description
    .replace(/"/g, '')
    .replace(/\s{2,}/g, ' ')
    .trim();
};

export const splitStringItems = (value?: string | null): string[] => {
  const trimmed = (value || '').trim();
  if (trimmed.trim() === '') {
    return [];
  }
  return trimmed.split(/\s+|\n/);
};

export const countStringItems = (value?: string | null): number => {
  return splitStringItems(value).length;
};

// Transforms entity type to entity label
export const getEntityLabel = (entityType: string): string => {
  switch (entityType) {
    case 'users':
      return 'contact';
    case 'assets':
      return 'asset';
    case 'products':
      return 'product';
    case 'events':
      return 'sub-event';
    case 'organizations':
      return 'organization';
    default:
      return entityType;
  }
};

export const hasAncestry = (
  {
    ancestors,
    effective_episode_versions_count,
    effective_episodes_count,
    effective_film_versions_count,
    effective_products_count,
    effective_programs_count,
    effective_seasons_count,
    effective_series_count,
  }: IProductAncestryInfo = {} as IProductAncestryInfo,
): boolean => {
  const hasParent = Boolean(ancestors?.length);
  const hasChildren = [
    effective_seasons_count,
    effective_series_count,
    effective_programs_count,
    effective_film_versions_count,
    effective_products_count,
    effective_episodes_count,
    effective_episode_versions_count,
  ].some((child) => child);

  return hasParent || hasChildren;
};

export const isProcessingPreview = ({
  preview_image_id,
  preview_image,
}: {
  preview_image_id?: ItemId | null;
  preview_image?: { url?: string } | null;
} = {}): boolean => {
  return Boolean(preview_image_id && !preview_image?.url);
};

export const getOptionLabels = (
  ids?: (ItemId | undefined)[] | null,
  options?: { value?: ItemId | null; label: string }[] | null,
): string[] => {
  const idsLowercased = ((ids || []).filter(Boolean) as ItemId[]).map((id) => id.toString().toLowerCase());
  const optionsToLowercase = (options || []).map(({ value, label }) => ({
    label,
    value: `${value || ''}`.toLowerCase(),
  }));

  const optionsByValue = by(optionsToLowercase, 'value');

  return idsLowercased.reduce((acc, id) => {
    if (optionsByValue[id]?.label) {
      return [...acc, optionsByValue[id].label];
    }
    return acc;
  }, []);
};

export const getStatus = (deleted_at?: string | null): string => (Boolean(deleted_at) ? 'Deleted' : 'Available');
export const formatDetailPageTabName = (tabName: DetailsPageTabs | ProductAccessTabSubTabs): string =>
  tabName.replace(/_/g, ' ');

export function rearrangeItems<T>(items?: T[] | null, ids?: ItemId[] | null, key = 'id'): T[] {
  if (!ids?.length || !items?.length) {
    return [];
  }

  const itemsById = by(items, key);
  return ids.reduce((acc, id) => (itemsById[id] ? [...acc, itemsById[id]] : acc), []);
}

export const getIsTabDisabled = (
  tab: DetailsPageTabs,
  editableTabs: DetailsPageTabs[],
  editModeEnabled: boolean,
): boolean => {
  if (!editModeEnabled) return false;
  return !editableTabs.includes(tab);
};

export const getRouteFromEntity = (type: string, entity?: string): string => {
  switch (type) {
    case GroupTypes.ACCESS_PRIVILEGE:
      return Routes.ACCESS_PRIVILEGES;
    case GroupTypes.EVENT:
      return Routes.EVENTS;
    case GroupTypes.RECOMMENDATION:
      return Routes.RECOMMENDATIONS;
    case GroupTypes.SCREENING_ROOM:
      return Routes.SCREENING_ROOMS;
    case GroupTypes.MOBILE_APP_SYNC:
      return Routes.MOBILE_APP_SYNC;
    case GroupTypes.ACCESS_GROUPS:
      return Routes.ACCESS_GROUPS;
    case GroupTypes.SELECTION:
      switch (entity) {
        case 'products':
          return Routes.PRODUCT_SELECTIONS;
        case 'assets':
          return Routes.ASSET_SELECTIONS;
        case 'users':
          return Routes.CONTACT_SELECTIONS;
        default:
          return '';
      }
    default:
      return '';
  }
};

type ITreeEntity = ICategory | IDivision;

export function formatTreeEntities(ids: number[], entities: ITreeEntity[]): string {
  if (!ids?.length) {
    return '';
  }

  const entitiesById = byId(entities);

  const entitiesFullNames = ids.map((id) => formatTreeEntity(id, entitiesById));

  return formatField(entitiesFullNames) as string;
}

export const getFullTreeEntitiesNames = (
  id: undefined | number,
  entitiesById: { [key: number]: ITreeEntity },
): string[] => {
  if (!id || !entitiesById[id]) {
    return [];
  }

  return [...getFullTreeEntitiesNames(entitiesById[id]?.parent_id, entitiesById), entitiesById[id].name];
};

export const formatTreeEntity = (id: number, entitiesById: { [key: number]: ITreeEntity }): string => {
  const field = getFullTreeEntitiesNames(id, entitiesById);

  return formatField(field, '', ' > ') as string;
};
