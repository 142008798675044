import { Model, ParametronOrder, ParametronSort } from 'helpers/filters/types';
import { IAssetToProduct, IFile, ItemId, IScheduledJob, ISubtitle } from 'types';
import { IAssetScheduledJobName } from './types';
import { IResult } from '@mediafellows/chipmunk';
import { queryProductAssets } from 'utils/apis/product-asset';
import { chipmunk } from 'utils/chipmunk';
import { getAssets } from 'utils/apis/asset';
import { logger } from 'utils/logger';
import { getAssetModel } from 'utils/asset';
import { flags } from 'utils/flags';

export const approveAssets = (item_ids: ItemId[]): Promise<void> => {
  return chipmunk.run(async ({ action }) => {
    await action(getAssetModel(), 'approve', {
      schema: `id`,
      params: { asset_ids: item_ids },
    });
  });
};

export const rejectAssets = (item_ids: ItemId[]): Promise<void> => {
  return chipmunk.run(async ({ action }) => {
    await action(getAssetModel(), 'reject', {
      schema: `id`,
      params: { asset_ids: item_ids },
    });
  });
};

export const removeAssetPreviewImage = (item_id: ItemId): Promise<void> => {
  return chipmunk.run((chipmunk) => {
    const { isMm3Assets } = flags;
    if (isMm3Assets) {
      return chipmunk.action(Model.MM3_PREVIEW_IMAGE, 'delete', {
        params: {
          asset_id: item_id,
        },
      });
    }

    return chipmunk.action('am.asset/artefact/preview_image', 'delete', {
      params: {
        asset_ids: item_id,
      },
    });
  });
};

export const deleteAssets = (item_ids: ItemId[]): Promise<void> => {
  return chipmunk.run(async ({ action }) => {
    await action(getAssetModel(), 'delete', {
      schema: `id`,
      params: { asset_ids: item_ids },
    });
  });
};

export const assignAssetsToProducts = ({
  asset_ids = [],
  product_ids = [],
  marketing_use = false,
  include_descendants = false,
}: IAssetToProduct): Promise<IResult[]> => {
  return chipmunk.run(async ({ action }) => {
    const assets = await getAssets(
      asset_ids,
      'id, name, access_level, preview_image { id, orientation }, asset_type, classification',
    );

    // calculate sequence number so newly added assets appear on the top of the list
    const [lastAsset] = await queryProductAssets(
      {
        product_ids,
        sort: ParametronSort.SEQUENCE_NUMBER,
        order: ParametronOrder.DESCENDING,
        per: 1,
        page: 1,
      },
      false,
    );
    const lastSequenceNumber = lastAsset?.sequence_number || 0;

    const body = assets.reduce((acc, asset, index) => {
      const sequence_number = lastSequenceNumber + index + 1;

      return [
        ...acc,
        ...product_ids.map((product_id) => ({
          asset_id: asset.id,
          name: asset.name,
          access_level: asset.access_level,
          preview_image_id: asset.preview_image?.id,
          preview_image_orientation: asset.preview_image?.orientation,
          asset_type: asset.asset_type,
          classification: asset.classification,
          product_id, // from form input
          include_descendants, // from form input
          marketing_use, // from form input
          sequence_number,
        })),
      ];
    }, []);

    return action(Model.PRODUCT_ASSET, 'create', {
      body: { assets: body },
    });
  });
};

export const reingestAssets = (item_ids: ItemId[]): Promise<void> => {
  return chipmunk.run(async ({ action }) => {
    await action(getAssetModel(), 'reingest', {
      params: { asset_ids: item_ids },
    });
  });
};

export const unarchiveAssets = (asset_ids: ItemId[]): Promise<void> => {
  return chipmunk.run(async ({ action }) => {
    await action(getAssetModel(), 'unarchive', {
      params: { asset_ids },
    });
  });
};

export const archiveAssets = (asset_ids: ItemId[]): Promise<void> => {
  return chipmunk.run(async ({ action }) => {
    await action(getAssetModel(), 'archive', {
      params: { asset_ids },
    });
  });
};

export const restoreAssets = (asset_ids: ItemId[]): Promise<void> => {
  return chipmunk.run(async ({ action }) => {
    await action(getAssetModel(), 'restore', {
      params: { asset_ids },
    });
  });
};

export const uploadSubtitle = async (
  assetId: ItemId,
  file: IFile,
  subtitle: Partial<ISubtitle>,
): Promise<IResult<ISubtitle> | undefined> => {
  if (!file || !file.url) {
    logger.error('No file or file.url provided to uploadSubtitle');
    return;
  }

  return chipmunk.run(async ({ action }) =>
    action(Model.SUBTITLES, 'create', {
      params: { asset_ids: [assetId] },
      body: {
        creation_type: subtitle.creation_type,
        file_attachment: file.url,
        purpose: subtitle.purpose,
        language_id: subtitle.language_id,
        label: subtitle.label,
        show_in_player: subtitle.show_in_player,
      },
    }),
  );
};

export const uploadMm3Asset = async (assetId: ItemId, file: IFile): Promise<unknown> => {
  if (!file?.url) {
    logger.error('No file or file.url provided to uploadPreviewImage');
    return;
  }

  const body = {
    id: assetId,
    file_size: file.size,
    source_url: file.url,
  };

  return chipmunk.run(async ({ action }) =>
    action(Model.MM3_ASSETS, 'upload', {
      params: { asset_ids: [assetId] },
      body: [body],
    }),
  );
};

export const uploadAsset = async (assetId: ItemId, file: IFile): Promise<unknown> => {
  if (!file?.url) {
    logger.error('No file or file.url provided to uploadPreviewImage');
    return;
  }

  const body = {
    asset: {
      file_name: decodeURIComponent(file.name),
      file_size: file.size,
      id: assetId,
    },
    src_url: file.url,
  };

  return chipmunk.run(async ({ action }) =>
    action(getAssetModel(), 'ingest', {
      body,
    }),
  );
};

export const extractPreviewImage = async (asset_id?: ItemId | null, position_offset?: number | null): Promise<void> => {
  if (!asset_id || (!position_offset && position_offset !== 0)) {
    return;
  }

  return chipmunk.run(async ({ action }) => {
    return action(Model.STORAGE_ASSET_HOST_TASK_PREVIEW_IMAGE_EXTRACTION, 'create', {
      params: {
        type: 'storage/asset_host/task/preview_image_extraction',
      },
      body: {
        type: 'storage/asset_host/task/preview_image_extraction',
        asset_id,
        position_offset,
      },
    });
  });
};

export const downloadAsset = (asset_id: ItemId): Promise<void> => {
  if (flags.isMm3Assets) {
    return downloadMm3Asset(asset_id);
  }

  return chipmunk.run(async ({ action }) => {
    const { object } = await action(Model.DOWNLOAD, 'member.download_asset', {
      params: { asset_id },
    });
    window.location.href = object.download_url;
  });
};

export const downloadMm3Asset = (asset_id: ItemId): Promise<void> => {
  return chipmunk.run(async ({ action }) => {
    const { object } = await action(Model.MM3_ASSETS, 'download', {
      params: { asset_ids: [asset_id] },
    });
    window.location.href = object.download_url;
  });
};

// this will send an email to the user with all assets to be downloaded
export const requestAssetsDownloadLink = (asset_ids: ItemId[]): Promise<void> => {
  if (flags.isMm3Assets) {
    return requestAssetsMm3DownloadLink(asset_ids);
  }

  return chipmunk.run(async ({ action }) => {
    await action(Model.DOWNLOAD_BATCH, 'create', {
      body: { asset_ids },
    });
  });
};

export const requestAssetsMm3DownloadLink = (asset_ids: ItemId[]): Promise<void> => {
  return chipmunk.run(async ({ action }) => {
    await action(Model.MM3_ASSETS, 'download_multiple', {
      body: { asset_ids },
    });
  });
};

const scheduledJobPayloadConverters = {
  change_status_on_asset: (payload) => ({
    ...payload,
    workflow_status: payload.status,
  }),
};

const prepareScheduledJobPayload = (name: IAssetScheduledJobName, payload): Record<string, unknown> => {
  return scheduledJobPayloadConverters[name]?.(payload) || payload;
};

export const createScheduledJobs = async (
  name: IAssetScheduledJobName,
  { item_ids, ...payload }: { item_ids: ItemId[] },
): Promise<IScheduledJob[]> => {
  return chipmunk.run(({ action }) => {
    return Promise.all(
      item_ids.map(async (item_id) => {
        const body = {
          ...prepareScheduledJobPayload(name, payload),
          name,
          asset_id: item_id,
        };

        return (
          await action(Model.ASSET_SCHEDULED_JOB, 'create', {
            body,
          })
        ).object;
      }),
    );
  });
};

export const loadAllScheduledJobs = async (item_ids: ItemId[] = []): Promise<IScheduledJob[]> => {
  if (item_ids.length === 0) {
    return [];
  }

  return chipmunk.run(async ({ action }) => {
    const ids_value = item_ids.join(',');

    const data = {
      params: {
        q: `status:("scheduled")
            AND asset_id:(${ids_value})`,
      },
    };
    return (await action(Model.ASSET_SCHEDULED_JOB, 'query', data)).objects;
  });
};

export const loadScheduledJobs = async (
  name: IAssetScheduledJobName,
  item_ids: ItemId[] = [],
): Promise<IScheduledJob[]> => {
  if (item_ids.length === 0) {
    return [];
  }
  return chipmunk.run(async ({ action }) => {
    const ids_value = item_ids.join(',');

    const data = {
      params: {
        q: `status:("scheduled")
            AND asset_id:(${ids_value})
            AND name:("${name}")`,
      },
    };
    return (await action(Model.ASSET_SCHEDULED_JOB, 'query', data)).objects;
  });
};

export const removeScheduledJob = (id: ItemId): Promise<IResult> => {
  return chipmunk.run((chipmunk) => {
    return chipmunk.action(Model.ASSET_SCHEDULED_JOB, 'delete', {
      params: {
        id,
      },
    });
  });
};
