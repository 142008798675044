import React from 'react';

import DateElement from 'components/date-list-element/date-list-element';

import { useBasicStore } from 'store';
import { IAsset, IMm3Asset } from 'types';
import { withAssetVersion } from 'utils/asset';
import { AssetFilters } from 'utils/asset';
import { MfxIcon } from 'icons';
import { Routes } from 'utils/routes';
import { formatDate, formatDuration } from 'utils/date';
import { getEntityType, formatAssetType, formatResolution, parseDataSize, joinLanguages } from 'utils/general';
import { getVideoResolution, placeholder } from 'utils/ui';
import { useLanguages } from 'utils/hooks';
import { Classes } from 'utils/ui';
import { DescendantsMm3Assets } from 'components/asset/assets-aggregation/descendants-assets-links';

import { AssetProtectionlevels, productAssetLink } from './utils';
import { AssetAttributes } from './asset-attributes';

export const OldAssetListItemAttributes: React.FC<{ asset: IAsset }> = ({ asset }) => {
  const { languages } = useBasicStore();
  const {
    id,
    updated_at,
    format,
    permissions,
    duration,
    published_at,
    language_ids,
    file_size,
    classification,
    products,
    height,
    protection_levels,
    width,
  } = asset;
  const assetType = getEntityType(classification as string);
  const hasResolution = height && width;

  return (
    <div className={`entity-list-item-row__attributes asset-list-item-row__attributes ${Classes.TEXT_MUTED}`}>
      <div className="asset-list-item-row__info text-nowrap">
        <span>{formatAssetType(classification as string)}</span>
        {assetType === AssetFilters.IMAGE && hasResolution && (
          <span>{` | ${formatResolution({ width, height })}`}</span>
        )}
        {assetType === AssetFilters.VIDEO && hasResolution && (
          <span>{` | ${getVideoResolution({ width, height })}`}</span>
        )}
        {(assetType === AssetFilters.VIDEO || assetType === AssetFilters.AUDIO) && duration && (
          <span>{` | ${formatDuration(duration, 'seconds')}`}</span>
        )}
        {file_size && <span>{` | ${parseDataSize(file_size)}`}</span>}
      </div>
      <div className="asset-list-item-row__format text-nowrap">{placeholder(format)}</div>
      <div className="text-truncate asset-list-item-row__permissions text-nowrap">
        <MfxIcon icon={permissions?.length ? 'download' : 'no-download'} />
      </div>
      <AssetProtectionlevels protectionLevels={protection_levels} />
      <div className="text-truncate asset-list-item-row__languages text-nowrap">
        {joinLanguages(languages, language_ids) || 'No languages'}
      </div>
      <div className="text-truncate asset-list-item-row__products text-nowrap">{productAssetLink(id, products)}</div>
      <DateElement
        date={formatDate(updated_at)}
        className="text-nowrap text-truncate asset-list-item-row__updated text-nowrap"
        mfxIcon="updated-at"
      />
      <DateElement
        date={formatDate(published_at)}
        className="text-nowrap text-truncate asset-list-item-row__published text-nowrap"
        mfxIcon="published-at"
      />
    </div>
  );
};

export const Mm3AssetListItemAttributes: React.FC<{ asset: IMm3Asset }> = ({ asset }) => {
  const { id, updated_at, permissions, published_at, products, protection_levels, children, parent_id } = asset;
  const { language_id } = asset.meta || {};
  const { format } = asset.source_meta || {};
  const language = useLanguages([language_id]);

  return (
    <div className={`entity-list-item-row__attributes asset-list-item-row__attributes ${Classes.TEXT_MUTED}`}>
      <div className="asset-list-item-row__info text-nowrap">
        <AssetAttributes asset={asset} />
      </div>
      <DescendantsMm3Assets
        className="asset-list-item-row__children"
        assets={children}
        baseUrl={`${Routes.ASSETS}/${id}`}
        muted
        fallback="—"
      />
      <div className="asset-list-item-row__format text-nowrap">{placeholder(format)}</div>
      <div className="text-truncate asset-list-item-row__permissions text-nowrap">
        <MfxIcon icon={permissions?.length ? 'download' : 'no-download'} />
      </div>
      <AssetProtectionlevels protectionLevels={protection_levels} />
      <div className="text-truncate asset-list-item-row__languages text-nowrap">{language || 'No languages'}</div>
      <div className="text-truncate asset-list-item-row__products text-nowrap">
        {placeholder(parent_id ? undefined : productAssetLink(id, products))}
      </div>
      <DateElement
        date={formatDate(updated_at)}
        className="text-nowrap text-truncate asset-list-item-row__updated text-nowrap"
        mfxIcon="updated-at"
      />
      <DateElement
        date={formatDate(published_at)}
        className="text-nowrap text-truncate asset-list-item-row__published text-nowrap"
        mfxIcon="published-at"
      />
    </div>
  );
};

export const AssetListItemAttributes = withAssetVersion(OldAssetListItemAttributes, Mm3AssetListItemAttributes);
