import React, { useCallback } from 'react';
import { UmGenericListCollection } from '@mediafellows/mm3-types';

import { formatDate } from 'utils/date';
import { InfoBoxV2 } from 'components/info-box-v2';
import { Row } from 'components/label-value-pair';
import { IUseMm3FormReturn } from 'helpers/form/use-mm3-form';
import {
  FormCategories,
  FormCheckbox,
  FormInput,
  FormMultiSelect,
  FormSelect,
  IFormMultiSelectOption,
} from 'helpers/form';
import { ItemId } from 'types/utility';
import { formatCollectionValueToOption } from 'utils/list';
import { CustomFieldsEdit } from 'pages/collection-details/collection-overview-tab/custom-fields';
import { formatOwnership } from 'utils/general';

interface ICollectionOverviewProps {
  productIds?: ItemId[];
  subCollectionsCount?: number;
  loadingSubCollections: boolean;
}

export const CollectionOverviewForm: React.FC<
  ICollectionOverviewProps & { collectionForm: IUseMm3FormReturn<UmGenericListCollection> }
> = ({ collectionForm, productIds, subCollectionsCount = 0, loadingSubCollections }) => {
  const { formData, handlers, values } = collectionForm;
  const fetchAllowedItemTypes = useCallback(
    async ({ ids }): Promise<IFormMultiSelectOption[]> => {
      if (ids?.length) {
        return ids.map(formatCollectionValueToOption);
      }
      return ['separator', 'product', ...(values?.sublist ? [] : ['list/collection'])].map(
        formatCollectionValueToOption,
      );
    },
    [values?.sublist],
  );
  const { sublist, allowed_item_types, created_at, updated_at } = values;
  const mustBeMainCollection = allowed_item_types?.includes('list/collection') || subCollectionsCount > 0;
  const ownershipInfo = formatOwnership(values);

  return (
    <InfoBoxV2 gridTemplateColumns="1fr 4fr" wrapperClassName="py-3" className="collection-overview__attributes">
      <Row rawLabel label="Name*" value={<FormInput name="name" {...formData.name} {...handlers} required={false} />} />
      <Row
        rawLabel
        label="Identifier*"
        value={<FormInput name="purpose" {...formData.purpose} {...handlers} required={false} />}
      />
      <Row
        label="Genres"
        value={
          <FormCategories
            placeholder="Select Genres..."
            name="meta.category_ids"
            {...formData.meta?.category_ids}
            {...handlers}
            large
          />
        }
      />
      <Row
        label="Is Sub-Collection"
        rawLabel
        value={
          <FormCheckbox
            label={
              mustBeMainCollection
                ? `(this Collection can not be turned into a Sub-Collection because it has Sub-Collections ${
                    subCollectionsCount > 0 ? 'assigned to it' : 'set as an allowed object'
                  })`
                : values?.sublist
                ? '(can not turn a Sub-Collection into a Main Collection)'
                : undefined
            }
            name="sublist"
            groupClassName="mb-0"
            {...formData.sublist}
            {...handlers}
            required={false}
            disabled={mustBeMainCollection || values?.sublist || false}
          />
        }
      />
      <Row
        label="Allowed objects*"
        rawLabel
        value={
          <FormMultiSelect
            large
            name="allowed_item_types"
            placeholder="Select Allowed Objects..."
            {...handlers}
            {...formData?.allowed_item_types}
            options={undefined}
            fetchValues={fetchAllowedItemTypes}
            required={false}
          />
        }
      />
      <Row
        rawLabel
        label="Access level*"
        value={<FormSelect name="access_level" {...formData.access_level} {...handlers} required={false} large />}
      />
      <CustomFieldsEdit collectionForm={collectionForm} />
      <Row
        label="Description"
        value={
          <FormInput
            placeholder="Type description..."
            textarea
            name="meta.description"
            {...formData.meta?.description}
            {...handlers}
          />
        }
      />
      <Row label="Ownership" value={ownershipInfo} size="m" />
      <Row label="Created at" rawLabel value={formatDate(created_at)} size="m" />
      <Row label="Updated at" rawLabel value={formatDate(updated_at)} size="m" />

      {!sublist && (
        <Row
          label="Sub-Collection count"
          rawLabel
          value={subCollectionsCount}
          loading={loadingSubCollections}
          valueClassName="d-block"
        />
      )}
      <Row label="Products count" rawLabel value={productIds?.length} valueClassName="d-block" />
    </InfoBoxV2>
  );
};
