import React, { useState, useMemo } from 'react';
import { observer } from 'mobx-react-lite';
import {
  FilterCheckbox,
  FilterDateRange,
  FilterSelect,
  FilterText,
  FilterSwitch,
  FilterTree,
} from 'helpers/filters/fields';
import { Switch } from '@mantine/core';
import { IResult } from '@mediafellows/chipmunk';

import { IFiltersDefinition, QFilter, RangeFilter, EqFilter, Model, InFilter } from 'helpers/filters/types';
import {
  ContextDataProvider,
  dynamicDataExecutorCache,
  queryDataExecutorCache,
  QueryDataProvider,
  StaticDataProvider,
} from 'helpers/data-provider/option-data-provider';

import { useStore } from 'store';
import { useFilters } from 'helpers/filters';
import { useFetchFieldOptions } from 'helpers/form';
import { submitFilters, toggleParam } from 'helpers/filters/helpers';
import { customContactParam } from 'components/contact-data-section';
import { IContact, IDivision } from 'types';
import { DataSectionPreset } from 'components/data-section-preset';
import { AdditionalFiltersCmp } from 'components/contact-filters/additional-filters';
import { useLocation } from 'react-router-dom';
import { chipmunk } from 'utils/chipmunk';
import { parseToSearchParams, parseQueryParams } from 'utils/general';
import { fetchResponsibleContacts } from 'utils/apis/contacts';
import { fetchRoles } from 'utils/apis/role';
import { getContactName } from 'utils/ui';
import { DynamicTreeDataProvider, unflattenTree } from 'helpers/data-provider/tree-data-provider';

export interface IContactFilters extends IFiltersDefinition {
  _: QFilter;
  status: InFilter;
  role_id: EqFilter;
  activated_at: RangeFilter;
  created_at: RangeFilter;
  last_login_at: RangeFilter;
  organization_id: EqFilter;
  country_id: EqFilter;
  responsible_user_id: EqFilter;
  bouncing: EqFilter;
  mobile_sync: EqFilter;
  division_ids: InFilter;
}

const organizationOptionsProvider = new QueryDataProvider(
  queryDataExecutorCache('um.organization.search', (q: string) =>
    chipmunk.run(async (chipmunk) => {
      return await chipmunk.action(Model.ORGANIZATIONS, 'search', {
        schema: `id, name`,
        body: parseToSearchParams({ q }),
      });
    }),
  ),
  (data: IResult) => data.objects.map((l) => ({ value: l.id, label: l.name })),
);

const responsibleContactOptionsProvider = new QueryDataProvider(
  queryDataExecutorCache('um.user.search.resp.contacts', async (query: string) => {
    const objects = await fetchResponsibleContacts(query);
    return { objects, object: objects[0] };
  }),
  (result: IResult) => result.objects.map((l: IContact) => ({ value: l.id, label: getContactName(l) })),
);

const statusOptionsProvider = new ContextDataProvider(Model.CONTACTS, 'status');

const defaultParamComponentValues = (queryParams): typeof customContactParam => ({
  include_deleted: !!queryParams['include_deleted'] && queryParams['include_deleted'].toString() === 'true',
  include_internal_accounts:
    !!queryParams['include_internal_accounts'] && queryParams['include_internal_accounts'].toString() === 'true',
});

const divisionTreeProvider = new DynamicTreeDataProvider(
  dynamicDataExecutorCache('divisions', () => chipmunk.run(({ action }) => action(Model.DIVISIONS, 'query'))),
  (data: IResult<IDivision>) =>
    unflattenTree(data.objects.map((d) => ({ label: d.name, value: d.id, ancestry: d.ancestry || '' }))),
);

const ContactFilters: React.FC = observer(() => {
  const {
    basicStore: { countriesDataOptions },
  } = useStore();
  const location = useLocation();
  const { dataSectionStore } = useStore();
  const { searchStore } = dataSectionStore;
  const queryParams = parseQueryParams(location.search);

  const roleOptions = useFetchFieldOptions(fetchRoles);
  const [paramComponentValues, setParamComponentValues] = useState(defaultParamComponentValues(queryParams));
  const rolesOptionsProvider = useMemo(() => new StaticDataProvider(roleOptions), [roleOptions]);
  const countryOptionsProvider = useMemo(() => new StaticDataProvider(countriesDataOptions), [countriesDataOptions]);

  const handleSubmit = (action): void => {
    submitFilters({
      action,
      setParamComponentValues,
      customParams: customContactParam,
    });
  };

  const [filterValues, filterHandlers] = useFilters<IContactFilters>(dataSectionStore, handleSubmit);

  const handleToggleParam = (event): void => {
    toggleParam({
      event,
      paramComponentValues,
      setParamComponentValues,
    });
  };

  return (
    <div className="entity-filters">
      <DataSectionPreset filterHandlers={filterHandlers} />
      <div className="entity-filters__new-filter">
        <form>
          <FilterText
            label="Search List"
            placeholder="Search"
            name="_"
            filter={filterValues._}
            onChange={filterHandlers.onChange}
          />

          <AdditionalFiltersCmp filterValues={filterValues} filterHandlers={filterHandlers} />

          <FilterSelect
            label="Platform Role"
            name="role_id"
            placeholder="Select Platform Role"
            optionsProvider={rolesOptionsProvider}
            filter={filterValues.role_id}
            onChange={filterHandlers.onChange}
          />

          <FilterCheckbox
            label="Status"
            name="status"
            optionsProvider={statusOptionsProvider}
            disabled={searchStore.running}
            filter={filterValues.status}
            onChange={filterHandlers.onChange}
            aggregations={searchStore.aggregationValues('status')}
          />

          <FilterDateRange
            label="Created at"
            name="created_at"
            filter={filterValues.created_at}
            onChange={filterHandlers.onChange}
          />

          <FilterDateRange
            label="Activated at"
            name="activated_at"
            filter={filterValues.activated_at}
            onChange={filterHandlers.onChange}
          />

          <FilterDateRange
            label="Last login at"
            name="last_login_at"
            filter={filterValues.last_login_at}
            onChange={filterHandlers.onChange}
          />

          <FilterSelect
            label="Organization"
            name="organization_id"
            placeholder="Select Organization"
            optionsProvider={organizationOptionsProvider}
            filter={filterValues.organization_id}
            onChange={filterHandlers.onChange}
          />
          <FilterTree
            label="Divisions"
            name="division_ids"
            optionsProvider={divisionTreeProvider}
            filter={filterValues.division_ids}
            onChange={filterHandlers.onChange}
            aggregations={searchStore.aggregationValues('division_ids')}
          />

          <FilterSelect
            label="Country"
            name="country_id"
            placeholder="Select Country"
            optionsProvider={countryOptionsProvider}
            filter={filterValues.country_id}
            onChange={filterHandlers.onChange}
          />

          <FilterSelect
            label="Responsible Contact"
            name="responsible_user_id"
            placeholder="Select Responsible Contact"
            optionsProvider={responsibleContactOptionsProvider}
            filter={filterValues.responsible_user_id}
            onChange={filterHandlers.onChange}
          />

          <FilterSwitch
            label="Mobile App Access"
            name="mobile_sync"
            filter={filterValues.mobile_sync}
            onChange={filterHandlers.onChange}
            checkedValue={true}
            notCheckedValue={false}
          />

          <FilterSwitch
            label="Bouncing emails"
            name="bouncing"
            filter={filterValues.bouncing}
            onChange={filterHandlers.onChange}
            checkedValue={true}
            notCheckedValue={false}
          />

          <Switch
            mb="sm"
            label="Include internal accounts"
            data-param={'include_internal_accounts'}
            onChange={handleToggleParam}
            checked={paramComponentValues.include_internal_accounts}
          />

          <Switch
            mb="sm"
            label="Include deleted"
            data-param={'include_deleted'}
            onChange={handleToggleParam}
            checked={paramComponentValues.include_deleted}
          />
        </form>
      </div>
    </div>
  );
});

export default ContactFilters;
