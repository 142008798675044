import React from 'react';
import { observer } from 'mobx-react-lite';
import { ActionIcon, ColorSwatch, Popover } from '@mantine/core';
import { ChevronDown, ChevronRight } from 'blueprint5-icons';
import { RenderTreeNodePayload } from '@mantine/core/lib/components/Tree/Tree';
import { useDisclosure } from '@mantine/hooks';
import cx from 'classnames';

import { useDataSectionStore } from 'store/hooks';

import { ActionsMenu } from 'components/action/utils';
import { Thumbnail } from 'components/thumbnail';
import { GenreActionsList } from 'components/action';

import { IUseActionsOptionParam } from 'utils/actions';
import { Classes, MantineIcon } from 'utils/ui';
import { pluralEntityInflect } from 'utils/general';

import { IGenreTreeItem } from 'types';

interface IGenreDataSectionItemProps extends Partial<RenderTreeNodePayload> {
  genre: IGenreTreeItem;
  handleClick: (e: React.MouseEvent<HTMLDivElement>) => void;
  options: IUseActionsOptionParam;
}
const noOp = (): null => null;

export const GenreDataSectionItem: React.FC<IGenreDataSectionItemProps> = observer(
  ({ genre, handleClick, expanded, hasChildren, elementProps, options }) => {
    const { id, name, preview_image, invisible } = genre;
    const [opened, { close, open }] = useDisclosure(false);
    const { getIsActive } = useDataSectionStore();
    const { entityWithCount } = pluralEntityInflect('sub-genre', genre?.children?.length || 0);

    return (
      <div
        onClick={handleClick}
        data-id={id}
        className={cx('genre-list-item', {
          'entity-list-item--active': getIsActive(id),
        })}
      >
        <div className="d-flex gap-2">
          {!expanded && (
            <ActionIcon
              {...elementProps}
              onClick={hasChildren ? elementProps?.onClick : noOp}
              style={{ cursor: hasChildren ? 'pointer' : 'default' }}
            >
              <MantineIcon icon={<ChevronRight color={!hasChildren ? 'transparent' : 'currentColor'} />} />
            </ActionIcon>
          )}
          {expanded && (
            <ActionIcon
              {...elementProps}
              onClick={hasChildren ? elementProps?.onClick : noOp}
              style={{ cursor: hasChildren ? 'pointer' : 'default' }}
            >
              <MantineIcon icon={<ChevronDown color={!hasChildren ? 'transparent' : 'currentColor'} />} />
            </ActionIcon>
          )}

          <div className="entity-list-item-row__thumb">
            <Thumbnail image={preview_image?.url} fallbackIconName="diagram-tree" />
          </div>
        </div>
        <div className="text-truncate text-nowrap">
          <div className={cx('d-flex justify-content-between selections-list-item__title', { 'gap-2': invisible })}>
            <div className="text-nowrap text-truncate">{name}</div>
            {invisible && (
              <Popover opened={opened}>
                <Popover.Target>
                  <span onMouseEnter={open} onMouseLeave={close}>
                    <MantineIcon icon="eye-off" />
                  </span>
                </Popover.Target>
                <Popover.Dropdown>
                  <div className="p-2 d-flex flex-column">
                    <span>Genre is invisible</span>
                  </div>
                </Popover.Dropdown>
              </Popover>
            )}
            <ActionsMenu component={GenreActionsList} items={[genre]} options={options} />
          </div>
          <div className={`genre-list-item__attributes ${Classes.TEXT_MUTED}`}>
            <span>{entityWithCount}</span>
            {genre?.color ? (
              <div className="d-flex align-items-center gap-1">
                <ColorSwatch color={genre?.color} />
                <span>{genre?.color}</span>
              </div>
            ) : (
              <>—</>
            )}
          </div>
        </div>
      </div>
    );
  },
);
