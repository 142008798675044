import React from 'react';

import { LabelValuePair as Row } from 'components/label-value-pair';

import { getIncludedAttribute } from 'components/product-preview-tab/utils';

import { ISmartGroupFormComponent } from 'types/smart-group';
import { parseDifferentContacts } from 'utils/apis/groups';
import { formatDate } from 'utils/date';
import { DataSectionStore } from 'store/data-section-store';
import { IContact, IProduct } from 'types';

export const SmartGroupFormSummary: React.FC<
  ISmartGroupFormComponent & {
    productDataSection: DataSectionStore<IProduct>;
    assetsCount: number;
    contactDataSection: DataSectionStore<IContact>;
  }
> = ({ assetsCount, contactDataSection, form: { values }, productDataSection }) => {
  const contacts = parseDifferentContacts(values.contacts);

  return (
    <div className="d-flex flex-column">
      <Row
        rawLabel
        labelClassName="pt-2 wizard_summary__label"
        valueClassName="ms-2 wizard_summary__value--border"
        label="Name:"
        value={values.name}
      />
      <Row
        rawLabel
        labelClassName="pt-2 wizard_summary__label"
        valueClassName="ms-2 wizard_summary__value--border"
        label="Access level:"
        value={values.access_level}
      />
      <Row
        rawLabel
        labelClassName="pt-2 wizard_summary__label"
        valueClassName="ms-2 wizard_summary__value--border"
        label="Expires at:"
        value={formatDate(values.expires_at)}
        hideIfNoValue
      />
      <Row
        rawLabel
        labelClassName="pt-2 wizard_summary__label"
        valueClassName="ms-2 wizard_summary__value--border"
        label="Products:"
        value={
          values.product_selection_method === 'dynamic'
            ? productDataSection.searchStore.totalCount
            : values.product_ids?.length
        }
      />
      <Row
        rawLabel
        labelClassName="pt-2 wizard_summary__label"
        valueClassName="ms-2 wizard_summary__value--border"
        label="Descendant Products:"
        hidden={values.product_selection_method === 'dynamic'}
        value={getIncludedAttribute(values.include_descendants)}
      />
      <Row
        rawLabel
        labelClassName="pt-2 wizard_summary__label"
        valueClassName="ms-2 wizard_summary__value--border"
        label="Assets:"
        value={values.asset_selection_method === 'dynamic' ? assetsCount : values.asset_ids?.length}
      />
      <Row
        rawLabel
        labelClassName="pt-2 wizard_summary__label"
        valueClassName="ms-2 wizard_summary__value--border"
        label="Contacts:"
        value={
          values.contact_selection_method === 'dynamic'
            ? contactDataSection.searchStore.totalCount
            : contacts.users.length + contacts.selections_users_count
        }
      />
    </div>
  );
};
