import { ISelectionEntityType } from 'utils/actions/types';
import { IIdentifiable, ItemId, IPreviewImage, ILanguage, IFileMeta } from './utility';
import { Product3Types } from './product';
import { IConference } from './conference';
import {
  McGenericRecommendationProduct,
  UmGenericListCollection,
  PmGenericCastCrew,
  AmGenericNews,
  AmGenericAsset3,
  AmGenericAsset3Digital,
  AmGenericAsset3DigitalVideo,
  AmGenericAsset3DigitalAudio,
  AmGenericAsset3DigitalImage,
  AmGenericAsset3DigitalDocument,
  PmGenericProduct3,
  PmGenericLayer3MotionPictureStandard,
  AmGenericAsset3DigitalSubtitle,
  AmGenericAsset3DigitalOther,
  CalGenericLocation,
  PmCrunchyrollProduct3MotionPictureEpisode,
  PmCrunchyrollProduct3MotionPictureEpisodeVersion,
  PmCrunchyrollProduct3MotionPictureFilmVersion,
  PmCrunchyrollProduct3MotionPictureProgram,
  PmCrunchyrollProduct3MotionPictureSeason,
  PmCrunchyrollProduct3MotionPictureSeries,
  AmCrunchyrollAsset3,
  AmCrunchyrollAsset3Digital,
  AmCrunchyrollAsset3DigitalAudio,
  AmCrunchyrollAsset3DigitalDocument,
  AmCrunchyrollAsset3DigitalImage,
  AmCrunchyrollAsset3DigitalOther,
  // AmCrunchyrollAsset3DigitalSubtitle,
  AmCrunchyrollAsset3DigitalVideo,
  AmCrunchyrollAsset3DigitalSubtitle,
} from '@mediafellows/mm3-types';
import { IAssetMainClassification } from './asset';
import { IItemRendererProps } from '@blueprintjs/select/src/common/itemRenderer';
import { ParametronOrder, ParametronSort } from 'helpers/filters/types';
import { IListItem } from './list';
import { IFilterOption } from 'utils/hooks';
import type { IContact } from './contact';

export * from './analytics';
export * from './asset';
export * from './utility';
export * from './product';
export * from './list';
export type { IConference, IBuyerDomain } from './conference';
export type { IAffiliation } from './affiliation';
export type { IDeliveryRecipient, IGenericMessage } from './delivery-package';
export type { IRecommendationSentRecipient } from './recommendation';
export type { ITimelineItem } from './timeline';
export type { IGroupAddContact } from './group';
export type { INewsFormData, INewenNewsFormData } from './news';
export type { IContact, INewenContact, ITiffContact, ILgtContact } from './contact';
export { ReportTypes } from './producer-portal';
export type { ICustomField } from './custom-fields';

export enum Globals {
  MODE_DARK_CLASS = 'bp3-dark',
  MODE_DARK_CLASS_BP5 = 'bp5-dark',
  SIDEBAR_MOBILE_CLASS = 'sidebar--mobile',
  SIDEBAR_COLLAPSED_CLASS = 'sidebar--collapsed',
  DATASECTION_SIDEBAR_COLLAPSED_CLASS = 'data-section-sidebar--hidden',
  DATASECTION_TILE_VIEW_CLASS = 'data-section__content--tile',
  THEME_LIGHT_LS = 'mf-theme-light',
  SIDEBAR_COLLAPSED_LS = 'mf-sidebar-collapsed',
  DATASECTION_SIDEBAR_COLLAPSED_LS = 'mf-data-section-sidebar',
  DATASECTION_TILE_VIEW_LS = 'mf-data-section-tile-view',
  DATASECTION_ITEMS_PER_PAGE_LS = 'mf-data-section-items-per-page',
  AFFILIATION_LOGO_UPDATE = 'affiliation-logo-update',
}

export enum DetailsPageTabs {
  GENERAL = 'general',
  OVERVIEW = 'overview',
  ANALYTICS = 'analytics',
  LISTS = 'baskets',
  METADATA = 'metadata',
  TIMELINE = 'timeline',
  ACTIVITY = 'activity',
  ACCESS = 'access',
  WORKFLOW = 'workflow',
  NEWS = 'news_content',
  ASSETS = 'assets',
  SUB_ASSETS = 'sub-assets',
  PRODUCTS = 'products',
  CONTACTS = 'contacts',
  FINANCIAL = 'financial',
  EMAILS = 'emails',
  RELATED = 'related',
  SERIES = 'series',
  SEASONS = 'seasons',
  EPISODES = 'episodes',
  EPISODE_VERSION = 'episode_versions',
  FILM_VERSION = 'film_versions',
  PROGRAMS = 'programs',
  IMAGES = 'images',
  RECIPIENTS = 'recipients',
  ATTENDEES = 'attendees',
  ARTIFACTS = 'artifacts',
  LAYOUT = 'layout',
  SUB_EVENTS = 'sub-events',
  SUB_COLLECTIONS = 'sub-collections',
  ORGANIZATIONS = 'organizations',
  LIST = 'top-lists',
  SUBTITLES = 'subtitles',
  REPORTS = 'reports',
  DETAILED_STATS = 'detailed_stats',
  CC_LIST = 'cc_list',
  CAST_CREW = 'cast_crew',
  ITEMS = 'items',
  CONNECTIONS = 'connections',
  DELIVERIES = 'deliveries',
  AUDIO_TRACKS = 'tracks',
  EMAIL_SETTINGS = 'email_settings',
  MOBILE_APP = 'mobile_app',
  DELIVERY_INFORMATION = 'delivery_information',
  PRODUCT_SEARCH_STATS = 'product_search_stats',
  OPERATIONS = 'operations',
}

export enum ProductAccessTabSubTabs {
  ACCESS_PRIVILEGE = 'access_privilege',
  RECOMMENDATION = 'recommendation',
}
export enum GroupTypes {
  ACCESS_PRIVILEGE = 'group/access_privilege',
  SELECTION = 'group/set',
  EVENT = 'group/event',
  RECOMMENDATION = 'group/recommendation',
  MOBILE_APP_SYNC = 'group/mobile_sync',
  SCREENING_ROOM = 'group/selection',
  ACCESS_GROUPS = 'group/general',
  SMART_GROUPS = 'group/smart',
  GUILD = 'group/guild',
}

export enum DashboardHotkeys {
  TOGGLE_SEARCH = 'shift + o',
  TOGGLE_DARK_LIGHT = 'shift + i',
  TOGGLE_SIDEBAR = 'shift + p',
}

export enum DialogTypes {
  CONFIRM = 'confirm',
  INFO = 'info',
  MODAL = 'modal',
}

export enum SharedConstants {
  NOT_ADDED = 'N/A',
}

export type IWithRequired<T, K extends keyof T> = T & { [P in K]-?: T[P] };
export type IWithDeepRequired<T> = T & { [P in keyof T]-?: T[P] extends IPrimitive ? T[P] : IWithDeepRequired<T[P]> };

export type IPrimitive = null | undefined | string | number | boolean | symbol | bigint;
export type IGroupVariety =
  | (IGroup & IIdentifiable)
  | IAccessPrivilege
  | IGroup
  | IMarketingEvent
  | ISelection
  | ISelectionStandardized
  | IRecommendation
  | IMobileAppSync
  | IGuild;

export type IDeepPartial<T> = T extends object ? { [P in keyof T]?: IDeepPartial<T[P]> } : T;

export type IGroupType =
  | GroupTypes.SELECTION
  | GroupTypes.EVENT
  | GroupTypes.ACCESS_PRIVILEGE
  | GroupTypes.MOBILE_APP_SYNC
  | GroupTypes.SCREENING_ROOM
  | GroupTypes.SMART_GROUPS
  | GroupTypes.GUILD;

export type IAccessLevels = 'private' | 'division' | 'company' | 'viewable' | 'public';

export interface IUserRole {
  id: number;
  name: string;
  users_count?: number;
  password_expires: boolean;
  internal_account: boolean;
  power_sets: string[];
  designation: string;
}

export enum UserStatus {
  ACTIVE = 'active',
  BLOCKED = 'blocked',
  DELETED = 'deleted',
  INACTIVE = 'inactive',
  INVITED = 'invited',
  PENDING = 'pending',
  REJECTED = 'rejected',
  REVIEW = 'review',
}

export type IDeliveryInformationLabel = 'Delivery Specifications' | 'File Naming Information';

export interface IOrganizationDeliveryInformation {
  id?: string;
  spec_type: string;
  page_url: string;
  document: string;
  label: IDeliveryInformationLabel;
  updated_at: string;
}

export interface IOrganizationLayer {
  subtitle?: string;
}
export interface IOrganization {
  '@type'?: string;
  id: ItemId;
  addresses?: IAddress[];
  access_level?: string;
  classification?: string;
  deleted_at?: string;
  language: ILanguage;
  language_id?: string;
  legal_entity?: string;
  name?: string;
  status?: string;
  users_count?: number;
  phones?: IPhone[];
  email: string;
  social_media_profiles: ISocialMediaProfile[];
  urls: IUrl[];
  country: ICountry;
  registration_number: string;
  responsible_user: IContact;
  responsible_user_id: number;
  tags: string[];
  geo_scopes?: IGeoScope[];
  geo_scope_ids?: string[];
  courier_services: ICourierService[];
  created_at: string;
  updated_at: string;
  key_persons: IKeyPerson[];
  vat_number: number;
  vat_rate: number;
  withholding_tax_rate: number;
  tax_number: string;
  currency_id: string;
  bank_accounts: IBankAccount[];
  users?: IContact[];
  country_id?: string;

  description?: string;
  producer_refs?: string[];
  is_producer?: boolean;
  logo: string;
  banner: string;

  delivery_informations?: IOrganizationDeliveryInformation[];

  default_layer?: IOrganizationLayer;
}

export interface ICountry {
  name?: string;
  id: string;
  number?: number | null;
}

export interface IAddress {
  id?: string;
  label?: string;
  street: string;
  supplement: string;
  zip_code: string;
  city: string;
  state?: string;
  country_id?: string;
  country?: string;
}
export interface IPhone {
  label: string;
  number: string;
  sequence_number?: number;
}

export interface IImContact {
  label: string;
  contact: string;
}

export interface ISocialMediaProfile {
  label: string;
  link: string;
  sequence_number: number;
}

export interface IUrl {
  id: string;
  url: string;
  sequence_number: number;
}

export interface IKeyPerson {
  label: string;
  name: string;
}

export interface IBankAccount {
  name: string;
  iban: string;
  swift_bic: string;
  account_number: string;
  sort_code: string;
  description: string;
}

export interface ICourierService {
  company: string;
  account: string;
}

export interface IGeoScope {
  id: string;
  name?: string;
}

export interface ITimelineData {
  user_title: string;
  effective_at: string;
  description: string;
  client_ip: string;
  entity_title: string;
  other_entity_title: string;
  other_entity_type: string;
  other_entity_id: string;
  diff: string;
  user: IContact;
}

export interface IFetchPaginatedDataResponse<T> {
  data: T[];
  totalPages: number;
}

export type IFetchTimelineResponse = IFetchPaginatedDataResponse<ITimelineData>;
export type IContactActivityData = ITimelineData;
export type IFetchContactActivityResponse = IFetchPaginatedDataResponse<IContactActivityData>;

export interface IProduct extends PmGenericProduct3 {
  assets: IProductAsset[];
  ancestry_info: IProductAncestryInfo;
  ancestry: string;
  children_count: number;
  created_at: string;
  default_layer: IProductLayer;
  default_layer_id: number;
  display_title: string;
  deactivate_at: string;
  deactivated_after: string;
  deleted_at: string;
  episodes_count: number;
  external_reference: string | null | undefined;
  flat_sequence_number: string;
  full_title?: string;
  id: number;
  inherited_preview_image: IPreviewImage;
  latest_video_added_at: string;
  meta: {
    related_product_ids: number[];
    published_at: string;
    slug: string;
    ccid: string | undefined;
  };
  group_ids: number;
  owner: IContact;
  owner_organization: IOrganization;
  parent_id: number | null;
  preview_image: IPreviewImage;
  published_at?: string;
  seasons_count: number;
  sequence_number: number;
  title: string;
  type: Product3Types;
  updated_at: string;
  year_of_production?: string;
  produced_by?: string;
  genre?: string;
  categories?: string;
}

export interface IProductAncestryInfo {
  ancestors: IAncestor[];
  effective_episode_versions_count: number;
  effective_episodes_count: number;
  effective_film_versions_count: number;
  effective_products_count: number;
  effective_programs_count: number;
  effective_seasons_count: number;
  effective_series_count: number;
  product_id?: number;
}

export interface IAncestor {
  display_title: string;
  product_id: number;
}
export interface IProductLayer extends PmGenericLayer3MotionPictureStandard {
  access_level: string;
  acclaim: string;
  aka: string;
  brand_name: string;
  categories: ICategory[];
  copyright_notice: string;
  countries: { name: string }[];
  created_at?: string;
  crews?: { name: string; role: string }[];
  display_definition?: string;
  duration?: number;
  duration_text?: string;
  episode_production_no?: string;
  format?: { name?: string };
  gross_box_office?: string;
  highlights?: string;
  id: number;
  identifier_accounting_reference?: string;
  identifier_archive_number: string;
  identifier_crid: string;
  identifier_guid: string;
  identifier_imdb: string;
  identifier_isan: string;
  identifier_isbn: string;
  identifier_iswc: string;
  identifier_umid: string;
  identifier_upid: string;
  identifier_uri: string;
  identifier_v_isan: string;
  internal_rating: string;
  language_versions: ILanguageVersion[];
  library: string;
  logline: string;
  long_synopsis: string;
  original_broadcaster: string;
  product_id: number;
  product_type: string;
  rating: string;
  resolution: string;
  synopsis: string;
  title: string;
  topics: { name: string }[];
  trademark: string;
  updated_at: string;
  web_page: string;
}

export interface ICategory {
  id: number;
  name: string;
  parent_id?: number;
  color?: string;
  invisible?: boolean;
  preview_image?: IPreviewImage;
  preview_image_id?: number;
  parent?: ICategory;
  sequence_no?: number;
}

export interface IGenreTreeItem extends ICategory {
  children: IGenreTreeItem[];
  label: string;
  value: string;
  sequence_number?: number;
}

export interface IDivisionTreeItem extends IDivision {
  children: IDivisionTreeItem[];
  label: string;
  value: string;
}

export interface ILanguageVersion {
  language_type: string;
  comment?: string;
  language_id: number | string;
}

export interface IProductAsset {
  id: number;
  asset_type: string;
  marketing_use: boolean;
  asset_id: number;
  classification: string;
  asset?: IAsset | IMm3Asset;
  sequence_number: number;
  product_id: number;
  parent_id: number;
}

export interface ISelectionItem {
  selection_item_id: number | string;
}

export interface IAssetGroupItem {
  id: number;
  asset_id: number;
  asset?: IAsset;
}

export interface IProductGroupItem {
  id: number;
  product_id: number;
  product?: IProduct;
}

export interface ISubtitle {
  id?: ItemId;
  purpose?: 'subtitle' | 'caption';
  asset?: IAsset;
  storage_url?: string;
  language_id?: string;
  language?: ILanguage;
  label?: string;
  status?: string;
  asset_id: ItemId;
  type?: string;
  creation_type?: 'Manually (human-created)' | 'Manually with AI-Support' | 'Fully automated (ML/AI-generated)';
  file_attachment?: string;
  show_in_player?: boolean;
}

export type IGenericMm3Asset = AmGenericAsset3 &
  AmGenericAsset3Digital &
  AmGenericAsset3DigitalVideo &
  AmGenericAsset3DigitalAudio &
  AmGenericAsset3DigitalImage &
  AmGenericAsset3DigitalOther &
  AmGenericAsset3DigitalSubtitle &
  AmGenericAsset3DigitalDocument;

export type IMm3Asset = {
  products?: IProduct[];
  file_status: string;
  product_asset?: IProductAsset;
  children?: IMm3Asset[];
} & IGenericMm3Asset;

export interface IAudioTrack {
  ['@type']: string;
  id?: ItemId;
  asset?: IAsset;
  storage_url?: string;
  language_id?: string;
  language?: ILanguage;
  label?: string;
  status?: string;
  asset_id: ItemId;
  file_attachment?: string;
  show_in_player?: boolean;
}

export interface IMm3AudioTrack extends Omit<IAudioTrack, '@type'> {
  ['$type']: string;
}

export interface IAsset {
  ['@type']: string;
  access_level?: string;
  archive_number?: string;
  asset_identifier?: string;
  aspect_ratio?: string;
  asset_type?: string;
  author?: string;
  barcode?: string;
  caption?: string;
  classification?: string | null;
  comments?: string;
  created_at?: string;
  default_layer?: IAssetLayer;
  default_layer_id?: number;
  deleted_at?: string;
  duration?: number;
  effective_permissions?: string[];
  external_reference?: string;
  file_name?: string;
  file_size?: number;
  file_status?: string;
  format?: string;
  geography_ids?: string[];
  geographies?: ITreeNode[];
  height?: number;
  id: ItemId;
  identifier_crid?: string;
  identifier_eidr?: string;
  identifier_isan?: string;
  identifier_umid?: string;
  identifier_uri?: string;
  identifier_visan?: string;
  ingest_notes?: string;
  ingest_error_message?: string;
  keywords?: string;
  language_ids?: string[];
  main_classification?: IAssetMainClassification;
  marketing_use: boolean;
  md5?: string;
  name?: string;
  number_of_pages?: number;
  organization?: IOrganization;
  owner?: IContact;
  owner_id?: number;
  division_ids?: number[] | null;
  owner_organization?: IOrganization;
  owner_organization_id?: number;
  pdf_version?: string;
  permissions?: string[];
  preview_image: IPreviewImage;
  preview_image_id?: string;
  producer?: string;
  products?: IProduct[];
  products_count?: number;
  protection_levels?: string[];
  product_asset?: IProductAsset;
  published_at: string;
  resolution?: string;
  selected?: boolean;
  access_change_jobs_count?: number;
  sharable?: boolean;
  status?: string;
  storage?: { is_restorable?: boolean };
  subject?: string;
  subtitles?: ISubtitle[];
  tags?: string[];
  title?: string;
  updated_at?: string;
  user_md5?: string;
  width?: number;
  classification_tag?: string;
  burned_in_subtitles_language_id: string;
  stream_url?: string;
  dubbed_language_id?: string;
}

export interface INewenAsset extends IAsset {
  target_audience?: 'INTER' | 'FRANCO' | null;
}

export interface IAssetLayer {
  id?: string;
  copyright_notice?: string;
  notes?: string;
  description?: string;
  caption?: string;
  talent_in_image?: string;
  photographer?: string;
  name: string;

  news_date?: string;
  news_location?: string;
}

export interface ITreeNode {
  id: string;
  name: string;
  parent_id?: string;
}

export interface IQueryParams {
  ids?: ItemId[] | null;
  q?: string;
  product_id?: ItemId;
  per?: number;
  page?: ItemId;
  sort?: ParametronSort;
  order?: ParametronOrder;
  only_roots?: boolean;
  include_deleted?: boolean;
  qfields?: 'autocomplete';
  sorting?: { [key: string]: ParametronOrder };
  stats?: string;
}

export type ISearchFilterValue = boolean | string | number | (string | number)[];
export type ISearchFilter = IFilterOption;
export type IExtendedSearchFilter = ISearchFilter | [string, string];

export interface ISearchParams {
  search: {
    filters: IExtendedSearchFilter[];
  };
}

export interface IFile {
  id: string;
  url?: string;
  name: string;
  size: number;
  type?: string;
  meta?: IFileMeta;
}

export interface ISelectionStandardized extends Omit<ISelection, 'owner'> {
  entityCount: number;
  owner: string;
}

export interface ISelection {
  id: number;
  name: string;
  main_entity_type: string;
  am_statistics_data: {
    count: number;
  };
  pm_statistics_data: {
    count: number;
  };
  users_count: number;
  owner_id: number;
  owner: IContact;
  access_level: string;
  created_at: string;
  deleted_at: string | null;
  type?: string;
}

export interface IGroup {
  '@type'?: string;
  access_level?: 'private' | 'division' | 'company' | 'viewable' | 'public' | string;
  am_statistics_data?: IAMStatistics;
  created_at?: string;
  deleted_at?: string;
  description?: string;
  external_reference?: string | null;
  id: ItemId;
  expires_at?: string;
  name?: string;
  owner?: IContact;
  owner_id?: ItemId;
  owner_organization?: Partial<IOrganization>;
  owner_organization_id?: ItemId;
  pm_statistics_data?: IPMStatistics;
  preview_image?: IPreviewImage;
  preview_image_id?: ItemId;
  scheduled_jobs_count?: number;
  settings?: { protection_levels: string[] };
  updated_at?: string;
  users_count?: number;
  users?: IContact[];
  group_ids?: ItemId[];
  groups?: IGroup[];
  main_entity_type?: string;
  entityType?: string;
  type?: string;
}

export interface IGroupItem {
  access_level: string;
  created_by: string;
  delegates_access: boolean;
  deleted_at: string;
  group_id: number;
  id: number;
  permissions: string[];
  scheduled_jobs_count: number;
  sequence_number: number;
  include_descendants?: boolean;
  include_future_descendants?: boolean;
}

export interface IGroupAssetItem extends IGroupItem {
  asset: IAsset;
  asset_id: string;
}

export type ISelectionMapper = (selectionData: ISelection) => ISelectionStandardized;

export type IDataSectionLayout = 'tiles' | 'list';

export type IBasketMapper = (basketData: IBasket) => IBasketStandardized;

/**
 * Allows to create types where at least one prop from the provided union is required
 * @example
 * interface IExample {
 *  foo: string;
 *  bar: string;
 *  baz: string;
 * }
 *
 * type IRequireAtLeastOne<IExample, 'foo' | 'bar'>;
 *
 * @returns
 * Type where if baz and foo or bar is provided it passes the type check
 */
export type IRequireAtLeastOne<T, Keys extends keyof T = keyof T> = Pick<T, Exclude<keyof T, Keys>> &
  {
    [K in Keys]-?: Required<Pick<T, K>> & Partial<Pick<T, Exclude<Keys, K>>>;
  }[Keys];

export interface IWorkflow {
  ['@type']: string;
  created_at: string;
  full_id: string;
  id: number;
  next_run_at?: string;
  owner?: IContact;
  progress: ItemId;
  status: string;
  restarted_job_id?: ItemId;
  tags: {
    affiliation_id: string;
    am_task_id: number;
    asset_id: number;
    ingest_type: string;
    owner_id: number;
    trace_id: string;
  };
  workflow: string;
}

export interface IAssetOperation {
  id: number;
  asset_id: number;
  created_at: string;
  start_time: string;
  end_time: string;
  updated_at: string;
  label: string;
  op_status: string;
  owner_id?: number | null;
  meta: {
    error_message?: string | null;
    payload?: string | null;
  };
}
export interface IStorage {
  bucket_name: string;
  id: number;
  is_archive: boolean;
  is_restorable?: boolean;
  name: string;
}

export interface ICurrency {
  id: string;
  name: string;
  number: number;
}

export interface IPMStatistics {
  count: number;
}

export interface IAMStatistics {
  count: number;
  mobile_sync_size?: number;
}

export interface IRecommendation extends IGroup {
  expires_at: string;
  message?: string;
  subject: string;
  requires_login?: boolean;
  product_ids: ItemId[];
  asset_ids: ItemId[];
  allows_asset_download: boolean;
  recipient_list: { recipient_type: 'user' | 'email'; recipient_id: ItemId }[];
  recommendation_group_id: ItemId;
}

export interface IRecommendation extends IGroup {
  expires_at: string;
  message?: string;
  subject: string;
  requires_login?: boolean;
  product_ids: ItemId[];
  asset_ids: ItemId[];
  allows_asset_download: boolean;
  recipient_list: { recipient_type: 'user' | 'email'; recipient_id: ItemId }[];
  recommendation_group_id: ItemId;
}

export interface IAccessPrivilege extends IWithRequired<IGroup, 'id'> {
  group: IGroup;
}

export interface IMobileAppSync extends IWithRequired<IGroup, 'id'> {
  group: IGroup;
}

export interface IAccessGroup extends IWithRequired<IGroup, 'id'> {
  group: IGroup;
}

export interface IGuild extends IGroup {
  requested_codes_count: number;
  codes_count?: number;
  export_url?: string;
}

export enum IOptionColor {
  red = 'red',
  orange = 'orange',
  yellow = 'yellow',
  green = 'green',
  white = 'white',
}

export interface IOption {
  value: string;
  label: string;
  color?: IOptionColor;
}

export interface IScheduledJobCreate {
  scheduled_for: string;
  name?: string;
}

export interface IScheduledJob extends IScheduledJobCreate {
  name: string;
  id: ItemId;
  status: string;
  user_id: ItemId;
  access_level?: string;
  permissions?: string[];
}

export type IItem =
  | IProduct
  | IMm3Asset
  | IAsset
  | IGroup
  | IContact
  | IOrganization
  | ISelectionStandardized
  | AmGenericNews
  | PmGenericCastCrew
  | UmGenericListCollection
  | CalGenericLocation;

export interface ISmartGroup extends IGroup {
  asset_filters?: ISearchFilter[] | null;
  product_filters?: ISearchFilter[] | null;
  user_filters?: ISearchFilter[] | null;
  organizations: Pick<IOrganization, 'id'>[];
}

export interface IMarketingEvent extends IGroup {
  event_starts_at?: string;
  event_ends_at?: string;
  purpose: string;

  event_password: string;
  asset_ids: ItemId[];
  product_ids: ItemId[];
  subevent_group_ids?: ItemId[];
  allow_registered_user_access?: boolean;

  is_timed_event: boolean;
  show_event_countdown: boolean;
  use_zip_source: boolean;

  description: string;
  event_not_started_headline: string;
  event_started_not_loggedin_headline: string;
  event_started_loggedin_headline: string;
  event_finished_headline: string;

  event_not_started_introduction: string;
  event_started_not_loggedin_introduction: string;
  event_started_loggedin_introduction: string;
  event_finished_introduction: string;

  registered_emails?: string;
  approved_emails?: string;

  event_not_started_background_image_url?: string;
  event_not_started_featured_image_url?: string;
  event_started_not_loggedin_background_image_url?: string;
  event_started_not_loggedin_featured_image_url?: string;
  event_started_loggedin_background_image_url?: string;
  event_started_loggedin_featured_image_url?: string;
  event_finished_background_image_url?: string;
  event_finished_featured_image_url?: string;
  subevent?: boolean;
  location?: string;
}

export interface IGroupEditForm extends Omit<IGroup, 'id'> {
  assets_item_ids?: ItemId[];
  products_item_ids?: ItemId[];
  contacts_item_ids?: ItemId[];
  products_include_descendants?: boolean;
  products_delegates_access?: boolean;
  group_preview_image_url?: string;
  group_parent_group_id?: ItemId;
  id?: ItemId;
}

export type IGroupEntityIdName = 'eventId' | 'recommendationId' | 'accessPrivilegeId' | 'screeningRoomId';
export interface IDivision {
  id: number;
  name: string;
  owner?: IContact;
  owner_id: number;
  owner_organization?: IOrganization;
  owner_organization_id: number;
  updated_at: string;
  users_count: number;
  parent_id?: number;
  ancestry?: string | null;
  created_at: string;
}

export interface IBasket {
  id: number;
  name: string;
  products_count: number;
  owner: {
    first_name: string;
    last_name: string;
  };
  owner_id: number;
  updated_at: string;
}

export interface IBasketStandardized {
  id: number;
  name: string;
  entityCount: number;
  entityType: ISelectionEntityType;
  ownerId: number;
  owner: string;
  updatedAt: string;
}

export interface IBasketItem {
  basket_item_id: number | string;
}

export enum IDataSectionItemStepSize {
  STEP1 = 576,
  STEP2 = 676,
  STEP3 = 776,
  STEP4 = 876,
  STEP5 = 976,
  STEP6 = 1076,
  STEP7 = 1176,
  STEP8 = 1276,
  STEP9 = 1376,
}

export enum ViewMode {
  PREVIEW = 'preview',
  EDIT = 'edit',
}

export type IUserAgent = {
  os: string;
  os_version: string;
  browser: string;
  browser_version: string;
  device: string;
};

export type IFetchThumbnailImages = Promise<{ image: string; assetType: string }[] | {}[]>;
export type IEntity =
  | IProduct
  | IAsset
  | IMm3Asset
  | IOrganization
  | IMarketingEvent
  | IGroup
  | IContact
  | ICategory
  | IAccessPrivilege
  | IMobileAppSync
  | IConference
  | McGenericRecommendationProduct
  | PmGenericCastCrew
  | IListItem
  | AmGenericNews
  | UmGenericListCollection
  | CalGenericLocation;

export interface ICustomization {
  siteTitle: string;
  header: {
    logo: {
      alt: string;
      text: string;
    };
  };
}

export type IFetchProductsWithAncestry = (
  data: { ids?: ItemId[] | null; q?: string },
  filters?: IExtendedSearchFilter[],
) => Promise<IProduct[]>;

export interface ICustomItemRendererProps extends Omit<IItemRendererProps, 'modifiers'> {
  modifiers: { active: boolean; disabled: boolean; matchesPredicate: boolean; isNavigationEnabled?: boolean };
}

export type ICustomItemRenderer<T> = (
  item: T,
  itemProps: ICustomItemRendererProps,
  options?: {
    isAncestryMode?: boolean;
  },
) => JSX.Element | null;

export interface IDomain {
  protocol: 'http' | 'https';
  name: string;
  id: number;
}

export interface IEntityWithPreview {
  preview_image_id?: ItemId | null;
  preview_image?: IPreviewImage;
  id?: ItemId | null;
}
export enum UserRoles {
  ADMIN = 'Administrator',
  SUPER_ADMIN = 'Super Admin',
  CLIENT = 'Client',
}

export type IFeature =
  | 'isMm3Assets'
  | 'showDeliveryFeature'
  | 'showScreeningRoomFeature'
  | 'showEventFeature'
  | 'showConferenceFeature'
  | 'showRecommendationFeature'
  | 'showAccessPrivilegeFeature'
  | 'showAssetRecommendationsFeature'
  | 'showMeetingsFeature'
  | 'showCollectionsFeature'
  | 'showProducerPortalFeature'
  | 'showMediaBenchFeature'
  | 'showCastCrewFeature'
  | 'showBasketsFeature'
  | 'showMobileSelectionsFeature'
  | 'showAccessGroupFeature'
  | 'showSmartGroupFeature'
  | 'showGuildsFeature'
  | 'showOrderManagementFeature';

export type IFeatureFlag = Record<IFeature, boolean>;

export type ISubFeature =
  | 'showNewsManageShowcaseAction'
  | 'showEventLayout'
  | 'showAutonomousShowcaseButtons'
  | 'showCollectionImagesTab'
  | 'showFutureEpisodesFeature'
  | 'showListRecommendationOnWebsiteFeature'
  | 'showRelatedProductsFeature'
  | 'hasCustomClassifications';

export type ISubFeatureFlag = Record<ISubFeature, boolean>;
export enum AssetStatuses {
  CREATED = 'created',
  REVIEW = 'review',
  AVAILABLE = 'available',
  ARCHIVED = 'archived',
  ARCHIVING = 'archiving',
  UNARCHIVING = 'unarchiving',
  OFFLINE = 'offline',
  DELETED = 'deleted',
}

export enum ACCESS_TABS {
  IMMEDIATE = 'immediate',
  SCHEDULED_ACCESS = 'scheduled_access',
  SCHEDULED_STATUS = 'scheduled_status',
  SCHEDULED_PROTECTION = 'scheduled_protection',
  SCHEDULED_DELEGATION = 'scheduled_delegation',
}

export type ICrunchyrollAsset = AmCrunchyrollAsset3 &
  AmCrunchyrollAsset3Digital &
  AmCrunchyrollAsset3DigitalVideo &
  AmCrunchyrollAsset3DigitalAudio &
  AmCrunchyrollAsset3DigitalImage &
  AmCrunchyrollAsset3DigitalOther &
  AmCrunchyrollAsset3DigitalSubtitle &
  AmCrunchyrollAsset3DigitalDocument;

export type ICrunchyrollProduct = PmCrunchyrollProduct3MotionPictureEpisode &
  PmCrunchyrollProduct3MotionPictureEpisodeVersion &
  PmCrunchyrollProduct3MotionPictureFilmVersion &
  PmCrunchyrollProduct3MotionPictureProgram &
  PmCrunchyrollProduct3MotionPictureSeason &
  PmCrunchyrollProduct3MotionPictureSeries;
