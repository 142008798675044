import React, { useCallback, useMemo } from 'react';
import { map, capitalize, uniq, isEmpty, remove } from 'lodash';
import { observer } from 'mobx-react-lite';

import { useDataSectionStore } from 'store';
import { Routes } from 'utils/routes';
import { getAssets } from 'utils/apis/asset';
import { Row } from 'components/label-value-pair';
import { formatConnectionType } from 'components/connection-list-item/utils';
import AssetsMainClassificationLink from 'components/asset/assets-aggregation/product-assets';
import { DeliveriesGenericDeliveryPackage, DeliveriesGenericConnection } from '@mediafellows/mm3-types';
import { PreviewInfo } from 'components/preview-tab';
import { DetailsPageTabs } from 'types';
import { formatDate, extendedFormatDuration } from 'utils/date';
import { formatFullName } from 'utils/general';
import { useRemote } from 'utils/hooks';
import { DetailCountLink } from 'utils/ui';

interface IDeliveryPreviewAttributesProps {
  connection: DeliveriesGenericConnection;
}

export const DeliveryPreviewAttributes: React.FC<IDeliveryPreviewAttributesProps> = observer(({ connection }) => {
  const { activeItem: delivery } = useDataSectionStore<DeliveriesGenericDeliveryPackage>();
  const {
    id,
    delivered_at,
    scheduled_at,
    organization,
    owner,
    status,
    delivery_items,
    recipient_list,
    percent_transferred,
    delivery_duration_seconds,
    package_size_human_readable,
  } = delivery || {};
  const asset_ids = useMemo(() => uniq(map(delivery_items, 'asset_id')), [delivery_items]);
  const product_ids = uniq(remove(map(delivery_items, 'product_id'), null));
  const detailsPageLink = `${Routes.DELIVERIES}/${id}`;

  const fetchAssets = useCallback(async () => {
    if (isEmpty(asset_ids)) return [];

    return getAssets(asset_ids);
  }, [asset_ids]);

  const [assets = []] = useRemote(fetchAssets);

  return (
    <PreviewInfo>
      <Row label="Status" value={capitalize(status as string)} rawLabel />
      <Row label="Scheduled at" value={formatDate(scheduled_at)} rawLabel />
      <Row label="Sent at" value={formatDate(delivered_at)} rawLabel />
      <Row label="Organization" value={organization?.name} rawLabel />
      <Row label="Sender" value={formatFullName(owner)} rawLabel />
      <Row label="Products" value={product_ids?.length} rawLabel />
      <Row
        label="Assets"
        valueClassName="overflow-inherit"
        value={
          <AssetsMainClassificationLink
            assets={assets}
            baseUrl={`${detailsPageLink}/${DetailsPageTabs.ASSETS}`}
            muted={false}
          />
        }
      />
      <DetailCountLink
        entityCount={recipient_list?.length}
        link={`${detailsPageLink}/${DetailsPageTabs.RECIPIENTS}`}
        label="Recipients"
      />
      <Row label="Connection" value={formatConnectionType(connection?.type)} rawLabel />
      <Row
        className="entity_value-percentage"
        label="Percentage Transferred"
        value={`${percent_transferred || 0}%`}
        rawLabel
      />

      <Row label="Delivery Duration" value={`${extendedFormatDuration(delivery_duration_seconds || 0)}`} />

      <Row label="Total Delivery Size" value={package_size_human_readable} rawLabel />
    </PreviewInfo>
  );
});
