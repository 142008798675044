import React from 'react';

import { FormSectionWrapperV2 } from 'components/form-section-wrapper-v2';
import { FormSelect, FormMultiSelect, FormGeoSelect, FormInput, FormHtmlInput } from 'helpers/form';
import { ImageSpecificFields } from 'components/asset/asset-edit-form/sections';
import { CustomAssetMm3Row, CustomAssetRow } from 'components/asset/asset-edit-form/sections/custom-row';

import { useBasicStore } from 'store/hooks';

import { withAssetVersion } from 'utils/asset';

import { IAssetMm3AboutSectionProps, IAssetOldAboutSectionProps } from './types';
import { ImageMm3SpecificFields } from './image-asset-fields';

export const AssetOldAboutSection: React.FC<IAssetOldAboutSectionProps> = ({
  formData,
  handlers,
  assetType,
  formId,
}) => {
  const { languagesDataOptions } = useBasicStore();

  return (
    <>
      <FormSectionWrapperV2 gridTemplateColumns="1fr 1fr 1fr" title="About">
        <FormInput label="Name" name="default_layer.name" large {...formData['default_layer.name']} {...handlers} />
        <FormSelect name="classification" label="Type" large {...formData.classification} {...handlers} />
        <CustomAssetRow formData={formData} handlers={handlers} />
        <FormMultiSelect
          name="language_ids"
          label="Languages"
          large
          {...formData.language_ids}
          {...handlers}
          options={languagesDataOptions}
          className="asset-edit-modal__form__language--input"
        />
        <FormGeoSelect
          name="geography_ids"
          label="Target Geography"
          placeholder="Add Target Geography"
          large
          {...formData.geography_ids}
          {...handlers}
        />
        <FormInput
          name="default_layer.copyright_notice"
          label="Copyright Notice"
          type="textarea"
          {...formData['default_layer.copyright_notice']}
          {...handlers}
        />
        <FormInput
          name="default_layer.description"
          label="Description"
          type="textarea"
          {...formData['default_layer.description']}
          {...handlers}
          className="asset-edit-modal__form__input--double"
        />
        <ImageSpecificFields assetType={assetType} formData={formData} handlers={handlers} />
      </FormSectionWrapperV2>
      <FormSectionWrapperV2 gridTemplateColumns="1fr" title="Notes">
        <FormHtmlInput
          formId={formId}
          name="default_layer.notes"
          large
          placeholder="Type Notes"
          {...formData['default_layer.notes']}
          {...handlers}
        />
      </FormSectionWrapperV2>
    </>
  );
};

export const AssetMm3AboutSection: React.FC<IAssetMm3AboutSectionProps> = ({
  formData,
  handlers,
  assetType,
  formId,
}) => {
  const { languagesDataOptions } = useBasicStore();

  return (
    <>
      <FormSectionWrapperV2 gridTemplateColumns="1fr 1fr 1fr" title="About">
        <FormInput label="Name" className="asset-edit-form__name-field" name="name" {...formData.name} {...handlers} />
        <FormSelect name="classification" label="Type" large {...formData.classification} {...handlers} />
        <CustomAssetMm3Row formData={formData} handlers={handlers} />
        <FormSelect
          name="meta.language_id"
          label="Language"
          large
          {...formData.meta?.language_id}
          {...handlers}
          options={languagesDataOptions}
        />
        <FormGeoSelect
          name="geography_ids"
          label="Target Geography"
          placeholder="Add Target Geography"
          large
          {...formData.geography_ids}
          {...handlers}
        />
        <FormInput
          name="meta.copyright_notice"
          label="Copyright Notice"
          type="textarea"
          {...formData.meta?.copyright_notice}
          {...handlers}
        />
        <FormInput
          name="meta.description"
          label="Description"
          type="textarea"
          {...formData.meta?.description}
          {...handlers}
        />
        <ImageMm3SpecificFields assetType={assetType} formData={formData} handlers={handlers} />
      </FormSectionWrapperV2>
      <FormSectionWrapperV2 gridTemplateColumns="1fr" title="Notes">
        <FormHtmlInput
          formId={formId}
          name="meta.notes"
          large
          placeholder="Type Notes"
          {...formData.meta?.notes}
          {...handlers}
        />
      </FormSectionWrapperV2>
    </>
  );
};

export default withAssetVersion(AssetOldAboutSection, AssetMm3AboutSection);
