import React, { useMemo } from 'react';
import { observer } from 'mobx-react-lite';

import { FormSectionWrapperV2 } from 'components/form-section-wrapper-v2';
import { FilterCheckbox, FilterDateRange, FilterSelect, FilterTree } from 'helpers/filters/fields';

import { organizationOptionsProvider, useFilters } from 'helpers/filters';

import {
  ContextDataProvider,
  dynamicDataExecutorCache,
  queryDataExecutorCache,
  QueryDataProvider,
  StaticDataProvider,
} from 'helpers/data-provider/option-data-provider';

import { AdditionalFiltersCmp } from 'components/contact-filters/additional-filters';
import { DataSectionStore } from 'store/data-section-store';
import { IContact, IDivision, IUserRole } from 'types';
import { useStore } from 'store';
import { fetchRoles } from 'utils/apis/role';
import { IContactFilters } from 'components/contact-filters';
import { DynamicTreeDataProvider, unflattenTree } from 'helpers/data-provider/tree-data-provider';
import { Model } from 'helpers/filters/types';
import { IResult } from '@mediafellows/chipmunk';
import { fetchResponsibleContacts } from 'utils/apis/contacts';
import { chipmunk } from 'utils/chipmunk';
import { getContactName } from 'utils/ui';

const responsibleContactOptionsProvider = new QueryDataProvider(
  queryDataExecutorCache('um.user.search.resp.contacts', async (query: string) => {
    const objects = await fetchResponsibleContacts(query);
    return { objects, object: objects[0] };
  }),
  (result: IResult<IContact>) => result.objects.map((l: IContact) => ({ value: l.id, label: getContactName(l) })),
);

const statusOptionsProvider = new ContextDataProvider(Model.CONTACTS, 'status');

const divisionTreeProvider = new DynamicTreeDataProvider(
  dynamicDataExecutorCache('divisions', () => chipmunk.run(({ action }) => action(Model.DIVISIONS, 'query'))),
  (data: IResult<IDivision>) =>
    unflattenTree(data.objects.map((d) => ({ label: d.name, value: d.id, ancestry: d.ancestry || '' }))),
);

const rolesOptionsProvider = new QueryDataProvider(
  queryDataExecutorCache('user-roles-provider-cache', async () => {
    const roles = await fetchRoles();
    return { objects: roles, object: roles[0] };
  }),
  (data: IResult<IUserRole>) => {
    return data.objects.map(({ id, name }) => ({ value: id, label: name }));
  },
);

export const SmartGroupContactFilters: React.FC<{
  dataSectionStore: DataSectionStore<IContact>;
}> = observer(({ dataSectionStore }) => {
  const { searchStore } = dataSectionStore;
  const {
    basicStore: { countriesDataOptions },
  } = useStore();

  const countryOptionsProvider = useMemo(() => new StaticDataProvider(countriesDataOptions), [countriesDataOptions]);

  const [filterValues, filterHandlers] = useFilters<IContactFilters>(dataSectionStore, dataSectionStore.triggerSearch);

  return (
    <FormSectionWrapperV2 padded={false} className="smart-group-wizard__filters-tab">
      <AdditionalFiltersCmp filterValues={filterValues} filterHandlers={filterHandlers} />

      <FilterSelect
        label="Platform Role:"
        name="role_id"
        placeholder="Select Platform Role"
        optionsProvider={rolesOptionsProvider}
        filter={filterValues.role_id}
        onChange={filterHandlers.onChange}
      />

      <FilterSelect
        label="Organization:"
        name="organization_id"
        placeholder="Select Organization"
        optionsProvider={organizationOptionsProvider}
        filter={filterValues.organization_id}
        onChange={filterHandlers.onChange}
      />
      <FilterTree
        label="Divisions:"
        name="division_ids"
        optionsProvider={divisionTreeProvider}
        filter={filterValues.division_ids}
        onChange={filterHandlers.onChange}
        aggregations={searchStore.aggregationValues('division_ids')}
      />

      <FilterSelect
        label="Country:"
        name="country_id"
        placeholder="Select Country"
        optionsProvider={countryOptionsProvider}
        filter={filterValues.country_id}
        onChange={filterHandlers.onChange}
      />

      <FilterSelect
        label="Responsible Contact:"
        name="responsible_user_id"
        placeholder="Select Responsible Contact"
        optionsProvider={responsibleContactOptionsProvider}
        filter={filterValues.responsible_user_id}
        onChange={filterHandlers.onChange}
      />

      <FilterDateRange
        label="Date Created:"
        name="created_at"
        filter={filterValues.created_at}
        onChange={filterHandlers.onChange}
      />

      <FilterDateRange
        label="Activated at:"
        name="activated_at"
        filter={filterValues.activated_at}
        onChange={filterHandlers.onChange}
      />

      <FilterDateRange
        label="Last login at:"
        name="last_login_at"
        filter={filterValues.last_login_at}
        onChange={filterHandlers.onChange}
      />

      <FilterCheckbox
        label="Status:"
        name="status"
        optionsProvider={statusOptionsProvider}
        disabled={searchStore.running}
        filter={filterValues.status}
        onChange={filterHandlers.onChange}
      />
    </FormSectionWrapperV2>
  );
});
