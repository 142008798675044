import React, { useCallback } from 'react';
import { addYears } from 'date-fns';

import { FormCheckbox, FormDateRange, FormInput, FormSegment, FormSelect, useDateRangeSplit } from 'helpers/form';
import { FormSectionWrapper } from 'components/form-section-wrapper';
import { IStepGeneralInfo } from './types';
import { slugify } from 'utils/slug';
import { IEventTiming } from 'components/event-create/steps/types';

const maximumDateInFuture = addYears(new Date(), 10);
const timedEventsSegments = [
  { value: 'persistent', label: 'Persistent' },
  { value: 'timed', label: 'Timed' },
];

const StepGeneralInfoFirstColumn: React.FC<IStepGeneralInfo> = (props) => {
  const { form } = props;

  const { onChange } = form.handlers;
  const [eventTimeData, eventTimeHandlers] = useDateRangeSplit('event_time', 'event_starts_at', 'event_ends_at', form);

  const onEventTimingChange = useCallback(
    ({ eventTiming }: { eventTiming: IEventTiming }) => {
      if (eventTiming === 'timed') {
        onChange({ eventTiming });
      } else {
        onChange({ eventTiming, show_event_countdown: false });
        eventTimeHandlers.onChange({
          event_time: [null, null],
        });
      }
    },
    [eventTimeHandlers, onChange],
  );

  const onNameChange = useCallback(
    ({ name }) => {
      form.handlers?.onChange?.({ name, ...(!form.formData?.purpose?.touched ? { purpose: slugify(name) } : {}) });
    },
    [form.formData.purpose.touched, form.handlers],
  );
  const isTimed = form.values.eventTiming === 'timed';
  return (
    <FormSectionWrapper className="create-wizard__inner-section" gridTemplateColumns="1fr">
      <FormInput name="name" label="Name" {...form.formData.name} {...form.handlers} onChange={onNameChange} />
      <FormInput name="purpose" label="Event Identifier" {...form.formData.purpose} {...form.handlers} />
      <FormInput
        name="event_password"
        label="Event Password"
        disabled={form.values.subevent}
        {...form.formData.event_password}
        {...form.handlers}
      />
      <FormSelect name="access_level" label="Access Level" large {...form.formData.access_level} {...form.handlers} />
      <FormSegment
        name="eventTiming"
        label="Event Timing"
        segments={timedEventsSegments}
        {...form.formData.eventTiming}
        {...form.handlers}
        onChange={onEventTimingChange}
      />
      <FormDateRange
        name="event_time"
        label="Event Time"
        withTime
        large
        wide
        maxDate={maximumDateInFuture}
        showShortcuts={false}
        {...eventTimeData}
        {...eventTimeHandlers}
        disabled={!isTimed}
      />
      <FormCheckbox
        name="show_event_countdown"
        label="Display countdown"
        {...form.formData.show_event_countdown}
        {...form.handlers}
        disabled={!isTimed}
      />
      <FormInput name="location" label="Location" {...form.formData?.location} {...form.handlers} />
    </FormSectionWrapper>
  );
};

export { StepGeneralInfoFirstColumn };
