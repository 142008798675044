import React, { useCallback } from 'react';

import { ISmartGroup, ItemId } from 'types';
import { useStore } from 'store';

import { SmartGroupWizard } from '.';
import { IModalSize } from 'components/dialogs/types';
import { useRefreshDataSection } from 'utils/hooks';

interface IUseSmartGroupDialog {
  productIds?: ItemId[];
  userIds?: ItemId[];
  userSelectionIds?: ItemId[];
  organizationIds?: ItemId[];
  group?: ISmartGroup;
  onSuccess?: VoidFunction;
}

export const useSmartGroupDialog = ({
  productIds,
  userIds,
  userSelectionIds,
  organizationIds,
  group,
  onSuccess,
}: IUseSmartGroupDialog = {}): [() => void] => {
  const { dialogStore } = useStore();
  const refreshDataSection = useRefreshDataSection();

  const body = useCallback((): JSX.Element => {
    return (
      <SmartGroupWizard
        onSuccess={() => {
          (onSuccess || refreshDataSection)();
          dialogStore.close();
        }}
        group={group}
        productIds={productIds}
        organizationIds={organizationIds}
        userSelectionIds={userSelectionIds}
        userIds={userIds}
      />
    );
  }, [dialogStore, group, onSuccess, organizationIds, productIds, refreshDataSection, userIds, userSelectionIds]);

  const openDialog = (): void => {
    dialogStore.openModal({
      title: 'Smart Group',
      body,
      fitInView: true,
      size: IModalSize.XL,
    });
  };

  return [openDialog];
};
