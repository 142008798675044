import React, { useCallback, useEffect, useState } from 'react';
import { Checkbox } from '@mantine/core';
import cx from 'classnames';
import { observer } from 'mobx-react-lite';

import { useStore } from 'store';
import { DeliveriesGenericConnection, DeliveriesGenericDeliveryPackage } from '@mediafellows/mm3-types';
import { handleItemClick } from 'components/data-section';
import { isEntityDeleted } from 'utils/is-entity-deleted';
import { useRemote } from 'utils/hooks';
import { IAsset } from 'types';

import { DeliveryAttributeList } from 'components/delivery-list-item/delivery-attributes-list';
import { MemoizedMultiThumbnail } from 'components/multi-component/multi-component';
import { Thumbnail } from 'components/thumbnail';

import './style.scss';
import { fetchConnectionById } from 'utils/apis/delivery-connections';
import { loadDeliveryThumbnails } from 'utils/apis/package-delivery';

interface IDeliveryListItemProps {
  className?: string;
  delivery: DeliveriesGenericDeliveryPackage & { assets: Array<IAsset> };
  contextMenu: React.ReactElement;
}

const DeliveryListItem: React.FC<IDeliveryListItemProps> = observer((props) => {
  const {
    dataSectionStore: { checked, active, isAllItemsSelected },
  } = useStore();

  const { className, delivery, contextMenu } = props;
  const { id } = delivery;
  const isChecked = checked.some((checked) => checked.id === delivery.id);
  const isActive = active === delivery.id;

  const getConnectionById = useCallback(async () => {
    const id = delivery?.connection_id;
    return fetchConnectionById(id);
  }, [delivery?.connection_id]);

  const fetchThumbnails = useCallback(() => {
    return loadDeliveryThumbnails(delivery);
  }, [delivery]);

  const [connection] = useRemote(getConnectionById);
  const [thumbnails = []] = useRemote(fetchThumbnails);

  const handleClick = useCallback(
    (e): void => {
      handleItemClick(e, id as number);
    },
    [id],
  );
  const [itemChecked, setItemChecked] = useState(isChecked);

  useEffect(() => {
    setItemChecked(isChecked);
  }, [isChecked]);

  return (
    <div
      className={cx('entity-list-item-row', className, {
        'entity-list-item--active': isActive,
        'entity-list-item--deleted': isEntityDeleted(delivery),
      })}
      onClick={handleClick}
    >
      <div className="delivery-list-item__checkbox">
        <Checkbox onChange={handleClick} checked={itemChecked || isAllItemsSelected} disabled={isAllItemsSelected} />
      </div>

      <div className="entity-list-item-row__multi-thumb">
        {thumbnails?.length ? <MemoizedMultiThumbnail thumbnailImages={thumbnails} /> : <Thumbnail showType image="" />}
      </div>

      <DeliveryAttributeList
        delivery={delivery}
        connection={connection as DeliveriesGenericConnection}
        contextMenu={contextMenu}
      />
    </div>
  );
});

export default DeliveryListItem;
