import React from 'react';
import { observer } from 'mobx-react';
import cx from 'classnames';

import { ISocialMediaProfile, IPhone, IUrl, IAddress, IGeoScope } from 'types';
import { useBasicStore } from 'store';

import { InfoBoxV2 } from 'components/info-box-v2';
import { LabelValuePair } from 'components/label-value-pair';
import './style.scss';
import { extractHostFromUrl } from 'utils/extract-host-from-url';
import { formatAddress, sortPhones } from 'utils/general';
import { useCountryNames } from 'utils/hooks';

interface IMultipleValuesEntityProps<T> {
  values: T[];
  sortBy?: (val1: T, val2: T) => number;
  hideIfNoValue?: boolean;
  RowComponent: React.FC<T>;
}

function EntityList<T>({
  values,
  sortBy = () => 0,
  hideIfNoValue,
  RowComponent,
}: IMultipleValuesEntityProps<T>): React.ReactElement {
  return (
    <>
      {(hideIfNoValue && !values) || values.sort(sortBy).map((value, index) => <RowComponent key={index} {...value} />)}
    </>
  );
}

export const LabelLinkPair: React.FC<{ label: string; link: string; hideIfNoValue?: boolean }> = ({
  label,
  link,
  hideIfNoValue = false,
}) => {
  if (hideIfNoValue) {
    return <></>;
  }

  return (
    <>
      <div className="muted-text">{label}</div>
      <a href={link} target="_blank" rel="noreferrer">
        {link}
      </a>
    </>
  );
};

export const GeoScopesRow: React.FC<{ geo_scopes?: IGeoScope[] }> = ({ geo_scopes }) => {
  const territoriesOfResponsibility = geo_scopes?.map(({ name }) => name).join(', ');

  return <LabelValuePair label="Territories of Responsibility" value={territoriesOfResponsibility} />;
};

export const MultipleAddressesList: React.FC<{ addresses?: IAddress[] }> = ({ addresses }) => {
  const { countries } = useBasicStore();

  return (
    <>
      {addresses ? (
        addresses.map((address, index, addresses) => (
          <LabelValuePair
            key={address.id}
            label={`Address ${addresses.length > 1 ? index + 1 : ''}`}
            value={formatAddress(address, countries)}
          />
        ))
      ) : (
        <LabelValuePair label="Address" value="—" />
      )}
    </>
  );
};

export const PhoneRow: React.FC<IPhone> = ({ label, number }) => {
  return (
    <>
      <div className="muted-text pe-4">{label}</div>
      <a href={`tel:${number}`}>{number || '—'}</a>
    </>
  );
};
export const PhonesList: React.FC<{ phones?: IPhone[]; gridTemplateColumns?: string }> = ({
  phones,
  gridTemplateColumns = '200px 1fr',
}) => {
  return (
    <InfoBoxV2 padded={false} gridTemplateColumns={gridTemplateColumns} wrapperClassName="phone-list__wrapper">
      <div className={cx(`contact-general-tab__subtitle mb-2 muted-text`)}>Phones</div>
      <div></div>
      {phones?.length ? <EntityList values={phones} sortBy={sortPhones} RowComponent={PhoneRow} /> : <div>—</div>}
    </InfoBoxV2>
  );
};

export const AddressRow: React.FC<{ label: string; value: string }> = ({ label, value }) => {
  return <LabelValuePair label={label} value={value} />;
};
export const AddressList: React.FC<{ address?: IAddress }> = observer(({ address }) => {
  const { street, supplement, zip_code, city, country_id } = address || {};
  const countryNames = useCountryNames([country_id]);

  return (
    <>
      <div className={cx(`contact-general-tab__subtitle mb-2 muted-text`)}>Address</div>
      <InfoBoxV2 padded={false} gridTemplateColumns="200px 1fr">
        {address ? (
          <EntityList
            values={[
              { label: 'Street', value: street },
              { label: 'Supplement', value: supplement },
              { label: 'ZIP Code', value: zip_code },
              { label: 'City', value: city },
              { label: 'Country', value: countryNames[0] },
            ]}
            RowComponent={AddressRow}
          />
        ) : (
          <div>—</div>
        )}
      </InfoBoxV2>
    </>
  );
});

export const SocialMediaRow: React.FC<Omit<ISocialMediaProfile, 'sequence_number'>> = ({ label, link }) => {
  return <LabelLinkPair label={label} link={link} />;
};

export const SocialMediaProfilesList: React.FC<{
  social_media_profiles: ISocialMediaProfile[];
  showTitle?: boolean;
  gridTemplateColumns?: string;
}> = ({ social_media_profiles, showTitle = true, gridTemplateColumns = '200px 1fr' }) => {
  return (
    <>
      {showTitle && <div className={cx(`contact-general-tab__subtitle mb-2 muted-text`)}>Social media profiles</div>}
      <InfoBoxV2 padded={false} gridTemplateColumns={gridTemplateColumns}>
        {social_media_profiles ? (
          <EntityList values={social_media_profiles} RowComponent={SocialMediaRow} />
        ) : (
          <div>—</div>
        )}
      </InfoBoxV2>
    </>
  );
};

export const WebsitesRow: React.FC<IUrl> = ({ url }) => {
  return <LabelLinkPair label="Website" link={url} />;
};

export const WebsitesList: React.FC<{ websites: IUrl[] }> = ({ websites }) => {
  return <EntityList values={websites} RowComponent={WebsitesRow} />;
};

interface ITagsListProps {
  label?: string;
  tags?: string[] | null;
  className?: string;
}
export const TagsList: React.FC<ITagsListProps> = ({ tags, label = 'Tags', className = 'me-1' }) => {
  return (
    <>
      <div className="muted-text">{label}</div>
      {tags?.length ? (
        <div className="tags-list__wrapper">
          {tags.map((tag, index) => (
            <span className={cx('tags-list__tag text-break', className)} key={index}>
              {tag}
            </span>
          ))}
        </div>
      ) : (
        <div className="screen-m">—</div>
      )}
    </>
  );
};
export default EntityList;

export const getWebsiteLinks = (urls?: IUrl[]): JSX.Element[] | null => {
  if (!urls || !urls?.length) return null;
  return urls?.map(({ url }) => (
    <a
      className="me-1"
      key={url}
      href={`${url.includes('http') ? '' : 'https://'}${url}`}
      target="_blank"
      rel="noreferrer"
    >
      {extractHostFromUrl(url)}
    </a>
  ));
};
