import React, { useCallback, useState } from 'react';
import { ActionIcon, Tooltip } from '@mantine/core';
import { MantineIcon } from 'utils/ui/icon';
import { Star, StarEmpty } from 'blueprint5-icons';

import { IAsset, IMm3Asset, IProductAsset } from 'types';
import { IParametronData } from '@mediafellows/parametron';
import { setMarketingAsset } from 'utils/apis/product-asset';
import { useDataSectionStore } from 'store/hooks';
import { Pages } from 'utils/actions/types';
import { saveMm3Asset } from 'utils/apis/asset';
import { assetListSchema } from 'utils/schemas';
import { AmGenericAsset3DigitalSubtitle } from '@mediafellows/mm3-types';

interface IMarketingStartProps {
  asset: Partial<(IAsset | (IMm3Asset & AmGenericAsset3DigitalSubtitle)) & { product_asset?: IProductAsset }>;
  page?: Pages;
  disableMarketingStar?: boolean;
  setProductAssets?: React.Dispatch<React.SetStateAction<IProductAsset[]>>;
}

export const MarketingAssetStar: React.FC<IMarketingStartProps> = ({
  asset,
  page,
  disableMarketingStar,
  setProductAssets,
}) => {
  const { searchStore } = useDataSectionStore<IAsset>();
  const [isAvailableOnPlayer, setIsAvailableOnPlayer] = useState(
    Boolean((asset as IMm3Asset & AmGenericAsset3DigitalSubtitle)?.meta?.show_in_player),
  );

  const setMarketingUse = useCallback(async () => {
    if (!asset?.product_asset?.id || !searchStore?.update) {
      return;
    }

    const product_asset = await setMarketingAsset(asset.product_asset.id, !asset.product_asset.marketing_use);

    searchStore.update({
      objects: searchStore.objects.map((item) => {
        if (item.id === asset.id) {
          return { ...item, product_asset };
        }
        return item;
      }),
    } as IParametronData);
    if (page === Pages.PRODUCT_ASSETS_TAB) {
      setProductAssets?.((productAssets) => {
        return [...productAssets.filter((productAsset) => product_asset.id !== productAsset.id), product_asset];
      });
    }
  }, [asset, searchStore, page, setProductAssets]);

  const setShowInPlayer = useCallback(async () => {
    const mm3Asset = asset as IMm3Asset & AmGenericAsset3DigitalSubtitle;
    if (!mm3Asset?.id || !mm3Asset?.parent_id || !mm3Asset?.meta || !searchStore?.update) {
      return;
    }

    const { object: result } = await saveMm3Asset(
      mm3Asset.id,
      {
        id: mm3Asset.id,
        type: mm3Asset.type,
        meta: { ...mm3Asset.meta, show_in_player: !mm3Asset.meta?.show_in_player },
      },
      assetListSchema,
    );

    setIsAvailableOnPlayer(Boolean(result.meta?.show_in_player));
    searchStore.update({
      objects: searchStore.objects.map((item) => {
        return item.id !== result.id ? item : result;
      }),
    });
  }, [asset, searchStore]);

  if (page === Pages.ASSET_SUB_ASSETS_TAB) {
    return (
      <Tooltip label="Make available in video player" position="bottom">
        <ActionIcon variant="subtle" color="gray.5" onClick={setShowInPlayer}>
          <MantineIcon icon={isAvailableOnPlayer ? <Star /> : <StarEmpty />} />
        </ActionIcon>
      </Tooltip>
    );
  }
  if (!asset?.product_asset?.id && page === Pages.PRODUCT_ASSETS_TAB) {
    return (
      <div className="marketing-star__descendants-icon">
        <MantineIcon variant="subtle" icon="inheritance" />
      </div>
    );
  }

  if (!asset?.product_asset?.id) {
    return null;
  }

  if (disableMarketingStar) {
    return (
      <div className="marketing-star__descendants-icon">
        <MantineIcon icon="star" />
      </div>
    );
  }

  return (
    <ActionIcon variant="subtle" color="gray.5" onClick={setMarketingUse}>
      <MantineIcon
        icon={
          asset.product_asset.marketing_use || (asset as AmGenericAsset3DigitalSubtitle).meta?.show_in_player ? (
            <Star />
          ) : (
            <StarEmpty />
          )
        }
      />
    </ActionIcon>
  );
};
