import React, { useState, useEffect } from 'react';
import { Checkbox } from '@mantine/core';

import cx from 'classnames';
import { Link } from 'react-router-dom';

import { Routes } from 'utils/routes';
import { ScheduledChangeIndicator } from 'components/common/scheduled-change-info';
import { Thumbnail } from 'components/thumbnail';
import { ProductAncestryBreadcrumbs as AncestryBreadcrumbs } from 'components/breadcrumb-product-ancestry';
import useSelectedOption from 'utils/hooks/selected-option';
import { IProductLayer } from 'types';
import { formatLayerDuration } from 'utils/date';
import { formatField } from 'utils/general';
import { isEntityDeleted } from 'utils/is-entity-deleted';
import { formatMm3TypeTag } from 'utils/product';
import { placeholder } from 'utils/ui';
import { MfxTooltip } from 'utils/ui/mfx-tooltip';

import { IProductDataSectionWrappedComponent } from './types';

import './style.scss';

export const ProductDataSectionTilesItem: React.FC<IProductDataSectionWrappedComponent> = ({
  contextMenu,
  handleClick,
  isAllItemsSelected,
  isChecked,
  product,
  id,
  active,
}) => {
  const { default_layer, inherited_preview_image, display_title, access_level, type } = product;
  const layer = (default_layer || {}) as IProductLayer;
  const { year_of_production, year_of_production_text } = layer?.meta || {};
  const [itemChecked, setItemChecked] = useState(isChecked);
  const selectedOption = useSelectedOption({ value: access_level });
  const tagType = formatMm3TypeTag(type);
  const duration = formatLayerDuration(default_layer);

  useEffect(() => {
    setItemChecked(isChecked);
  }, [isChecked]);

  return (
    <div
      className={cx('product-list-item', {
        'product-list-item-tile--active': active === product.id,
        'entity-list-item--deleted': isEntityDeleted(product),
      })}
      onClick={handleClick}
    >
      <div className="product-list-item-tile">
        <div className="product-list-item-tile__actions d-flex justify-content-between align-items-center mb-2">
          <div
            className={cx('product-list-item-tile__checkbox', {
              [`access-level-${selectedOption?.color}--tile`]: Boolean(selectedOption),
            })}
          >
            <Checkbox
              onChange={handleClick}
              checked={itemChecked || isAllItemsSelected}
              disabled={isAllItemsSelected}
            />
          </div>
          <div className="product-list-item-tile__type muted-text">{tagType}</div>
          <div className="d-flex align-items-center">
            <div style={{ marginBottom: '3px' }}>
              <ScheduledChangeIndicator entity={product} />
            </div>
            <div className="ms-1">{Boolean(contextMenu) && contextMenu}</div>
          </div>
        </div>

        <div className="product-list-item-tile__thumb mb-2">
          <Thumbnail image={inherited_preview_image?.url} assetType={product?.type} />
        </div>
        <div className="product-list-item-tile__content">
          <AncestryBreadcrumbs ancestryInfo={product?.ancestry_info} layout="tiles" productId={id} />
          <div className="entity-list-item-row__title product-list-item-tile__title d-flex">
            <Link className="text-truncate" to={`${Routes.PRODUCTS}/${product?.id}`}>
              <h4 className="text-truncate">{display_title}</h4>
            </Link>
          </div>
          <div className="product-list-item-tile__attributes">
            {(year_of_production_text || year_of_production) && (
              <MfxTooltip
                label={formatField(year_of_production_text || year_of_production)}
                position="top"
                className={cx('production muted-text text-truncate', !duration ? 'full-width' : ' ')}
              />
            )}

            {duration && (
              <MfxTooltip
                label={placeholder(formatLayerDuration(layer))}
                position="top"
                className={cx('duration', !(year_of_production_text || year_of_production) ? 'full-width' : ' ')}
              />
            )}
          </div>
        </div>
      </div>
    </div>
  );
};
