import React, { useCallback, useMemo } from 'react';
import { observer } from 'mobx-react-lite';
import { endOfDay, startOfDay, subDays } from 'date-fns';

import { Routes } from 'utils/routes';
import { AssetVideoPlayer } from 'components/common';
import { Thumbnail } from 'components/thumbnail';
import { AssetFilters, readyStatuses } from 'utils/asset';
import { PreviewBarChart } from 'components/preview-bar-chart';
import { SectionHeader } from 'components/section-header';
import { ThumbnailFileStatus } from 'components/file-status';
import { PreviewTab } from 'components/preview-tab';
import { AssetAudioPlayer } from 'components/audio-player/audio-player';

import { useDataSectionStore } from 'store';

import { IAsset, IMm3Asset, IHistogramData, DetailsPageTabs } from 'types';

import { changeAssetAccessImmediate } from 'utils/apis/asset';
import { getEntityType } from 'utils/general';
import { useRemote } from 'utils/hooks';
import { LinkWithArrow } from 'utils/ui';
import { assetListSchema } from 'utils/schemas/asset';
import { getHistogramData } from 'utils/apis/analytics';
import { changeAccessLevelSlider } from 'utils/change-access';
import { getIngestStatus, getPreviewImageUrl } from 'utils/asset';
import { AssetPreviewAttributes } from 'components/asset/asset-preview-tab/asset-preview-attributes';
import { ParentBreadcrumbs } from 'components/parent-breadcrumb';

import './style.scss';

const AssetPreviewTab: React.FC = observer(() => {
  const { active, activeItem: asset } = useDataSectionStore<IAsset | IMm3Asset>();
  const end = React.useMemo(() => endOfDay(new Date()), []);
  const start = React.useMemo(() => subDays(startOfDay(end), 13), [end]);

  const { id, access_level, name, classification, deleted_at } = asset || {};

  const detailsPageLink = `${Routes.ASSETS}/${id}`;

  const assetItemType = getEntityType(classification);
  const isVideo = assetItemType === AssetFilters.VIDEO;

  const fetchAssetAnalytics = useCallback(async (): Promise<IHistogramData[]> => {
    const { data } = await getHistogramData({
      entity: 'asset',
      queryName: isVideo ? 'video::histogram' : 'asset::histogram',
      entityId: active,
      dateRange: [start, end],
    });
    return data;
  }, [active, end, isVideo, start]);

  const [histogram] = useRemote(fetchAssetAnalytics);

  const handleAccessLevelChange = useCallback(
    (newAccessLevel: string) => {
      return changeAccessLevelSlider({
        apiCall: (data) => changeAssetAccessImmediate(data, assetListSchema),
        divisionIds: asset?.division_ids,
        newAccessLevel,
      });
    },
    [asset?.division_ids],
  );

  const CustomRightRenderer = useCallback(
    (): JSX.Element => <LinkWithArrow to={`${detailsPageLink}/${DetailsPageTabs.ANALYTICS}`} text="View All" />,
    [detailsPageLink],
  );

  const customThumbnail = useMemo(() => {
    const assetItemType = getEntityType(asset?.classification);
    const isVideo = assetItemType === AssetFilters.VIDEO;
    const isAudio = assetItemType === AssetFilters.AUDIO;
    const isFileReady = readyStatuses.has(asset?.file_status || '');
    if (isVideo && isFileReady) {
      return <AssetVideoPlayer video={asset} />;
    }
    if (isAudio) {
      return <AssetAudioPlayer asset={asset} key={asset?.id} />;
    }
    return (
      <Thumbnail
        image={getPreviewImageUrl(asset?.preview_image)}
        showType
        assetType={assetItemType}
        placeholder={<ThumbnailFileStatus status={getIngestStatus(asset)} size={35} withBackground />}
      />
    );
  }, [asset]);

  const { parent } = (asset as IMm3Asset) || {};
  return (
    <PreviewTab
      title={name}
      breadcumbs={
        <ParentBreadcrumbs
          title={parent?.name}
          id={parent?.id}
          to={`${Routes.ASSETS}/${parent?.id}`}
          layout="preview"
        />
      }
      showBreadcrumbs={parent}
      accessLevel={access_level}
      detailsLink={deleted_at ? '' : `${detailsPageLink}/${DetailsPageTabs.OVERVIEW}`}
      handleAccessLevelChange={handleAccessLevelChange}
      customThumbnail={customThumbnail}
    >
      <AssetPreviewAttributes />
      <div className="asset-preview__analytics">
        <SectionHeader
          useBackground
          title={isVideo ? 'Screening Activity' : 'Download Activity'}
          {...(!deleted_at && { CustomRightRenderer })}
        />
        <div className="asset-preview__analytics__graph">
          <PreviewBarChart
            data={histogram}
            title={isVideo ? 'Screening Activity' : 'Download Activity'}
            startDate={start}
            endDate={end}
            unit={isVideo ? 'screening' : 'download'}
          />
        </div>
      </div>
    </PreviewTab>
  );
});
export default AssetPreviewTab;
