import { chipmunk, IResult } from 'utils/chipmunk';

import {
  addProductToGroup,
  addDifferentContactEntitiesToGroup,
  addOrganizationsToGroup,
  addAssetToGroup,
  queryProductGroupItems,
  removeProductFromGroup,
  queryAssetGroupItems,
  removeAssetFromGroup,
  removeContactsFromGroup,
  removeOrganizationsFromGroup,
  updateGroup,
} from 'utils/apis/groups';

import { GroupTypes, ISmartGroup } from 'types';
import { Model } from 'helpers/filters/types';
import { ISmartGroupFormFields } from 'types/smart-group';
import { queryAllProducts } from './product';
import { getValidIds } from 'utils/general';
import { queryAllAssets } from './asset';
import { queryContacts } from './contacts';
import { queryOrganizations } from './organization';

export const createSmartGroup = async (values: ISmartGroupFormFields): Promise<IResult<ISmartGroup>> => {
  return chipmunk.run(async ({ action }) => {
    const {
      asset_filters,
      asset_ids,
      asset_selection_method,
      contacts,
      expires_at,
      include_descendants,
      include_future_descendants,
      name,
      organization_ids,
      product_filters,
      product_ids,
      product_selection_method,
      permissions,
      user_filters,
    } = values;
    const result = await action(Model.SMART_GROUP, 'create', {
      body: {
        asset_filters,
        expires_at,
        name,
        product_filters,
        type: GroupTypes.SMART_GROUPS,
        user_filters,
      },
    });

    const { object: group } = result;
    const group_id = group.id;

    const productsPromise =
      product_selection_method === 'manual' &&
      addProductToGroup({
        group_id,
        item_ids: product_ids,
        include_descendants,
        include_future_descendants,
      });

    const assetsPromise =
      asset_selection_method === 'manual' &&
      addAssetToGroup({
        group_id,
        item_ids: asset_ids,
        include_descendants,
        include_future_descendants,
        permissions,
      });

    const contactsPromise = addDifferentContactEntitiesToGroup({ group_id, contacts });

    const organizationsPromise =
      organization_ids?.length && addOrganizationsToGroup({ group_id, item_ids: organization_ids });

    await Promise.all([productsPromise, assetsPromise, contactsPromise, organizationsPromise]);

    return group;
  });
};

export const updateSmartGroup = async (values: ISmartGroupFormFields): Promise<ISmartGroup | undefined> => {
  const {
    access_level,
    asset_filters,
    asset_ids,
    asset_selection_method,
    contacts,
    expires_at,
    id,
    include_descendants,
    include_future_descendants,
    name,
    organization_ids,
    product_filters,
    user_filters,
    product_ids,
    product_selection_method,
    settings,
    permissions,
  } = values;
  const group = await updateGroup<ISmartGroup>({
    access_level,
    asset_filters,
    expires_at,
    id,
    name,
    product_filters,
    settings,
    type: GroupTypes.SMART_GROUPS,
    user_filters,
  });

  const group_id = group?.id;
  if (!group_id) {
    return;
  }

  let productsPromise;
  if (product_selection_method === 'manual') {
    queryAllProducts(
      {},
      [
        ['group_ids', 'in', [group_id]],
        ['id', 'not_in', product_ids || []],
      ],
      'id',
    ).then(async (productsToRemove) => {
      if (product_ids?.length) {
        productsPromise = addProductToGroup({
          group_id,
          item_ids: product_ids,
          include_descendants,
          include_future_descendants,
        });
      }
      const productIdsToRemove = getValidIds(productsToRemove);
      const { objects: groupItems } = await queryProductGroupItems(group.id, productIdsToRemove);
      if (groupItems?.length) {
        removeProductFromGroup({ group_ids: [group_id], item_ids: getValidIds(groupItems) });
      }
    });
  }

  let assetsPromise;
  if (asset_selection_method === 'manual') {
    queryAllAssets(
      {},
      [
        ['group_ids', 'in', [group_id]],
        ['id', 'not_in', asset_ids],
      ],
      'id',
    ).then(async (assetsToBeRemoved) => {
      if (asset_ids?.length) {
        assetsPromise = addAssetToGroup({
          group_id,
          item_ids: asset_ids,
          include_descendants,
          permissions,
          include_future_descendants,
        });
      }
      const assetIdsToBeRemoved = getValidIds(assetsToBeRemoved);
      const { objects: groupItems } = await queryAssetGroupItems(group.id, assetIdsToBeRemoved);
      if (groupItems?.length) {
        removeAssetFromGroup({ group_ids: [group_id], item_ids: getValidIds(groupItems) });
      }
    });
  }

  let contactsPromise;

  queryContacts(
    {},
    [
      ['group_ids', 'in', [group_id]],
      ['id', 'not_in', getValidIds(contacts)],
    ],
    'id',
    'unfurl',
  ).then(async (contactsToRemove) => {
    contactsPromise = addDifferentContactEntitiesToGroup({ group_id, contacts });
    if (contactsToRemove?.length) {
      removeContactsFromGroup(group_id, getValidIds(contactsToRemove));
    }
  });

  let organizationsPromise;
  queryOrganizations({}, [
    ['group_ids', 'in', [group_id]],
    ['id', 'not_in', organization_ids || []],
  ]).then(async (organizationToBeRemoved) => {
    if (organization_ids?.length) {
      organizationsPromise = addOrganizationsToGroup({ group_id, item_ids: organization_ids });
    }
    if (organizationToBeRemoved?.length) {
      removeOrganizationsFromGroup(group_id, getValidIds(organizationToBeRemoved));
    }
  });

  await Promise.all([productsPromise, assetsPromise, contactsPromise, organizationsPromise]);

  return group;
};
