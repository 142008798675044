import React, { useCallback, useEffect, useMemo } from 'react';
import { Model } from 'helpers/filters/types';
import { useForm } from 'helpers/form';
import { ItemId, IProduct, IGroupAddContact, IAsset, ISmartGroup, IContact } from 'types';
import { useStore } from 'store';
import { initialValues } from 'components/smart-group/form-hook/initial-values';
import { observer } from 'mobx-react-lite';
import { useControlDataSection } from 'utils/hooks';
import { DataSectionStore } from 'store/data-section-store';

import { SmartGroupWizard } from './smart-group-wizard';
import { ISmartGroupFormFields } from 'types/smart-group';
import { noParentFilter } from 'components/product-filters/utils';
import { IAssetFilters } from 'components/asset/asset-filters';
import { defaultAssetFilters, defaultProductFilters } from 'components/smart-group/form-hook/default-filters';
import { getAssetModel } from 'utils/asset';
import { ToastError } from 'components/toast';
import { createSmartGroup, updateSmartGroup } from 'utils/apis/smart-group';
import { convertPresetsToFilters } from 'helpers/filters/helpers';
import { defaultContactFilters, IContactFilters } from 'components/contact-filters';

const params = { per: 1 };

interface ISmartGroupContainerWizardContainerProps {
  productIds?: ItemId[];
  organizationIds?: ItemId[];
  userIds?: ItemId[];
  userSelectionIds?: ItemId[];
  contacts?: IGroupAddContact[];
  onSuccess?: () => void;
  group?: ISmartGroup;
  fitInParent?: boolean;
}

const makePartialOptions = (ids, type: 'contact-selection' | 'contact'): IGroupAddContact[] =>
  ids.map((id) => ({ id, type }));

const customContext = {
  properties: {
    permissions: {
      validations: [{ value_inclusion: { in: ['download'], allow_blank: true } }],
    },
  },
};
const productDefaultInitFilters = noParentFilter(true);

export const SmartGroupWizardContainer: React.FC<ISmartGroupContainerWizardContainerProps> = observer(
  ({
    onSuccess,
    productIds = [],
    organizationIds = [],
    userIds = [],
    contacts = [],
    userSelectionIds = [],
    group,
    fitInParent = false,
  }) => {
    const { toastStore } = useStore();

    const { productDataSectionStore, assetDataSectionStore, contactDataSectionStore } = useMemo(() => {
      return {
        productDataSectionStore: new DataSectionStore<IProduct>(),
        assetDataSectionStore: new DataSectionStore<IAsset>(),
        contactDataSectionStore: new DataSectionStore<IContact>(),
      };
    }, []);

    const handleSubmit = useCallback(
      async ({
        product_selection_method,
        asset_selection_method,
        contact_selection_method,
        product_filters,
        product_ids,
        asset_ids,
        asset_filters,
        contact_ids,
        user_filters,
        ...values
      }) => {
        try {
          toastStore.info('Saving...');
          const submit = values.id ? updateSmartGroup : createSmartGroup;

          await submit({
            ...values,
            product_ids: product_selection_method === 'static' ? product_ids : undefined,
            asset_ids: asset_selection_method === 'static' ? asset_ids : undefined,
            contact_ids: contact_selection_method === 'static' ? contact_ids : undefined,
            product_filters: product_selection_method === 'dynamic' ? productDataSectionStore.searchStore?.filters : [],
            asset_filters: asset_selection_method === 'dynamic' ? assetDataSectionStore.searchStore?.filters : [],
            user_filters: contact_selection_method === 'dynamic' ? contactDataSectionStore.searchStore?.filters : [],
          });
          toastStore.clearAll();
          toastStore.success(`Smart Group was ${values.id ? 'updated' : 'created'} successfully`);
          onSuccess?.();
        } catch (error) {
          toastStore.error(<ToastError error={error} />);
        }
      },
      [assetDataSectionStore, contactDataSectionStore, onSuccess, productDataSectionStore, toastStore],
    );

    const form = useForm<ISmartGroupFormFields>(
      {
        ...initialValues,
        product_ids: productIds,
        organization_ids: organizationIds,
        contact_ids: userIds,
        contacts: [
          ...contacts,
          ...makePartialOptions(userIds, 'contact'),
          ...makePartialOptions(userSelectionIds, 'contact-selection'),
        ],
        ...(group?.id
          ? {
              ...group,
              product_selection_method: group?.product_filters ? 'dynamic' : 'static',
              asset_selection_method: group?.asset_filters ? 'dynamic' : 'static',
              contact_selection_method: group?.user_filters ? 'dynamic' : 'static',
            }
          : {}),
      },
      Model.SMART_GROUP,
      handleSubmit,
      customContext,
    );

    const productInitFilters = useMemo(() => {
      if (!group?.product_filters?.length) {
        return productDefaultInitFilters;
      }
      return convertPresetsToFilters(defaultProductFilters, group.product_filters);
    }, [group?.product_filters]);

    useControlDataSection({
      model: Model.PRODUCTS,
      customDataSectionStore: productDataSectionStore,
      defaultFilters: defaultProductFilters,
      initFilters: productInitFilters,
      schema: 'id',
      stats: '',
      params,
      defaultParams: params,
    });

    const assetInitFilters = useMemo(() => {
      if (!group?.asset_filters?.length) {
        return;
      }
      return convertPresetsToFilters(defaultAssetFilters, group.asset_filters);
    }, [group?.asset_filters]);

    useControlDataSection<IAsset, IAssetFilters>({
      model: getAssetModel(),
      customDataSectionStore: assetDataSectionStore,
      defaultFilters: defaultAssetFilters,
      initFilters: assetInitFilters,
      schema: 'id',
      stats: '',
      defaultParams: params,
      params,
    });

    const contactInitFilters = useMemo(() => {
      if (!group?.user_filters) {
        return;
      }
      return convertPresetsToFilters(defaultContactFilters, group.user_filters);
    }, [group?.user_filters]);

    useControlDataSection<IContact, IContactFilters>({
      model: Model.CONTACTS,
      customDataSectionStore: contactDataSectionStore,
      defaultFilters: defaultContactFilters,
      initFilters: contactInitFilters,
      schema: 'id',
      stats: '',
      defaultParams: params,
      params,
    });

    useEffect(() => {
      (async () => {
        contactDataSectionStore.triggerSearch();
        assetDataSectionStore.triggerSearch();
        productDataSectionStore.triggerSearch();
      })();
    }, [
      assetDataSectionStore,
      assetDataSectionStore.target,
      contactDataSectionStore,
      contactDataSectionStore.target,
      productDataSectionStore,
      productDataSectionStore.target,
    ]);

    return (
      <SmartGroupWizard
        contactDataSectionStore={contactDataSectionStore}
        productDataSectionStore={productDataSectionStore}
        assetDataSectionStore={assetDataSectionStore}
        form={form}
        fitInParent={fitInParent}
      />
    );
  },
);
