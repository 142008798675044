import React from 'react';
import { observer } from 'mobx-react-lite';
import { IResult } from '@mediafellows/chipmunk';

import {
  FilterCheckbox,
  FilterDateRange,
  FilterSelect,
  FilterSlider,
  FilterSwitch,
  FilterTree,
} from 'helpers/filters/fields';
import { Model } from 'helpers/filters/types';
import { Mm3ContextDataProvider } from 'helpers/data-provider/option-data-provider';
import { dynamicDataExecutorCache } from 'helpers/data-provider/option-data-provider';
import { DynamicTreeDataProvider, unflattenTree } from 'helpers/data-provider/tree-data-provider';
import { IFiltersHandlers, organizationOptionsProvider, ownersOptionsProvider } from 'helpers/filters';
import { chipmunk } from 'utils/chipmunk';
import { DataSectionStore } from 'store/data-section-store';
import { IDivision, IProduct } from 'types';
import { IFilters } from 'components/product-filters/types';
import { LanguageSelector } from 'components/product-filters/language-selector';

import { AdditionalFiltersCmp } from 'components/product-filters/additional-filters';

const typeOptionsProvider = new Mm3ContextDataProvider(Model.PRODUCTS, 'type');

const accessOptionsProvider = new Mm3ContextDataProvider(Model.PRODUCTS, 'access_level');
const divisionTreeProvider = new DynamicTreeDataProvider(
  dynamicDataExecutorCache('divisions', () => chipmunk.run(({ action }) => action(Model.DIVISIONS, 'query'))),
  (data: IResult<IDivision>) =>
    unflattenTree(data.objects.map((d) => ({ label: d.name, value: d.id, ancestry: d.ancestry || '' }))),
);

const genreTreeProvider = new DynamicTreeDataProvider(
  dynamicDataExecutorCache('genres', () =>
    chipmunk.run(({ action }) => action('pm.layer/motion_picture/standard/category', 'graph')),
  ),
  (data: IResult) =>
    unflattenTree(data.objects[0]['@graph'].map((g) => ({ label: g.name, value: g.id, ancestry: g.ancestry }))),
);

const labelMarks = [
  {
    value: 1946,
    label: '1946',
  },
  {
    value: 1996,
    label: '1996',
  },
];

interface ISharedProductFilters<T = IFilters> {
  filterValues: T;
  filterHandlers: IFiltersHandlers<T>;
  dataSectionStore: DataSectionStore<IProduct>;
}
export const SharedProductFilters: React.FC<ISharedProductFilters> = observer(
  ({ dataSectionStore, filterValues, filterHandlers }) => {
    const { searchStore } = dataSectionStore;

    return (
      <>
        <AdditionalFiltersCmp filterValues={filterValues} filterHandlers={filterHandlers} />

        <FilterDateRange
          label="Updated at"
          name="updated_at"
          filter={filterValues.updated_at}
          onChange={filterHandlers.onChange}
        />
        <FilterDateRange
          label="Created at"
          name="created_at"
          filter={filterValues.created_at}
          onChange={filterHandlers.onChange}
        />

        <FilterSelect
          label="Owner"
          name="owner_id"
          placeholder="Select Owner"
          optionsProvider={ownersOptionsProvider}
          filter={filterValues.owner_id}
          onChange={filterHandlers.onChange}
        />
        <FilterSelect
          label="Owner Organization"
          name="owner_organization_id"
          placeholder="Select Organization"
          optionsProvider={organizationOptionsProvider}
          filter={filterValues.owner_organization_id}
          onChange={filterHandlers.onChange}
        />
        <FilterTree
          label="Divisions"
          name="division_ids"
          optionsProvider={divisionTreeProvider}
          filter={filterValues.division_ids}
          onChange={filterHandlers.onChange}
          aggregations={searchStore.aggregationValues('division_ids')}
        />
        <FilterCheckbox
          label="Access"
          name="access_level"
          optionsProvider={accessOptionsProvider}
          filter={filterValues.access_level}
          onChange={filterHandlers.onChange}
          disabled={searchStore.running}
          aggregations={searchStore.aggregationValues('access_level')}
        />

        <FilterCheckbox
          label="Type"
          name="type"
          simplifyCheckboxLabel={true}
          optionsProvider={typeOptionsProvider}
          disabled={searchStore.running}
          filter={filterValues.type}
          onChange={filterHandlers.onChange}
          aggregations={searchStore.aggregationValues('type')}
        />
        {filterValues.no_parent && (
          <FilterSwitch
            label="Show parent products only"
            name="no_parent"
            disabled={searchStore.running}
            filter={filterValues.no_parent}
            onChange={filterHandlers.onChange}
            checkedValue={true}
            notCheckedValue={false}
          />
        )}

        <LanguageSelector
          filterValues={filterValues}
          filterHandlers={filterHandlers}
          dataSectionStore={dataSectionStore}
        />

        <FilterSlider
          label="Year of Production"
          name="default_layer.meta.year_of_production"
          min={1896}
          max={2040}
          marks={labelMarks}
          filter={filterValues['default_layer.meta.year_of_production']}
          onChange={filterHandlers.onChange}
        />

        <FilterTree
          label="Genre"
          name="default_layer.meta.category_ids"
          optionsProvider={genreTreeProvider}
          filter={filterValues['default_layer.meta.category_ids']}
          onChange={filterHandlers.onChange}
          aggregations={searchStore.aggregationValues('category_ids')}
        />
      </>
    );
  },
);
