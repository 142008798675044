import { IAsset, IMm3Asset } from 'types';
import { AssetFilters, getMainType } from 'utils/asset';

export const filterClassificationsByType: (type: string, classifications: string[]) => string[] = (): string[] => [];

export enum MainClassification {}

export enum AssetType {
  AUDIO = 'Asset3::Digital::Audio',
  DOCUMENT = 'Asset3::Digital::Document',
  IMAGE = 'Asset3::Digital::Image',
  OTHER = 'Asset3::Digital::Other',
  SUBTITLE = 'Asset3::Digital::Subtitle',
  VIDEO = 'Asset3::Digital::Video',
}

//todo: subtitles extensions
export const extensionsByClassification = {
  image: ['image/*'],
  video: ['video/*'],
  audio: '.aac .ac3 .aif .aiff .amr .ape .dts .flac .m4a .mka .mks .mp3 .oga .ogg .wav .wma'.split(' '),
  document: '.doc .docx .pdf .txt .rtf .odt .ott .tex .xls .xlsx .ppt .pptx .csv .html .htm .xml .md'.split(' '),
  subtitle: '.srt .vtt .ttml'.split(' '),
};

export const getAssetType = (type?: string, extension?: string): string => {
  if (!type) {
    return AssetType.OTHER;
  }
  const fileExtension = `.${extension}`;

  switch (true) {
    case type?.includes('image'):
      return AssetType.IMAGE;
    case type?.includes('video'):
      return AssetType.VIDEO;
    case extensionsByClassification.audio?.includes(fileExtension):
      return AssetType.AUDIO;
    case extensionsByClassification.document?.includes(fileExtension):
      return AssetType.DOCUMENT;
    case extensionsByClassification.subtitle?.includes(fileExtension):
      return AssetType.SUBTITLE;
    default:
      return AssetType.OTHER;
  }
};

export const isAssetVideo = (asset: IMm3Asset | IAsset): boolean => getMainType(asset) === AssetFilters.VIDEO;
