import { getRootStore } from 'store';
import { ItemId } from 'types';
import { IResult } from 'utils/chipmunk';

interface IUseChangeAccess<T> {
  apiCall: (data: { item_ids: (number | string)[]; access_level?: string }) => Promise<IResult | undefined>;
  divisionIds?: ItemId[] | null;
  newAccessLevel: string;
  entitySetter?: React.Dispatch<React.SetStateAction<T>>;
}

export const changeAccessLevelSlider = async <T>({
  apiCall,
  divisionIds,
  newAccessLevel,
  entitySetter,
}: IUseChangeAccess<T>): Promise<boolean> => {
  const {
    toastStore,
    dataSectionStore: { searchStore, active },
  } = getRootStore();

  if (!divisionIds?.length && newAccessLevel === 'division') {
    toastStore.error('Entity does not belong to any division');
    return false;
  }

  const data = {
    item_ids: [active],
    access_level: newAccessLevel,
  };

  const accessChange = await apiCall(data);
  const newEntity = accessChange?.object;

  if (!newEntity) {
    return false;
  }
  entitySetter
    ? entitySetter(newEntity as T)
    : searchStore.update({
        objects: searchStore.objects.map((entity) => {
          return entity.id === active ? { ...entity, access_level: newEntity?.access_level } : entity;
        }),
      });

  return true;
};
