import React from 'react';
import { StatisticCard } from 'components/statistic-card';
import { ActivityChart } from 'components/activity-chart';
import { StatisticsTopCard } from 'components/statistic-top-card';
import {
  fetchActivityStatistics,
  fetchDashboardAnalytics,
  fetchTopProductData,
  fetchTopVideoData,
  fetchTopVideoViewers,
} from 'utils/apis/analytics';
import { IconAlias } from 'icons';
import { Routes } from 'utils/routes';
import { DetailsPageTabs, Product3Types } from 'types';
import { formatType } from 'utils/format-type';
import { ProductInteractions, ContactInteractions, AssetInteractions } from 'components/analytics';
import { useSessionStore } from 'store/session-store';
import { useRemote, useThrottledResizeObserver } from 'utils/hooks';
import { MantineIcon } from 'utils/ui/icon';
import { Classes } from 'utils/ui';

import './style.scss';
import { flags } from 'utils/flags';

const formatAssetsKeys = (key: string): string => (key.endsWith('s') ? key.slice(0, -1) : key);

const valueToProductType = {
  episode: Product3Types.EPISODE,
  format: Product3Types.FORMAT,
  programs: Product3Types.PROGRAM,
  season: Product3Types.SEASON,
  series: Product3Types.SERIES,
};

const mapToProductType = (value: string): string => {
  return valueToProductType[value] || value;
};

const formatProductLabel = (label: string): string => formatType(mapToProductType(label));

const Dashboard: React.FC = () => {
  const user = useSessionStore((state) => state.user);
  const [data, isLoading] = useRemote(fetchDashboardAnalytics);
  const { ref, width } = useThrottledResizeObserver(500);
  const assetParam = flags.isMm3Assets ? 'type' : 'main_classification';

  const cardClassNames =
    'statistic-cards__wrapper--size-' + (width < 739 ? '0' : width < 1112 ? '1' : width < 1500 ? '2' : '3');

  return (
    <div className="dashboard-layout d-flex flex-column w-100 h-100 py-1">
      <div className="dashboard-layout__header mb-4">
        <h1>{`Hello ${user?.first_name || ''}`}</h1>

        <div className="dashboard-layout__subheader">
          <h4 className="bp3-text-muted mt-1 fw-normal">Welcome back</h4>
          <div className={Classes.TEXT_MUTED}>
            <MantineIcon icon="calendar" className="mx-2" />
            <span>Last 14 days statistics</span>
          </div>
        </div>
      </div>
      <div className="dashboard-layout__content">
        <div className="dashboard-layout-page__wrapper">
          <div className="statistic-cards__wrapper" ref={ref}>
            <StatisticCard
              title="contacts"
              icon={IconAlias.CONTACT}
              data={data?.object.user}
              isLoading={isLoading}
              path={Routes.CONTACTS}
              param="status"
              classNames={cardClassNames}
            />
            <StatisticCard
              title="products"
              icon={IconAlias.PRODUCT}
              path={Routes.PRODUCTS}
              data={data?.object.product}
              isLoading={isLoading}
              formatKeys={mapToProductType}
              formatLabel={formatProductLabel}
              param="type"
              classNames={cardClassNames}
            />
            <StatisticCard
              title="assets"
              icon={IconAlias.ASSET}
              data={data?.object.asset}
              isLoading={isLoading}
              path={Routes.ASSETS}
              param={assetParam}
              formatKeys={formatAssetsKeys}
              classNames={cardClassNames}
            />
            <StatisticCard
              title="files"
              icon={IconAlias.FILES}
              data={data?.object.file}
              isLoading={isLoading}
              path={Routes.ASSETS}
              param="file_status"
              classNames={cardClassNames}
            />
          </div>
          <div className="statistic-activity__wrapper">
            <ActivityChart fetcher={fetchActivityStatistics} url={Routes.ANALYTICS_CONTACTS} aspectRatio={6} />
          </div>
          <div className="statistic-top__wrapper">
            <StatisticsTopCard
              title="Top Products"
              statisticName="views"
              interaction={ProductInteractions.VIEWS}
              fetcher={fetchTopProductData}
              url={`${Routes.ANALYTICS_PRODUCTS}/${DetailsPageTabs.LIST}`}
            />
            <StatisticsTopCard
              title="Top Video Viewers"
              statisticName="minutes"
              fetcher={fetchTopVideoViewers}
              interaction={ContactInteractions.SCREENINGS}
              url={`${Routes.ANALYTICS_CONTACTS}/${DetailsPageTabs.LIST}`}
            />
            <StatisticsTopCard
              title="Top Videos Screened"
              statisticName="times"
              fetcher={fetchTopVideoData}
              interaction={AssetInteractions.SCREENINGS}
              url={`${Routes.ANALYTICS_ASSETS}/${DetailsPageTabs.LIST}`}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Dashboard;
