import React, { useCallback } from 'react';
import { observer } from 'mobx-react-lite';
import { useParams } from 'react-router-dom';

import { SectionMessage, SectionMessageType } from 'components/section-message';
import { DataSectionPage } from 'components/layout';
import { Loading } from 'components/loading';

import { useRemote } from 'utils/hooks';
import { getAssetsAggregations, loadAsset } from 'utils/apis/asset';

import { IAsset, IAssetAggregation, IMm3AssetType } from 'types';

import { AssetDetailsLoaded } from './asset-details-loaded';

import './style.scss';

const AssetDetails: React.FC<{}> = observer(() => {
  const { assetId = '' } = useParams<{ assetId: string }>();

  const fetchAsset = useCallback(async (): Promise<IAsset> => loadAsset(assetId), [assetId]);
  const [asset, loadingAssets, setAsset, refreshAsset] = useRemote<IAsset>(fetchAsset);
  const getSubAssetsAggregations = useCallback((): Promise<IAssetAggregation<IMm3AssetType>> => {
    return getAssetsAggregations<IMm3AssetType>({ stats: 'type,classification' }, [
      ['parent_id', 'eq', parseInt(assetId)],
    ]);
  }, [assetId]);

  const [subAssetsAggregations, loadingAggregations, , refreshAggregation] = useRemote(getSubAssetsAggregations);

  const loading = loadingAggregations || loadingAssets;
  const refresh = useCallback(async () => {
    await Promise.all([refreshAsset(), refreshAggregation()]);
    return;
  }, [refreshAggregation, refreshAsset]);

  return (
    <DataSectionPage refresh={refresh} hideToggleButton>
      {loading && <Loading text="Loading Asset" />}
      {asset?.id && !loading && (
        <AssetDetailsLoaded
          asset={asset}
          setAsset={setAsset}
          refreshAsset={refresh}
          fetchAsset={fetchAsset}
          subAssetsAggregations={subAssetsAggregations}
        />
      )}
      {!loading && !asset?.id && <SectionMessage intent={SectionMessageType.EMPTY} />}
    </DataSectionPage>
  );
});

export default AssetDetails;
