import React from 'react';
import { Tooltip } from '@mantine/core';
import cx from 'classnames';

import { Thumbnail } from 'components/thumbnail';
import { AssetAttributes } from 'components/asset/asset-data-section-item';

import { AssetMetadataPopover } from './asset-search-item-popover-metadata.tsx';
import useSelectedOption from 'utils/hooks/selected-option';
import { Classes } from 'utils/ui';
import { IAsset, IMm3Asset } from 'types';
import { getMainType } from 'utils/asset';

import './style.scss';

interface IAssetSearchItem {
  asset: IAsset | IMm3Asset;
  handleSelect: (e: React.MouseEvent<HTMLElement, MouseEvent>) => void;
  showMetadata?: boolean;
}

const AssetSearchItemContent: React.FC<IAssetSearchItem> = ({ asset, handleSelect }) => {
  const { name, classification, access_level, id, preview_image } = asset;
  const type = getMainType(asset);

  const selectedOption = useSelectedOption({ value: access_level });
  if (!asset) {
    return <></>;
  }

  return (
    <div
      key={`${id}-asset`}
      className={cx('entity-search-list-item', {
        [`access-level-${selectedOption?.color}`]: Boolean(selectedOption),
      })}
    >
      <div
        className={`searchbox__asset ${Classes.MENU_ITEM}`}
        data-id={id}
        data-item-type={type}
        onClick={handleSelect}
      >
        <div className="searchbox__thumb">
          <Thumbnail image={preview_image?.url} assetType={classification as string} showType />
        </div>
        <div>
          <div className="text-truncate">{name}</div>
          <div className={Classes.TEXT_MUTED}>
            <AssetAttributes asset={asset} />
          </div>
        </div>
      </div>
    </div>
  );
};

const AssetSearchItem: React.FC<IAssetSearchItem> = ({ asset, handleSelect, showMetadata = false }) => {
  return showMetadata ? (
    <Tooltip
      label={<AssetMetadataPopover asset={asset as IAsset} />}
      withArrow
      withinPortal
      position="top"
      style={{
        background: 'var(--mfx-gray-7--dark)',
        boxShadow: '0 0 0 1px rgba(0, 0, 0, 0.2), 0 2px 4px rgba(0, 0, 0, 0.4), 0 8px 24px rgba(0, 0, 0, 0.4)',
        padding: 0,
      }}
    >
      <div>
        <AssetSearchItemContent asset={asset} handleSelect={handleSelect} />
      </div>
    </Tooltip>
  ) : (
    <AssetSearchItemContent asset={asset} handleSelect={handleSelect} />
  );
};

export { AssetSearchItem };
