import React, { useCallback, useEffect } from 'react';
import { map } from 'lodash';
import { FormMultiSelect, IFormMultiSelectOption } from 'helpers/form';
import { queryAssets, querySubAssets } from 'utils/apis/asset';
import { queryAssetSelections } from 'utils/apis/selection';
import { IAsset, IGroup, IIdentifiable, IQueryParams, ISearchFilter } from 'types';
import { parseAssets } from 'components/recommendation/recommend-assets/utils';
import { IAccessGroupFormComponent } from './types';

export const queryAssetsSelections = async (
  params: IQueryParams,
  filters: ISearchFilter[] = [],
): Promise<((IAsset | IGroup) & IIdentifiable)[]> => {
  if (params?.ids?.length === 0) {
    return [];
  }
  return queryAssetSelections(params, filters);
};

export const AccessGroupFormAssets: React.FC<IAccessGroupFormComponent> = ({
  form: { formData, handlers, values },
}) => {
  const setAssets = useCallback(
    (options: IFormMultiSelectOption[]): void => {
      handlers.onChange(parseAssets(options));
    },
    [handlers],
  );

  const fetchAssets = useCallback(({ q, ids }: IQueryParams): Promise<(IAsset | IGroup | string)[]> => {
    let assetPromise;
    let selectionPromise;
    if (!ids?.length) {
      assetPromise = queryAssets({ q });
      selectionPromise = queryAssetsSelections({ q });
    } else {
      assetPromise = queryAssets({ ids });
      selectionPromise = queryAssetsSelections({ ids });
    }

    return Promise.all([assetPromise, selectionPromise]).then((result) => result.flatMap((item) => item));
  }, []);

  useEffect(() => {
    const fetchSubAssets = async (): Promise<void> => {
      if (!values.asset_ids) return;

      const subAssets = await querySubAssets(values.asset_ids as string[]);
      const subAssetIds = map(subAssets, 'id') || [];
      handlers.onChange({ subAssetIds: subAssetIds || [] });
    };

    fetchSubAssets();
  }, [values.asset_ids, handlers]);

  return (
    <FormMultiSelect
      name="assetList"
      label="Assets"
      fitInParent
      large
      omni
      {...handlers}
      {...formData.assetList}
      onSelectedItemsChange={setAssets}
      fetchValues={fetchAssets}
    />
  );
};
