import React from 'react';

import { useFutureDescendantsFlag } from 'components/access-privilege';
import { FormProducts, FormCheckbox } from 'helpers/form';
import { ISmartGroupFormComponent } from 'types/smart-group';

export const keysProducts = ['product_ids', 'include_descendants', 'delegates_access', 'permit_download'];

export const SmartGroupFormProducts: React.FC<ISmartGroupFormComponent> = ({ form }) => {
  useFutureDescendantsFlag(form);
  return (
    <div className="smart-group-wizard__products-tab">
      <FormProducts
        name="product_ids"
        label="Products"
        fitInParent
        large
        {...form.formData.product_ids}
        {...form.handlers}
      />
      <FormCheckbox
        name="include_descendants"
        label="Add product(s) including descendants"
        {...form.formData.include_descendants}
        {...form.handlers}
      />
      <FormCheckbox
        name="include_future_descendants"
        label="Automatically include all future descendants"
        {...form.formData.include_descendants}
        {...form.handlers}
      />
    </div>
  );
};
